body {
    &.dark {
        background-color: var(--theme-default-dark);
        transition: all 0.3s ease;
        color: var(--theme-default);

        .mat-drawer-content {
            background-color: var(--theme-default-dark);
        }
    }

}

.dark {
    .color-options {
        .configuration-content {
            h6 {
                color: var(--theme-color-2) !important;
            }

            h5 {
                color: var(--theme-default-dark) !important;
            }
        }
    }

    .main-slider {
        .sl-wrap {
            .content {
                h3 {
                    color: var(--theme-default-dark) !important;
                }
            }
        }
    }

    .mat-card {
        background: transparent !important;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--theme-default-grey) !important;
    }

    .mat-tab-labels {
        .mat-tab-label-content {
            color: var(--theme-default-grey);
        }
    }


    .product-item-2,
    .product-item-1 {
        .mat-card:not([class*=mat-elevation-z]) {
            .price-wrap {
                p {
                    font-size: 19px;
                    color: var(--theme-default-grey) ;
                }
            }
        }
    }


    .info-bar {
        border: 1px solid var(--theme-default-dark);

        .card-wrap {
            .mat-card {
                border-right: 1px solid var(--theme-default-dark);

                .content {
                    p {
                        font-weight: 600;
                        font-size: 14.5px;
                        color: var(--theme-default-grey);
                    }
                }

                &.no-bd {
                    border-right: none;
                }
            }

            .mat-icon {
                color: var(--theme-default);

            }
        }
    }

    .home-3-wrap {
        .main-home3-wrapper {
            .info-bar {
                border: 1px solid var(--theme-default-dark);

                .card-wrap {
                    .mat-card {
                        border-right: 1px solid var(--theme-default-dark);
                    }
                }
            }
        }

        .brands {
            border: 1px solid var(--theme-default-dark);
        }
    }

    .left-sidebar-wrapper {
        .all-products {
            .col {
                .product-item {
                    .mat-card:not([class*=mat-elevation-z]) {
                        box-shadow: none !important;
                        background: transparent;

                        .product-info-wrapper {
                            .price-wrap {
                                p {
                                    color: var(--theme-default-grey);
                                }
                            }
                        }
                    }
                }
            }

            select {
                color: var(--theme-default-grey);
            }

            input {
                color: var(--theme-default-grey);
            }
        }

        .filter-row {
            border-bottom: 1px solid var(--theme-default-dark);
            border-top: 1px solid var(--theme-default-dark);
        }

        .mat-expansion-panel {

            border: 1px solid var(--theme-default-dark);

            span {
                color: var(--theme-default-grey);
            }
        }

        .no-shadow {
            background: transparent;
        }
    }

    .right-sidebar-wrapper {
        .all-products {
            background: transparent;

            .col {
                .product-item {
                    .mat-card:not([class*=mat-elevation-z]) {
                        box-shadow: none !important;
                        background: transparent;

                        .product-info-wrapper {
                            .price-wrap {
                                p {
                                    color: var(--theme-default-grey);
                                }
                            }
                        }
                    }
                }
            }

            select {
                color: var(--theme-default-grey);
            }

            input {
                color: var(--theme-default-grey);
            }
        }

        .filter-row {
            border-bottom: 1px solid var(--theme-default-dark);
            border-top: 1px solid var(--theme-default-dark);
        }

        .mat-expansion-panel {

            border: 1px solid var(--theme-default-dark);

            span {
                color: var(--theme-default-grey);
            }
        }

        .no-shadow {
            background: transparent;
        }
    }

    .products {
        .product-item {
            .mat-card {
                background-color: transparent;
                box-shadow: none !important;

                .product-info-wrapper {
                    .price-wrap {
                        p {
                            color: var(--theme-default-grey);
                        }
                    }
                }
            }
        }
    }

    .vertical-products {
        .widget-column {
            .title {
                .widget-title {
                    &::before {
                        content: "";
                        left: 0;
                        position: absolute;
                        width: 100%;
                        height: 0.0625rem;
                        bottom: 0.34375rem;
                        background-color: var(--theme-default-dark);
                    }

                    &::after {
                        content: "";
                        left: 0;
                        position: absolute;
                        width: 2.5rem;
                        height: 0.125rem;
                        background-color: var(--theme-color-2);
                        bottom: 0.3125rem;
                    }


                }
            }

            .media-body {
                span {
                    color: var(--theme-default-grey);
                }

                .price {
                    color: var(--theme-default-grey);
                }
            }
        }
    }

    .footer {
        background-color: var(--theme-default-dark);

        .footer-widget {
            a {
                color: var(--theme-default-grey);
            }

            p {
                color: var(--theme-default-grey);
            }

            .tel-box {
                .icon {
                    i {
                        color: var(--theme-default);
                    }
                }
            }

            .social {
                li {

                    border: 1px solid var(--theme-color-2);

                    i {
                        color: var(--theme-default-grey);
                    }
                }
            }
        }


    }

    .carousel-2 {
        .slider-categories {
            .categories-wrap {
                border: 1px solid var(--theme-default-dark);

                .category-contant {
                    ul {
                        li {
                            a {
                                border-top: 1px solid var(--theme-default-dark);
                            }
                        }
                    }
                }

                .title {
                    background: var(--theme-default-dark);
                    border-bottom: 1px solid var(--theme-default-dark);
                    color: var(--theme-default-grey);
                }
            }
        }
    }


    .home3-sidebar {
        .categories-wrap {
            border: 1px solid var(--theme-default-dark);

            .category-contant {
                ul {
                    li {
                        a {
                            border-top: 1px solid var(--theme-default-dark);
                        }
                    }
                }
            }

            .title {
                background: var(--theme-default-dark);
                border-bottom: 1px solid var(--theme-default-dark);
                color: var(--theme-default-grey);
            }
        }
    }

    .carousel-3 {
        background-color: var(--theme-default-dark);
        padding: 25px 0;
    }

    .home-3-wrap {
        .main-home3-wrapper {
            .products {
                background-color: var(--theme-default-dark);
            }
        }
    }

    .menu-container1 {
        background: var(--theme-default-dark);
        border-bottom: 1px solid var(--theme-default-dark);

        a.mat-button {
            color: var(--theme-default-grey);
        }
    }

    .info-bar-four-wrapper {
        margin-top: 0px;
        border: none;
        background: var(--theme-default-dark);
        border-bottom: 1px solid var(--theme-default-dark);

        .card-wrap {
            .mat-card {
                border-right: 1px solid var(--theme-default-dark);

                p {
                    color: var(--theme-default-grey);
                }
            }
        }

    }

    .products {
        .products-four {
            h3 {
                &::before {

                    background-color: var(--theme-default-dark);

                }

                &::after {
                    background-color: var(--theme-color-2);
                }
            }
        }
    }

    .featured-products-wrap {
        background: var(--theme-default-dark);
    }

    .home-header-three {
        background: var(--theme-default-dark);
        border-top: none;

        .call-bucket-wrap {
            .main-menu-wrapper-3 {
                .mat-icon {
                    color: var(--theme-default-grey);
                }

                .top-cart {
                    span {
                        color: var(--theme-default-grey);
                    }
                }
            }
        }
    }

    .topbar_four {
        background: var(--theme-default-dark) !important;

        .widget-text {
            a {
                color: var(--theme-default-grey);

                .mat-icon {
                    color: var(--theme-default-grey);
                }
            }
        }
    }

    .menu-container4 {
        background: var(--theme-default-dark);
        border-top: 1px solid var(--theme-default-dark);

        .main-menu {
            a.mat-button {
                color: var(--theme-default-grey);
            }
        }
    }

    .popular-products {
        .title-header {
            h4 {
                &::before {

                    background-color: var(--theme-default-dark);

                }

                &::after {

                    background-color: var(--theme-color-2);

                }
            }
        }

        .reviews {
            .content {
                .info {
                    .price {
                        color: var(--theme-default-grey);
                    }
                }
            }
        }
    }

    .home-5-wrap {
        background: var(--theme-default-dark);

        .categories {
            background: var(--theme-default-dark);

            .title {
                h3 {
                    &::before {
                        background-color: var(--theme-default-dark);
                    }

                    &::after {
                        background-color: var(--theme-color-2);
                    }
                }
            }
        }
    }

    .home-header-4 {
        .call-bucket-wrap {
            .info-message-icon {
                .info-text {
                    span {
                        color: var(--theme-default-grey);
                    }

                    p {
                        color: var(--theme-default-grey);
                    }
                }

                .icon {
                    .mat-icon {
                        color: var(--theme-default-grey);
                    }
                }
            }

            .top-navbar {
                .mat-button-wrapper {
                    .mat-icon {
                        color: var(--theme-default-grey);
                    }

                    .top-cart {
                        span {
                            color: var(--theme-default-grey);
                        }
                    }
                }
            }
        }
    }

    .menu-container5 {
        .container {
            border-top: 1px solid var(--theme-default-dark);

            a.mat-button {
                color: var(--theme-default-grey);
            }
        }
    }

    .mat-form-field-label-wrapper {
        label {
            color: var(--theme-default-grey);
        }
    }

    .about-us-wrapper {
        .about-section {
            .left-info-panel {
                .about-us-intro {
                    p {
                        color: var(--theme-default-grey);
                    }

                    .accordions {
                        .mat-expansion-panel {
                            background: transparent;
                            border: 1px solid var(--theme-color-2);

                        }
                    }
                }

            }

        }

        .our-values {
            background-color: var(--theme-default-dark);

            .box-wraper {
                .box {
                    .content {
                        background: transparent;
                        border: 1px solid var(--theme-color-2);

                        p {
                            color: var(--theme-default-grey);
                        }
                    }
                }
            }
        }

        .our-team {
            .team-members {
                .item {
                    .single-team-member {
                        .text-box {
                            p {
                                color: var(--theme-color-2);
                            }
                        }
                    }
                }
            }
        }
    }

    .details-wrapper {
        .product-image {
            background: var(--theme-default) !important;
        }

        .small-carousel {
            .mat-card {
                background: var(--theme-default) !important;
            }
        }

        .info-bar {
            .mat-icon {
                color: var(--theme-default-grey);
            }

            .content {
                p {
                    color: var(--theme-default-grey);
                }

                span {
                    color: var(--theme-default-grey);
                }
            }
        }

        .products {
            .title {
                &::before {
                    background-color: var(--theme-default-dark);
                }

                &::after {
                    background-color: var(--theme-color-2);
                }
            }
        }

        .product-right {
            .avalibility {
                color: var(--theme-color-2);
            }
        }

        p.description {
            color: var(--theme-default-grey);
        }
    }

    .product-dialog {
        .mat-dialog-container {
            overflow: visible;
            background: var(--theme-default-secondary);
        }

        .swiper-container {
            .swiper-wrapper {
                img.img-fluid {
                    background: var(--theme-default);
                }
            }
        }

        .product-right {
            .discription {
                .bold {
                    color: var(--theme-color-2);
                }
            }

            span {
                color: var(--theme-default-grey);
            }

            .quantity {
                input {

                    background-color: transparent;
                    border: 1px solid var(--theme-color-2);
                    color: var(--theme-default);
                }
            }

        }

    }


    .menu-wraper-five {
        background: var(--theme-default-dark);

        a {
            color: var(--theme-default-grey) !important;
        }

        .menu-container {
            .call-bucket-wrap {
                .top-navbar {
                    .search-form {
                        input {
                            color: var(--theme-default-grey);
                        }

                        i {
                            color: var(--theme-default-grey);
                        }
                    }

                    .commerce-buttons {
                        .flex-row-button {
                            .mat-button-wrapper {
                                .mat-icon {
                                    color: var(--theme-default-grey);
                                }

                                .top-cart {
                                    span {
                                        color: var(--theme-default-grey);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .food-product-section {
        background: url(/assets/images/background/download2.svg) no-repeat 90%;
        background-size: auto 75px;
        background-repeat: repeat-x;
        background-position: center 0;

        .head-title {
            p {
                color: var(--theme-default-grey);
            }
        }

        .prod-menu {
            ul {
                li {
                    .text {
                        p {
                            color: var(--theme-default-grey);
                        }
                    }

                    .icon {
                        svg {
                            fill: var(--theme-default-grey) !important;

                            .cls-1 {
                                stroke: var(--theme-default-grey) !important;
                            }
                        }
                    }
                }
            }
        }

        .product-wrapper {
            .product-box {
                .product-item {
                    mat-card {
                        border: 1px solid var(--theme-default-dark);

                        .product-img {
                            background-color: var(--theme-default);
                        }

                        .product-info-wrapper {
                            background: var(--theme-default-dark);

                            .title-wrap {
                                p {
                                    color: var(--theme-default-grey);
                                }
                            }
                        }
                    }

                }
            }
        }
    }

    .about-products {
        background: var(--theme-default-dark);

        p,
        span {
            color: var(--theme-default-grey);
        }
    }

    .main-topbar-furniture {
        background: var(--theme-default-dark) !important;

        span,
        p {
            color: var(--theme-default-grey);
        }
    }

    .home-header-seven {
        >div {
            width: 100%;
            background-color: var(--theme-default-dark);

            .widget-text {
                a {
                    .mat-icon {
                        vertical-align: middle;
                        margin-right: 1px !important;
                        font-size: 23px;
                        color: var(--theme-default);
                    }
                }
            }

            .call-bucket-wrap {
                .main-menu-wrapper-2 {
                    .top-navbar {
                        background: transparent !important;

                        .mat-icon {
                            color: var(--theme-default);
                        }

                        .top-cart {

                            span {
                                color: var(--theme-default);
                            }
                        }
                    }
                }
            }
        }


    }

    .carousel-furniture {
        padding-top: 30px;

        .ctn-box {
            .slider-categories {
                .categories-wrap {
                    border: 1px solid var(--theme-default-dark);

                    .title {
                        background: var(--theme-default-dark);
                        border-bottom: 1px solid var(--theme-default-dark);
                        color: var(--theme-default-grey);
                    }

                    .category-contant {
                        ul {
                            li {
                                a {
                                    border-top: 1px solid var(--theme-default-dark);
                                }
                            }
                        }
                    }
                }
            }
        }

        .main-menu {
            a.mat-button {
                color: var(--theme-default-grey);
            }
        }
    }

    .banners-container {
        .baner {
            h4 {

                color: var(--theme-default-dark) !important;
            }
        }
    }

    .commercial-banner {
        .story__content {
            background-color: var(--theme-default-dark);

            .inner-column {
                h1 {
                    color: var(--theme-default-grey);
                }
            }
        }
    }




    .main-menu {
        a.mat-button {
            color: var(--theme-default-grey);
        }
    }

    .header-decor {
        >div {
            width: 100%;
            background-color: var(--theme-default-dark);
        }
    }

    .carousel-home-decor {
        .slider-categories {
            background-color: var(--theme-default-dark);

            .categories-wrap {
                border: 1px solid var(--theme-default-dark);

                .title {
                    background: var(--theme-default-dark);
                    border-bottom: 1px solid var(--theme-default-dark);
                    color: var(--theme-default-grey);
                }

                .category-contant {
                    ul {
                        li {
                            a {
                                border-top: 1px solid var(--theme-default-dark);
                                color: var(--theme-default-grey);
                            }
                        }
                    }
                }
            }
        }
    }

    .banners-decor {
        border: 1px solid var(--theme-color-2);

        .baner {
            h4 {
                color: var(--theme-default-dark) !important;

            }
        }
    }
}