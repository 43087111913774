/**************************************************************************
***************** Master Stylesheet for ecommerce************************
***************************************************************************
=========================== Content Index =================================
* 1. General styles
* 2. HOME-1 STYLES
     - Info bar styles
     - Header-1 styles
     - Shopping widget styles
     - Home product section styles
     - Home baners section styles
     - Home vertical-product section styles
     - Home footer section styles
* 3. HOME-2 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-3 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-4 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 3. Menu-1 styles
* 4. Menu-2 styles
* 5. Products left sidebar page styles
* 6. Popular product page styles
* 7. My account page styles
* 11. Price slider styles
* 12. Product dialog styles
* 13. Popular products styles

***************************************************************************
**************************************************************************/
/***************************************/
/* Icon Pulse Shrink */
:root {
  --theme-default-secondary: #004876 !important; // text and where black is needed
  --theme-default-blackish: #373A36 !important; // text and where black is needed
  --theme-default-grey: #eeedee !important; //grey
  --theme-color-2: #9c9c9c !important; // medium grey
  --theme-default-dark: #373A36 !important; //grey
  --theme-main-red: #f33927 !important; //red
  --theme-main-yellow: #ffeb3b !important; // yellow
  --theme-main-blue: #cc9e67 !important; // light blue
  --theme-green: #4caf50 !important; //success green
  --theme-orange: #ff9800 !important; // orange

  --theme-default: #ffffff !important; // white only
  --theme-color-1: #eeeeee !important; //grey
  --theme-color-2: #9c9c9c !important; // medium grey
  --theme-color-3: #383838 !important; //grey
  --theme-white: #fff !important; //grey

  --theme-brand-color-1: #232b63 !important; //grey
  --theme-brand-color-2: #eeeeee !important; //grey


}

@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.hvr-icon-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px var(--theme-default-secondary);
}

.hvr-icon-pulse-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon-pulse-shrink:hover .hvr-icon,
.hvr-icon-pulse-shrink:focus .hvr-icon,
.hvr-icon-pulse-shrink:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-shrink;
  animation-name: hvr-icon-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.color-options {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 350px;
  right: -360px;
  background-color: var(--theme-default);
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 var(--theme-default-grey);
  transition: all 0.5s ease;

  .options-icon {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--theme-default-grey);
    width: 40px;
    height: 40px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 5px 0px var(--theme-color-2);
    transition: all 0.5s ease;
    z-index: 2;

    .mat-icon {
      -webkit-animation: rotation 2s infinite linear;
      color: var(--theme-default-dark);
    }
  }

  .mat-card {
    position: absolute;
    padding: 14px;
    width: 100%;
    height: 100%;
  }

  &.show {
    right: -2px;
    -webkit-transition: max-height 0.25s ease-in;
    -moz-transition: max-height 0.25s ease-in;
    transition: all 0.5s ease;
  }

  .card-title-text {
    padding: 1rem 1rem;
    background: var(--theme-default-grey);

    h4 {
      color: var(--theme-default-dark) !important;
    }
  }

  .configuration-content {
    padding: 1rem 1rem;

    .setting-contant {
      border-bottom: 1px solid var(--theme-default-grey) !important;
      padding-bottom: 1rem !important;
      margin-bottom: 1rem !important;

      .color-box {
        li {
          display: flex;
          align-items: center;

          input {
            border: none;
            box-shadow: none;
            width: 34px;
            height: 34px;
            background-color: transparent;
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }

      .products-preview-box-wrapper {
        .item {
          padding-right: 15px;
          padding-left: 15px;
          margin-bottom: 30px;

          .products1 {
            height: 110px;
            width: 100%;
            display: inline-flex;
            background-size: cover;
            transition: all 2s ease-out 0s;
            box-shadow: -1px 2px 5px 1px var(--theme-color-2);

            img {
              width: 100%;
            }
          }

          .products2 {
            height: 110px;
            width: 100%;
            display: inline-flex;
            background-size: cover;
            transition: all 2s ease-out 0s;
            box-shadow: -1px 2px 5px 1px var(--theme-color-2);

            img {
              width: 100%;
            }
          }

          .products3 {
            height: 110px;
            width: 100%;
            display: inline-flex;
            background-size: cover;
            transition: all 2s ease-out 0s;
            box-shadow: -1px 2px 5px 1px var(--theme-color-2);

            img {
              width: 100%;
            }
          }

          .demo-text {
            text-align: center;
            background: transparent;

            .btn-preview {
              background: transparent;
              margin-top: 14px;

              a {
                border: 1px solid var(--theme-color-2);
                background-color: var(--theme-default);
                width: 90px;
                color: var(--theme-default);
                padding: 4px 12px;
                border-radius: 30px;
              }
            }
          }

          &:hover {
            .products1,
            .products2,
            .products2 {
              background-position: center 100% !important;
            }
          }
        }
      }

      .theme-layout-version {
        cursor: pointer;
      }
    }

    h6 {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin: 0 0 1rem;
    }
  }
}

/***************************************
* 1. General styles
****************************************/

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Red Hat Text", sans-serif;
  position: relative;
  font-size: 1rem;
  color: var(--theme-default-blackish) !important;
}

h1 {
  font-size: 60px;
  color: var(--theme-default-dark);
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 36px;
  color: var(--theme-default-dark);
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 28px;
  text-align: left;
  color: var(--theme-default-dark);
  font-weight: 500;
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-default-dark);
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--theme-default-dark);
  line-height: 24px;
}

* {
  margin: 0;
  padding: 0;
}
.mat-raised-button{
  font-family: "Red Hat Text" !important;
}


.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.mat-slider-track-fill {
  background-color: var(--theme-orange);
}

.mat-primary .mat-slider-thumb {
  background-color: var(--theme-orange);
}

.mat-raised-button.mat-warn {
  background-color: var(--theme-main-red);
}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow:
    2px 2px 6px 0px var(--theme-default-grey),
    0 1px 1px 0 var(--theme-default-secondary),
    0 1px 3px 0 var(--theme-default-grey) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--theme-default-secondary) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--theme-default-secondary) !important;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: var(--theme-default-dark);
}

.mat-expansion-panel:hover .mat-expansion-indicator::after,
.mat-expansion-panel:focus .mat-expansion-indicator::after {
  color: var(--theme-main-blue);
}

.sophia-app .mat-drawer-container {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
  background-color: var(--theme-default);
}

.mat-menu-panel {
  min-width: 100% !important;
  max-width: 100% !important;
}

.mat-mini-fab.mat-accent {
  background-color: var(--theme-orange);
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center !important;
}

li {
  list-style-type: none;
}

.mat-snack-bar-container.error {
  background: var(--theme-main-red) !important;
}

.mat-snack-bar-container.info {
  background: var(--theme-color-2) !important;
  color: var(--theme-default);
}

.mat-snack-bar-container.warning {
  background: var(--theme-main-yellow) !important;
  color: var(--theme-default-secondary) 0;
}

.mat-snack-bar-container.success {
  background: var(--theme-green) !important;
  color: var(--theme-default);
}

.mat-snack-bar-container button {
  width: 10px !important;
  color: var(--theme-default) !important;
  background-color: transparent !important;
  padding: 0px !important;
}

.mat-drawer-backdrop {
  background-color: var(--theme-color-2);
}

.mat-drawer-container {
  color: var(--theme-default-dark);
}

.mat-radio-label-content {
  color: var(--theme-color-2);
  font-weight: 400;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: var(--theme-main-red);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: var(--theme-default-blackish) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--theme-default-blackish) !important;
}

.mat-raised-button.mat-primary {
  background-color: var(--theme-default-blackish); //var(--theme-default);
  color: var(--theme-default);
  border-radius: 23px;
}

.mat-input-element {
  caret-color: var(--theme-orange) !important;
}

.mat-tab-group.mat-primary {
  .mat-tab-label {
    opacity: 0.7;
  }

  .mat-ink-bar {
    background-color: var(--theme-default);
  }
}

.container {
  max-width: 1390px;
  margin: 0 auto;
  padding: 0 15px !important;
}

.divider {
  border-top: 1px solid var(--theme-default-grey);
}

.bg {
  background: var(--theme-default-grey);
}

.ml-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.w-50 {
  width: 50%;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.no-pdn {
  padding: 0 !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.devider {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed var(--theme-color-2);
}

.w-100 {
  width: 100% !important;
}

.text-muted {
  color: var(--theme-color-2) !important;
}

.text-right {
  text-align: right;
}

.sec-padding {
  padding: 60px 0;
}

.btn-project {
  padding: 5px 32px !important;
  box-shadow: none !important;
}

/******** Product-1 styles *********/
.product-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;

  .mat-card:not([class*="mat-elevation-z"]) {
    -webkit-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    -moz-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    padding: 0;

    .product-img {
      background: var(--theme-default-grey);

      img {
        max-width: 180px;
        margin-bottom: 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .product-info-wrapper {
      padding: 15px;

      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;

        a {
          color: var(--theme-default-dark);
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;

          &:hover {
            color: var(--theme-default) !important;
          }
        }
      }

      .category {
        margin-bottom: 4px;
        display: block;
        font-weight: 300;
        color: var(--theme-color-2);
      }

      span {
        color: var(--theme-default);
        font-size: inherit;
      }

      .price-wrap {
        margin: 10px 0 15px 0;

        .stars {
          color: var(--theme-orange);
          margin: 2px 0 10px 0;

          span {
            color: var(--theme-default-grey);
            font-size: 13px;
            margin-left: 10px;
          }

          mat-icon {
            font-size: 17px;
            color: var(--theme-orange);
            line-height: 17px;
            height: 17px;
            width: 17px;
            vertical-align: middle;
          }
        }

        p {
          font-size: 19px;
        }

        mat-icon {
          color: var(--theme-default);
        }
      }

      .btn-wrap {
        a {
          cursor: pointer;
          transition: 0.3s;
          width: 100%;
          border-radius: 0;
          text-align: center;
          line-height: 39px;
          color: var(--theme-default);
          font-size: 14px;
          box-shadow: none !important;
          transition: 0.4s ease;
          border: 2px solid var(--theme-default) !important;

          mat-icon {
            font-size: 18px;
            vertical-align: middle;
          }

          &:hover {
            border: 2px solid var(--theme-default) !important;

            background: var(--theme-default);

            span {
              color: var(--theme-default) !important;
            }
          }
        }
      }

      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
        }
      }
    }

    &:hover {
      .icons {
        opacity: 1;
      }

      .price-wrap {
        a {
          background: var(--theme-orange);
        }
      }
    }
  }

  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
  }
}

/******** Pack-1 styles *********/
.pack-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 0px 0px 0px 12px;

  .mat-card:not([class*="mat-elevation-z"]) {
    -webkit-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    -moz-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    padding: 0;
    border: 1px solid var(--theme-color-2);

    .product-img {
      background: var(--theme-default);

      img {
        max-width: 145px;
        height: 120px;
        // margin-bottom: 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .product-info-wrapper {
      padding: 15px;

      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;

        a {
          color: var(--theme-default-dark);
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;

          &:hover {
            color: var(--theme-default) !important;
          }
        }
      }

      .category {
        margin-bottom: 4px;
        display: block;
        font-weight: 300;
        color: var(--theme-color-2);
      }

      span {
        color: var(--theme-default);
        font-size: 14px;
      }

      .price-wrap {
        p {
          letter-spacing: -0.3px;
          color: var(--theme-color-2);
          font-size: 12px;
          font-weight: 400;
        }
      }

      .btn-wrap {
        a {
          margin-top: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          border-radius: 6px;
          // width: 110px;
          height: 38px;
          color: var(--theme-default);
          font-size: 11px;
          letter-spacing: -0.25px;
          font-family: "Red Hat Text", sans-serif;
        }
      }

      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          // font-weight: 500;
          color: var(--theme-color-2);
          font-size: 14px;
          font-family: "Red Hat Text", sans-serif;
          margin-top: 5px;
          letter-spacing: -0.35px;
        }
      }
    }

    &:hover {
      .icons {
        opacity: 1;
      }
    }
  }

  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
  }

  .footerButton .mat-button {
    min-width: 42px;
  }
}

/******** customer-type-1 styles *********/
.customer-item-1 {
  position: unset;
  height: 290px;
  transition: 0.4s;
  padding: 0px 0px 0px 12px;

  .mat-card:not([class*="mat-elevation-z"]) {
    -webkit-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    -moz-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
    padding: 0;
    border: 1px solid var(--theme-color-2);
    height: 270px;

    .product-img {
      background: var(--theme-default-grey);

      img {
        max-width: 120px;
        // margin-bottom: 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .product-info-wrapper {
      padding: 15px;

      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;

        a {
          color: var(--theme-default-dark);
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;

          &:hover {
            color: var(--theme-default) !important;
          }
        }
      }

      .category {
        margin-bottom: 4px;
        display: block;
        font-weight: 300;
        color: var(--theme-color-2);
      }

      span {
        color: var(--theme-default);
        font-size: 14px;
      }

      .price-wrap {
        p {
          letter-spacing: -0.3px;
          color: var(--theme-color-2);
          font-size: 12px;
          font-weight: 400;
        }
      }

      .btn-wrap {
        a {
          margin-top: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
          border-radius: 6px;
          width: 100px;
          height: 30px;
          color: var(--theme-default);
          font-size: 11px;
          letter-spacing: -0.25px;
          font-family: "Red Hat Text", sans-serif;
        }
      }

      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          // font-weight: 500;
          color: var(--theme-color-2);
          font-size: 14px;
          font-family: "Red Hat Text", sans-serif;
          margin-top: 5px;
          letter-spacing: -0.35px;
        }
      }
    }

    &:hover {
      .icons {
        opacity: 1;
      }
    }
  }

  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: 46px;
    z-index: 10;
    background-image: none;
  }

  .footerButton .mat-button {
    min-width: 42px;
  }
}

/******** Product-2 styles *********/
.product-item-2 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;

  .mat-card:not([class*="mat-elevation-z"]) {
    box-shadow:
      2px 2px 6px 0px var(--theme-default-grey),
      0 1px 1px 0 var(--theme-default-grey),
      0 1px 3px 0 var(--theme-default-grey) !important;
    padding: 0;

    .product-img {
      background: var(--theme-default-grey);

      img {
        max-width: 180px;
        margin-bottom: 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .icons {
        position: absolute;
        top: 30px;
        right: 10px;
        opacity: 0;
        transition: all 0.5s ease;

        a {
          color: var(--theme-default-dark);
          cursor: pointer;
          transition: 0.5s ease;
          margin-bottom: 5px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .product-info-wrapper {
      padding: 15px;

      .stars {
        color: var(--theme-orange);
        margin: 2px 0 10px 0;

        span {
          color: var(--theme-default-grey);
          font-size: 13px;
          margin-left: 10px;
        }

        mat-icon {
          font-size: 17px;
          line-height: 17px;
          height: 17px;
          width: 17px;
          vertical-align: middle;
        }
      }

      .category {
        margin-bottom: 4px;
        display: block;
        font-weight: 300;
      }

      span {
        color: var(--theme-color-2);
        font-size: 14px;
      }

      .price-wrap {
        p {
          font-size: 19px;
        }

        mat-icon {
          color: var(--theme-default);
        }

        a {
          background-color: var(--theme-default);
          cursor: pointer;
          transition: 0.3s;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          text-align: center;
          line-height: 46px;

          mat-icon {
            transition: 0.3s;
            font-size: 20px;
            line-height: 24px;
          }
        }

        a {
          background-color: var(--theme-default);
          cursor: pointer;
          transition: 0.3s;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          text-align: center;
          line-height: 46px;

          mat-icon {
            transition: 0.3s;
            font-size: 18px;
          }
        }
      }

      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
        }
      }
    }

    &:hover {
      .icons {
        opacity: 1;
      }
    }
  }

  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
  }
}

/******** Product-3 styles *********/
.product-item-3 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;

  .mat-card:not([class*="mat-elevation-z"]) {
    box-shadow:
      2px 2px 6px 0px var(--theme-default-grey),
      0 1px 1px 0 var(--theme-default-grey),
      0 1px 3px 0 var(--theme-default-grey) !important;
    padding: 0;

    .product-img {
      background: var(--theme-default-grey);
      position: relative;
      overflow: hidden;

      img {
        max-width: 180px;
        margin-bottom: 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .content {
        display: table-cell;
        vertical-align: bottom;
        overflow: hidden;

        .icons {
          position: absolute;
          bottom: 0;
          top: auto;
          left: 0;
          width: 100%;
          text-indent: 0;
          height: auto;
          padding: 0.75rem 0 0.45rem 0;
          position: absolute;
          opacity: 0;
          visibility: hidden;
          background: var(--theme-default);
          z-index: 2;
          transition: 0.3s ease;
          -webkit-transform: translate3d(0, 35px, 0);
          transform: translate3d(0, 35px, 0);
          -webkit-transition:
            -webkit-transform 0.4s ease,
            visibility 0.4s ease,
            opacity 0.4s ease;
          transition:
            transform 0.4s ease,
            visibility 0.4s ease,
            opacity 0.4s ease;

          a {
            cursor: pointer;
            transition: 0.5s ease;
            line-height: 22px;
          }
        }
      }
    }

    .product-info-wrapper {
      padding: 15px;

      .stars {
        color: var(--theme-orange);
        margin: 2px 0 10px 0;

        span {
          color: var(--theme-default-grey);
          font-size: 13px;
          margin-left: 10px;
        }

        mat-icon {
          font-size: 17px;
          line-height: 17px;
          height: 17px;
          width: 17px;
          vertical-align: middle;
        }
      }

      .category {
        margin-bottom: 4px;
        display: block;
        font-weight: 300;
      }

      span {
        color: var(--theme-color-2);
        font-size: 14px;
      }

      .price-wrap {
        p {
          font-size: 19px;
        }

        mat-icon {
          color: var(--theme-default);
        }

        .mat-mini-fab.mat-primary {
          box-shadow: none;

          mat-icon {
            transition: 0.3s;
            font-size: 20px;
            line-height: 24px;
          }

          background: var(--theme-default);
        }

        a {
          background: var(--theme-orange);
          cursor: pointer;
          transition: 0.3s;
          width: 38px;
          height: 38px;
          background: var(--theme-default-grey);
          border-radius: 50%;
          text-align: center;
          line-height: 46px;

          &:hover {
            background: var(--theme-orange);
          }

          mat-icon {
            transition: 0.3s;
            font-size: 18px;
          }
        }
      }

      .title-wrap {
        h4 {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 500;
        }
      }
    }

    &:hover {
      .content {
        .icons {
          opacity: 0.85;
          visibility: visible;
          -webkit-transform: translate3d(0, 0px, 0);
          transform: translate3d(0, 0px, 0);
        }
      }
    }
  }

  button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME-1 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-1 styles
****************************************/
.main-slider {
  height: 550px;

  .sl-wrap {
    position: relative;
    height: 100%;

    .content {
      height: 100%;
      position: relative;
      z-index: 9;
      /* padding-left: 294px; */
      vertical-align: middle;
      position: relative;

      /* top: 58%; */
      button {
        background-color: var(--theme-default);
        color: var(--theme-default);
        padding: 5px 33px;
        width: 200px;
        box-shadow: none !important;

        span {
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      h1 {
        font-size: 48px;
        text-align: left;
        color: var(--theme-default-dark);
        text-transform: uppercase;
        letter-spacing: 3px;
      }

      h3 {
        font-size: 36px;
        text-align: left;
        color: var(--theme-default-dark);
        margin-bottom: 30px;
        font-weight: 300;
        letter-spacing: 2px;
      }
    }
  }

  .slide-item {
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}


.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

/***************************************
*  Home info bar styles
****************************************/
.info-bar {
  margin-top: 30px;
  border-bottom: 1px solid var(--theme-default-grey);

  .card-wrap {
    padding: 15px 0;

    .mat-card {
      height: 100px;
      box-shadow: none;
      border-right: 1px solid var(--theme-default-grey);

      &.no-bd {
        border-right: none;
      }
    }

    .mat-icon {
      color: var(--theme-default-dark);
      font-size: 41px;
      margin-right: 15px;
      line-height: 23px;
    }
  }
}

span:not(.builder-text) {
  font-size: 14px;
}

.sidenav {
  width: 250px;
  // padding: 8px 16px;
  position: fixed !important;
  z-index: 999999999999999999 !important;
}

.mat-toolbar-multiple-rows {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
}

.welcome-message p {
  font-size: 13.5px;
  color: var(--theme-color-2);
}

/***************************************
*  Header-1 styles
****************************************/

/*  Topbar styles */
.main-topbar {
  background: var(--theme-default) !important;

  .top-bar {
    .widget-text {
      padding: 9px 7px;

      a {
        color: var(--theme-default-dark);
        text-decoration: none;
        font-size: 13px;
        transition: 0.4s;

        .mat-icon {
          vertical-align: middle;
          margin-right: 1px !important;
          font-size: 23px;
        }

        &:hover {
          color: var(--theme-color-2);
        }
      }

      &:not(:last-child) {
        margin: 0 20px 0 -15px;
      }

      .mat-button-wrapper {
        img {
          margin-right: 3px;
        }
      }
    }

    .welcome-message {
      p {
        color: var(--theme-color-2);
        font-size: 13.5px;
        font-weight: 400;
      }
    }

    span {
      .mat-icon {
        color: var(--theme-orange);
      }
    }

    .cur-icon {
      color: var(--theme-color-2) !important;
    }
  }
}

/*  header styles */
.home-header {
  &.yellow {
    display: none;
  }

  .kuro {
    background-color: var(--theme-brand-color-1);
    padding: 13px 0;
    color: var(--theme-default-);
  }

  .call-bucket-wrap {
    .info-message-icon {
      color: var(--theme-default-secondary) !important;
    }
  }

  .top-navbar {
    background: transparent !important;

    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--theme-default) !important;

      .top-cart {
        span {
          font-size: 15px;
        }
      }
    }
  }

  .logo {
    padding: 25px 0;
  }

  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;

    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: var(--theme-default-dark);
      background: var(--theme-default-grey);

      input {
        border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border-color: var(--theme-default);
        -webkit-transition: box-shadow 0.3s ease-out;
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
      }
    }

    .btn-search {
      overflow: visible;
      position: absolute;
      border: 0;
      margin: 0;
      cursor: pointer;
      height: 100%;
      min-width: 50px;
      right: -15px;
      top: 0;
      padding: 0 35px;
      color: var(--theme-default);
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      text-transform: uppercase;
      background-color: var(--theme-default-dark);
      border-radius: 0 3px 3px 0;
      -webkit-border-radius: 0 3px 3px 0;
      text-shadow: 0 -1px 0 var(--theme-default-grey);
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-appearance: none;
    }
  }

  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
        }
      }

      .info-text {
        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .cart-count-wrapper {
    position: absolute;
    top: -10px;
    left: 30px;
    background: var(--theme-white);
    height: 18px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    font-size: 11px;
  }
}

.home-header-yellow {
  background: var(--theme-main-yellow);
  padding: 13px 0;

  &.green {
    display: none;
  }

  &.blue {
    display: none;
  }

  &.red {
    display: none;
  }

  &.pink {
    display: none;
  }

  &.purple {
    display: none;
  }

  &.grey {
    display: none;
  }

  &.orange {
    display: none;
  }

  .kuro {
    background-color: var(--theme-brand-color-1);
  }

  .call-bucket-wrap {
    .info-message-icon {
    }
  }

  .top-navbar {
    background: transparent !important;

    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .top-cart {
        span {
          font-size: 15px;
        }
      }

      .commerce-buttons {
        .flex-row-button {
          .mat-button-wrapper {
            .cart-count-wrapper {
              position: absolute;
              top: -10px;
              left: 30px;
              background: var(--theme-default);
              height: 18px;
              width: 18px;
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .logo {
    padding: 25px 0;
  }

  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;

    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: var(--theme-default-dark);
      background: var(--theme-default-grey);

      input {
        border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border-color: var(--theme-default);
        -webkit-transition: box-shadow 0.3s ease-out;
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
      }
    }

    .btn-search {
      overflow: visible;
      position: absolute;
      border: 0;
      margin: 0;
      cursor: pointer;
      height: 100%;
      min-width: 50px;
      right: -15px;
      top: 0;
      padding: 0 35px;
      color: var(--theme-default);
      -webkit-transition: all 250ms ease-in-out;
      -moz-transition: all 250ms ease-in-out;
      -ms-transition: all 250ms ease-in-out;
      -o-transition: all 250ms ease-in-out;
      transition: all 250ms ease-in-out;
      text-transform: uppercase;
      background-color: var(--theme-default-dark);
      border-radius: 0 3px 3px 0;
      -webkit-border-radius: 0 3px 3px 0;
      text-shadow: 0 -1px 0 var(--theme-default-grey);
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-appearance: none;
    }
  }

  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          color: var(--theme-default-secondary);
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
        }
      }

      .info-text {
        color: var(--theme-default-secondary);

        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .cart-count-wrapper {
    position: absolute;
    top: -10px;
    left: 30px;
    background: var(--theme-default);
    height: 18px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    font-size: 11px;
  }
}

/*  shopping widgets styles */

.spCard-main {
  width: 100%;

  .mat-menu-content {
    width: 239px !important;
  }

  .spCard-dropdown {
    background-color: var(--theme-default);
    padding: 15px 10px;
    max-width: 300px;
    z-index: 400;

    .card-list-title {
      padding: 0 0 10px 0;
    }

    h4 {
      padding-bottom: 10px;
    }

    .new-product {
      .product {
        img {
          width: 70px;
        }
      }

      .close-circle {
        flex: 1;

        a {
          cursor: pointer;
          color: var(--theme-default-grey);
          transition: 0.3s;
          float: right;

          &:hover {
            color: var(--theme-orange);
          }

          i {
            font-size: 20px;
          }
        }
      }

      .item {
        padding: 10px 0;

        .product {
          margin-right: 7px;
          background: var(--theme-default-grey);
          border-radius: 3px;
        }

        .desc {
          p {
            font-weight: 500;
          }

          span {
            color: var(--theme-color-2);
            font-size: 15px;
            margin-top: 5px;
            /* padding-top: 7px; */
            display: block;
          }
        }
      }

      .total {
        padding-top: 15px;

        p {
          font-size: 15px;
        }

        span {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .card-list-title {
      a {
        color: var(--theme-default-dark);
        font-size: 14px;
        font-weight: 500;
      }

      p {
        color: var(--theme-default-dark);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .woo-message {
      text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
    }
  }
}

/***************************************
*  Shopping widget styles
****************************************/

.card-list-title2 {
  padding: 5px 0 15px 0;
}

/***************************************
*  Home product section styles
****************************************/

.products {
  position: relative;

  .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
    pointer-events: auto;
  }

  .product-wraper {
    padding: 20px 0px 20px 20px;

    .product-box {
      width: 100%;
    }
  }
}

/***************************************
*  Home baners section styles
****************************************/
.banners-container {
  .baners {
    background: var(--theme-default-grey) !important;
    margin-right: 15px;
  }

  .bn-wrap {
    margin-bottom: 18px;
  }

  .baner {
    height: 100%;
    transition: 0.15s ease-in;
    text-align: left;

    .info {
      padding: 20px;

      h4 {
        font-size: 21px;
        font-weight: 600;
        color: var(--theme-default-dark);
        margin-bottom: 5px;
        text-transform: uppercase;
      }
    }

    h4.big-title {
      font-size: 37px;
      font-weight: 500;
      color: var(--theme-default-dark);
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .info {
      .big-price {
        padding: 10px 25px !important;
        box-shadow: none !important;
        width: 135px;
        border-radius: 5px;
        text-align: center;

        span {
          font-size: 27px !important;
        }
      }
    }

    p {
      color: var(--theme-default-dark);
    }

    a {
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      color: var(--theme-default-dark);
      margin-top: 15px;
      display: block;
    }
  }

  .first {
    height: 450px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 4px;
  }

  .seccond {
    /* height: 200px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    margin: 0 10px;
    background-color: var(--theme-default-grey);
  }
}

/***************************************
*  Home vertical-product section styles
****************************************/
.vertical-products {
  .widget-column {
    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;

      .widget-title {
        font-size: 21px;
        font-weight: 500;

        &::before {
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
          height: 0.0625rem;
          bottom: 0.34375rem;
          background-color: var(--theme-default-grey);
        }

        &::after {
          content: "";
          left: 0;
          position: absolute;
          width: 2.5rem;
          height: 0.125rem;
          background-color: var(--theme-default-dark);
          bottom: 0.3125rem;
        }
      }
    }

    .media-image {
      background: var(--theme-default-grey);
      margin-right: 20px;
      width: 80px;
      height: 80px;
      border-radius: 3px;

      .small {
        width: 100%;
      }
    }

    .media-body {
      span {
        font-size: 17px;
        text-transform: capitalize;
        font-weight: 500;
        letter-spacing: 0.03em;
        line-height: 1;
        color: var(--theme-default-dark);
      }

      .stars {
        mat-icon {
          color: var(--theme-orange);
          font-size: 17px;
          line-height: 17px;
          height: 17px;
          width: 17px;
          vertical-align: middle;
        }
      }

      p.price {
        margin-top: 5px;
      }
    }

    .product-list {
      li:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .product-panel:not(:last-child) {
    padding-right: 30px;
  }
}

/***************************************
*  Home footer section styles
****************************************/
.footer {
  background-color: var(--theme-default-grey);

  .footer-widget {
    padding-right: 15px;

    .social {
      margin-top: 20px;

      li {
        // width: 33px;
        // height: 33px;
        border: 1px solid var(--theme-default-blackish);
        border-radius: 50%;
        text-align: center;
        margin-right: 5px;

        a {
          i {
            color: var(--theme-default-blackish);
          }
        }
      }
    }

    .ped-btn-footer {
      box-shadow: none !important;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      z-index: 222;
      background-color: var(--theme-default-blackish) !important;
      color: var(--theme-default);
      border-radius: 3px;
      line-height: 45px;
    }

    .title {
      margin-bottom: 10px;

      h5 {
        font-size: 18px;
        font-weight: 600;
      }
    }

    a {
      text-decoration: none;
      font-size: 15px;
      line-height: 32px;
      color: var(--theme-default-blackish);
    }

    .newsletter-widget {
      position: relative;
      margin: 15px 0 20px 0;
    }

    p {
      line-height: 24px;
      color: var(--theme-default-blackish);
    }

    ul {
      list-style-type: none;
    }

    .about-widget {
      .logo {
        img {
          max-width: 240px;
        }
      }
    }

    .tel-box {
      .icon {
        i {
          color: var(--theme-default-blackish);
          display: block;
          line-height: 27px;
          vertical-align: middle;
          margin-right: 5px;
          width: 18px;
          text-align: center;
          font-size: 18px;
        }
      }

      .text {
        p {
          a {
            line-height: 22px;
          }
        }
      }
    }
  }

  .subscribe-widget {
    input {
      height: 45px;
      background: var(--theme-default);
      font-size: 15px;
      line-height: 45px;
      border: 1px solid var(--theme-default-grey);
      border-radius: 4px;
      padding: 0 65px 0 20px;
      color: var(--theme-color-2);
    }
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 2 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-2 styles
****************************************/
.carousel-2 {
  padding-top: 25px;

  .ctn-box {
    box-sizing: content-box !important;
  }

  .main-slider {
    height: 461px;

    .sl-wrap {
      position: relative;
      height: 100%;

      .content {
        padding-left: 20px;
        padding-right: 400px;

        height: 100%;
        position: relative;
        z-index: 9;
        /* padding-left: 294px; */
        vertical-align: middle;
        position: relative;
        /* top: 58%; */
        transform: none;
      }
    }
  }

  .slider-categories {
    padding-right: 15px;

    .categories-wrap {
      border: 1px solid var(--theme-default-grey);

      .title {
        background: var(--theme-default-grey);
        border-bottom: 1px solid var(--theme-color-2);
        color: var(--theme-default-dark);
        padding: 15px 20px 15px 10px;
        font-weight: 500;
        font-size: 14.5px;
      }

      .category-contant {
        ul {
          li {
            display: block;

            a {
              padding-top: 13px;
              padding-bottom: 13px !important;
              border-top: 1px solid var(--theme-default-grey);
              margin: 0 10px -1px;
            }

            .no-brd {
              border-top: none;
            }
          }
        }
      }

      .btn-sale {
        margin: 20px 10px;

        button {
          padding: 5px 33px;
          width: 100%;
          box-shadow: none;
        }
      }
    }
  }

  .carousel-2-wrapper {
    max-width: 70%;

    .main-slider {
      height: 461px;

      .content {
        padding-left: 20px;
        padding-right: 485px;
      }
    }
  }
}

/***************************************
*  Header-2 styles
****************************************/

.home-header-2 {
  background: var(--theme-default);

  .logo {
    padding: 25px 0;
  }

  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;

    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: var(--theme-default-dark);
      background: var(--theme-default-grey);

      input {
        border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border: 1px solid var(--theme-default-grey);
        -webkit-transition: box-shadow 0.3s ease-out;
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
      }

      .btn-search {
        overflow: visible;
        position: absolute;
        border: 0;
        margin: 0;
        cursor: pointer;
        height: 100%;
        min-width: 50px;
        right: -15px;
        top: 0;
        padding: 0 35px;
        color: var(--theme-default);
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
        text-transform: uppercase;
        background-color: var(--theme-default-dark);
        border-radius: 0 3px 3px 0;
        -webkit-border-radius: 0 3px 3px 0;
        text-shadow: 0 -1px 0 var(--theme-default-grey);
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-appearance: none;
      }
    }
  }

  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
        }
      }

      .info-text {
        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .main-menu-wrapper-2 {
      .top-navbar {
        background: var(--theme-default);

        .mat-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .top-cart {
            span {
              font-size: 15px;
            }
          }
        }

        .commerce-buttons {
          .mat-button-wrapper {
            .cart-count-wrapper {
              position: absolute;
              top: -10px;
              left: 30px;
              color: var(--theme-default);
              background-color: var(--theme-default);
              height: 18px;
              width: 18px;
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

/***************************************
*  Header-5 styles
****************************************/

/*  header styles */
.home-header-five {
  background: var(--theme-default);

  .header-wrapper {
    border-bottom: 1px solid var(--theme-default-grey);
    padding: 20px 0;

    .phone-wrap {
      .info-text {
        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .top-navbar {
      background: transparent;

      .mat-button-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .top-cart {
          span {
            font-size: 15px;
          }
        }
      }
    }

    .thm-button {
      button {
        color: var(--theme-default) !important;
        border-radius: 0;
        padding: 4px 40px;
      }
    }
  }
}

/*  menu styles */
.menu-wraper-five {
  background: var(--theme-default);

  .menu-container {
    padding: 14px 0;
    height: 67px;

    .mat-toolbar-row {
      height: 100%;
    }

    .main-menu {
      margin-left: -15px;

      a {
        text-transform: uppercase;
        color: var(--theme-color-2);
      }
    }

    .call-bucket-wrap {
      padding-top: 10px;

      .top-navbar {
        flex-direction: row !important;
        box-sizing: border-box;
        display: flex;
        background: transparent !important;

        .info-message-icon {
          .icon {
            mat-icon {
              font-size: 35px;
              display: block;
              float: left;
              height: 49px;
              margin: 0 16px 0 0;
            }
          }
        }

        /*  shopping widgets styles */
        .commerce-buttons {
          margin-top: 7px;

          .flex-row-button {
            .mat-button-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;

              .cart-count-wrapper {
                position: absolute;
                top: -10px;
                left: 30px;
                background: var(--theme-default);
                height: 18px;
                width: 18px;
                line-height: 18px;
                border-radius: 50%;
                font-size: 11px;
                color: var(--theme-default);
              }
            }
          }

          .badge {
            .mat-badge-content {
              color: var(--theme-default);
            }
          }
        }

        .search-form {
          margin-right: 20px;

          .mat-form-field-wrapper {
            .mat-form-field-underline {
              background-color: var(--theme-default-grey) !important;
            }

            .mat-form-field-label {
              display: none;
            }
          }
        }

        .devider2 {
          height: 31px;
          width: 1px;
          background: var(--theme-default-grey);
          margin: 0 20px;
        }
      }
    }
  }
}

.spCard-main {
  width: 100%;

  .mat-menu-content {
    width: 239px !important;
  }

  .spCard-dropdown {
    background-color: var(--theme-default);
    padding: 15px 10px;
    max-width: 300px;
    z-index: 400;

    .card-list-title {
      padding: 0 0 10px 0;
    }

    h4 {
      padding-bottom: 10px;
    }

    .new-product {
      .product {
        img {
          width: 70px;
        }
      }

      .close-circle {
        flex: 1;

        a {
          cursor: pointer;
          color: var(--theme-default-grey);
          transition: 0.3s;
          float: right;

          i {
            font-size: 20px;
          }
        }
      }

      .item {
        padding: 10px 0;

        .product {
          margin-right: 7px;
          background: var(--theme-default);
          /* border-radius: 3px; */
          border: 1px solid var(--theme-default-grey);
        }

        .desc {
          p {
            font-weight: 500;
          }

          span {
            color: var(--theme-color-2);
            font-size: 15px;
            margin-top: 5px;
            /* padding-top: 7px; */
            display: block;
          }
        }
      }

      .total {
        padding-top: 15px;

        p {
          font-size: 15px;
        }

        span {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .card-list-title {
      a {
        color: var(--theme-default-dark);
        font-size: 14px;
        font-weight: 500;
      }

      p {
        color: var(--theme-default-dark);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .woo-message {
      text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
    }
  }
}

/***************************************
*  Home-2 info bar styles
****************************************/
.info-bar {
  margin-top: 30px;
  border: 1px solid var(--theme-default-grey);

  .card-wrap {
    padding: 15px 0;

    .mat-card {
      height: 100px;
      box-shadow: none;
      border-right: 1px solid var(--theme-default-grey);
      box-shadow: none !important;
      background: transparent !important;

      .content {
        p {
          font-weight: 600;
          font-size: 14.5px;
        }
      }

      &.no-bd {
        border-right: none;
      }
    }

    .mat-icon {
      color: var(--theme-default);
      font-size: 41px;
      margin-right: 10px;
      line-height: 65px;
      clear: both;
      /* display: block; */
      width: auto;
      height: 100%;
      /* vertical-align: middle; */
      padding: 0 3px;
    }
  }
}

/***************************************
*  Home-2 product section styles
****************************************/
.home-2-wrap {
  .products-section {
    background: var(--theme-default-grey);

    .products {
      padding: 0 !important;

      .product-wraper {
        .product-box {
          .product-item {
            width: 240px;

            .product-img {
              background: var(--theme-default);
            }
          }
        }
      }

      .mat-tab-labels {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    h3 {
      margin-bottom: 0;
      display: inline-block;
      font-size: 23px;
      font-weight: 580;
      position: absolute;
    }
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 3 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-3 styles
****************************************/

.carousel-3 {
  background: var(--theme-default);

  .ctn-box {
    box-sizing: content-box !important;
  }

  padding: 25px 0;

  .carousel-2-wrapper {
    //  max-width: 100% !important;
    .main-slider {
      height: 461px;

      .sl-wrap {
        position: relative;
        height: 100%;

        .content {
          padding-left: 20px;
          padding-right: 400px;

          height: 100%;
          position: relative;
          z-index: 9;
          /* padding-left: 294px; */
          vertical-align: middle;
          position: relative;
          /* top: 58%; */
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 20px;
          height: 5px;
          display: inline-block;
          border-radius: 13px;
          background: var(--theme-default-dark);
          opacity: 0.6;
        }
      }
    }

    max-width: 70%;
  }

  .slider-categories {
    padding: 0 !important;

    .carousel-banners-wrapper {
      padding-left: 15px;

      .carousel-banner {
        width: 100%;
        height: 223px;
        background: var(--theme-default-grey);

        .seccond {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 4px;
          margin: 0 10px;

          .info {
            padding: 40px 0;

            .bn-img {
              img {
                width: 100%;
                height: auto;
              }
            }

            .text-box {
              h4 {
                font-size: 24px;
                font-weight: 700;
                text-transform: uppercase;
              }

              button {
                color: var(--theme-default);
                text-decoration: underline;
                cursor: pointer;
                outline: none;
                border: none;
                -webkit-tap-highlight-color: transparent;
                display: inline-block;
                white-space: nowrap;
                vertical-align: baseline;
                text-align: left;
                margin-top: 21px;
                min-width: auto !important;
                line-height: 0 !important;
                padding: 0 !important;
                border-radius: 4px;
                overflow: visible;
                box-shadow: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.home-3-wrap {
  .home3-sidebar {
    padding-right: 15px;

    .categories-wrap {
      border: 1px solid var(--theme-color-2);
      margin-bottom: 25px;

      .title {
        background: var(--theme-default-grey);
        border-bottom: 1px solid var(--theme-color-2);
        color: var(--theme-default-dark);
        padding: 15px 20px 15px 10px;
        font-weight: 500;
        font-size: 14.5px;
      }

      .category-contant {
        ul {
          li {
            display: block;

            a {
              padding-top: 13px;
              padding-bottom: 13px !important;
              border-top: 1px solid var(--theme-color-2);
              margin: 0 10px -1px;
            }

            .no-brd {
              border-top: none;
            }
          }
        }
      }

      .btn-sale {
        margin: 20px 10px;

        button {
          padding: 5px 33px;
          width: 100%;
          box-shadow: none !important;
        }
      }
    }

    .brands {
      border: 1px solid var(--theme-color-2);
      padding: 15px 20px;
      margin-bottom: 25px;

      .title-header {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 1.25rem;

        h4 {
          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: var(--theme-default-grey);
          }

          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: var(--theme-default-dark);
            bottom: 0.3125rem;
          }
        }
      }

      .brand-logos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1em;
        grid-column-gap: 1em;

        .brand-item {
          display: inline-block;
          text-align: center;
          vertical-align: top;
          overflow: hidden;
          position: relative;
          margin-right: 10px;

          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .mat-toolbar {
      height: 17%;
      margin-bottom: 25px;
      background-color: var(--theme-default);

      .sale-widget {
        padding: 15px 20px;
        text-align: center;
        margin-bottom: 25px;

        .sale-widget1 {
          color: var(--theme-default);
          font-size: 36px;
          font-weight: 600;
          display: block;
          letter-spacing: 0.5;
        }

        .sale-widget2 {
          color: var(--theme-default);
          font-size: 18px;
          font-weight: 600;
          display: block;
          letter-spacing: 0.5;
        }

        .discount {
          display: inline-block;
          color: var(--theme-default-dark);
          font-size: 77px;
          font-weight: 600;
          position: relative;
          letter-spacing: 0.5;
        }

        .percent {
          display: inline-block;
          line-height: 31px;

          span {
            display: block;
            font-size: 2.5em;
            font-weight: 500;
          }
        }

        p {
          color: var(--theme-default);
          line-height: 24px;
          font-weight: 300;
          font-size: 15px;
        }
      }
    }

    .subscribe-widget {
      padding: 15px 20px;
      background: var(--theme-default-grey);
      text-align: center;

      span {
        font-weight: 600;
        display: block;
      }

      p {
        color: var(--theme-default-dark);
        line-height: 24px;
        font-weight: 300;
        font-size: 15px;
      }

      .form {
        position: relative;
        margin-top: 20px;

        .newsletter-widget {
          input {
            height: 45px;
            background: var(--theme-default);
            font-size: 15px;
            line-height: 45px;
            border: 1px solid var(--theme-default-grey);
            border-radius: 4px;
            padding: 0 65px 0 20px;
            color: var(--theme-color-2);
          }

          .ped-btn-footer {
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            margin-top: 10px;
            padding: 0px 10px;
            text-align: center;
            text-transform: uppercase;
            transition: all 0.4s ease 0s;
            z-index: 222;
            width: 100%;
            border-radius: 3px;
            line-height: 45px;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .main-home3-wrapper {
    padding-left: 15px;

    .info-bar {
      margin-top: 0px;
      border: 1px solid var(--theme-default-grey);

      .card-wrap {
        padding: 15px 0;

        .mat-card {
          height: 100px;
          box-shadow: none;
          border-right: 1px solid var(--theme-default-grey);
          padding: 9px;

          .content {
            p {
              font-weight: 600;
              font-size: 14.5px;
            }
          }

          &.no-bd {
            border-right: none;
          }

          .content {
            p {
              font-weight: 600;
              font-size: 14.5px;
            }
          }
        }

        .mat-icon {
          font-size: 41px;
          margin-right: 10px;
          line-height: 65px;
          width: 25%;
        }
      }
    }

    .products {
      background: var(--theme-default);
      position: relative;

      .product-wraper {
        .product-box {
          .product-item {
            width: 227px;
          }
        }
      }

      .mat-tab-body-wrapper {
        .mat-tab-body {
          .mat-tab-body-content {
            height: 100%;
          }
        }
      }

      .mat-tab-labels {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    h3 {
      margin-bottom: 0;
      display: inline-block;
      font-size: 22px;
      font-weight: 500;
      position: absolute;
      top: 69px;
    }

    .home3-banner-wrap {
      width: 100%;

      .banner {
        position: relative;

        .text-wrap {
          position: absolute;
          top: 33px;
          left: 35px;

          font-size: 95%;
          z-index: 9999999999999;

          .subtitle {
            color: var(--theme-default);
          }

          .main {
            color: var(--theme-orange);
            font-size: 38px;
            font-weight: 600;
          }

          a {
            border: 1px solid var(--theme-orange);
            color: var(--theme-default-dark) !important;
            cursor: pointer;
            display: inline-block;
            font-size: 13px;
            font-weight: 500;
            margin-top: 14px;
            padding: 0px 10px;
            text-align: center;
            text-transform: uppercase;
            transition: all 0.4s ease 0s;
            z-index: 222;
            background: var(--theme-orange);
            width: 120px;
            border-radius: 3px;
            line-height: 40px;
          }
        }

        img {
          position: relative;
          display: block;
          height: auto;
          max-width: 100%;
          width: calc(100% + 60px);
        }
      }
    }
  }
}

.menu-container1 {
  background: var(--theme-default-grey);

  a.mat-button {
    text-transform: uppercase;
    color: var(--theme-default-dark);
    font-size: 1em;
    font-weight: 700;
    padding: 0 25px;
    line-height: 63px;
  }
}

/***************************************
*  Menu-2 styles
****************************************/

.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  // padding: 0 13px !important;
  border-top: 4px solid var(--theme-orange) !important;

  .mat-menu-item {
    border-bottom: 1px dashed var(--theme-default-grey);
  }
}

.top-navbar a {
  padding: 15px 26px;
}

.menu-container2 {
  background: var(--theme-default);
  .mat-toolbar {
    background: transparent !important;
  }

  a.mat-button {
    text-transform: uppercase;
    color: var(--theme-default) !important;
    font-size: 1em;
    font-weight: 700;
    padding: 0 25px;
    line-height: 63px;
  }
}

/***************************************
*  Sidebar styles
****************************************/

.mat-drawer {
  width: 240px;
  // padding: 8px 14px;
  position: fixed;
  margin-left: 0px;
}

.toggle-header {
  height: 64px;
  background: var(--theme-main-red);
}

.sidebar-menu {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--theme-default-grey);

  .menu-item {
    a {
      color: var(--theme-default-dark);
      padding: 8px 15px;
      font-size: 16px;
      display: block;
    }
  }
}

/***************************************
*  Products left sidebar page styles
****************************************/

.left-sidebar-wrapper
  mat-sidenav.filter-sidenav.mat-drawer.mat-sidenav.ng-tns-c15-4.ng-trigger.ng-trigger-transform.ng-tns-c13-3.ng-star-inserted.mat-drawer-over {
  padding-right: 10px;
}

.left-sidebar-wrapper {
  .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid var(--theme-color-2);
    box-shadow: none !important;
    margin-bottom: 20px;
    background: transparent !important;

    .mat-expansion-panel-header-title span {
      font-size: 17px;
      text-transform: capitalize;
      font-weight: 500;

      line-height: 1;
    }
  }

  .filter-sidenav {
    width: 280px;
    border: 0 !important;

    .brands {
      .title-header {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 1.25rem;

        h4 {
          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: var(--theme-default-grey);
          }

          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: var(--theme-default-dark);
            bottom: 0.3125rem;
          }
        }
      }

      .brand-logos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1em;
        grid-column-gap: 1em;

        .brand-item {
          display: inline-block;
          text-align: center;
          vertical-align: top;
          overflow: hidden;
          position: relative;
          margin-right: 10px;

          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .popular-products {
      margin-top: 20px;
    }
  }

  .filter-row {
    padding: 6px 25px 0px;
    border-bottom: 1px solid var(--theme-default-grey);
    border-top: 1px solid var(--theme-default-grey);
    margin-bottom: 20px;
    box-shadow: none !important;
  }

  .all-products {
    padding-left: 17px;

    select {
      /* margin: 50px; */
      width: 150px;
      padding: 5px 35px 5px 5px;
      font-size: 16px;
      border: none;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;

      cursor: pointer;
      background: url(/assets/images/dropdown.png) no-repeat 90%;
    }

    .col {
      padding: 9px 0 9px 0px;

      .product-item {
        position: relative;
        height: auto;
        padding: 0 10px;
        transition: 0.4s;

        .mat-card:not([class*="mat-elevation-z"]) {
          box-shadow:
            2px 2px 6px 0px var(--theme-default-grey),
            0 1px 1px 0 var(--theme-default-grey),
            0 1px 3px 0 var(--theme-default-grey) !important;
          padding: 0;

          .product-img {
            background: var(--theme-default-grey);

            img {
              max-width: 180px;
              margin-bottom: 12px;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }

            .icons {
              position: absolute;
              top: 30px;
              right: 10px;
              opacity: 0;
              transition: all 0.5s ease;

              a {
                color: var(--theme-default-dark);
                cursor: pointer;
                transition: 0.5s ease;
                margin-bottom: 5px;
              }
            }
          }

          .product-info-wrapper {
            padding: 15px;

            .stars {
              color: var(--theme-orange);
              margin: 2px 0 10px 0;

              span {
                color: var(--theme-default-grey);
                font-size: 13px;
                margin-left: 10px;
              }

              mat-icon {
                font-size: 17px;
                line-height: 17px;
                height: 17px;
                width: 17px;
                vertical-align: middle;
              }
            }

            .category {
              margin-bottom: 4px;
              display: block;
              font-weight: 300;
            }

            span {
              color: var(--theme-color-2);
              font-size: 14px;
            }

            .price-wrap {
              p {
                font-size: 19px;
              }

              mat-icon {
                color: var(--theme-default);
              }

              a {
                cursor: pointer;
                transition: 0.3s;
                width: 38px;
                height: 38px;
                background: var(--theme-default);
                border-radius: 50%;
                text-align: center;
                line-height: 46px;

                mat-icon {
                  transition: 0.3s;
                  font-size: 18px;
                }
              }

              .mat-mini-fab.mat-primary {
                box-shadow: none;

                .mat-icon {
                  transition: 0.3s;
                  font-size: 20px;
                  line-height: 24px;
                }
              }
            }

            .title-wrap {
              h4 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: 500;
              }
            }
          }

          &:hover {
            .icons {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .no-shadow {
    box-shadow: none !important;
  }
}

/***************************************
*  Products left sidebar page styles
****************************************/

.right-sidebar-wrapper {
  .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid var(--theme-color-2);
    box-shadow: none !important;
    margin-bottom: 20px;
    background: transparent !important;

    .mat-expansion-panel-header-title span {
      font-size: 17px;
      text-transform: capitalize;
      font-weight: 500;

      line-height: 1;
    }
  }

  .filter-sidenav {
    width: 280px;
    border: 0 !important;

    .brands {
      .title-header {
        margin-bottom: 15px;
        position: relative;
        padding-bottom: 1.25rem;

        h4 {
          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: var(--theme-default-grey);
          }

          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: var(--theme-default-dark);
            bottom: 0.3125rem;
          }
        }
      }

      .brand-logos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 1em;
        grid-column-gap: 1em;

        .brand-item {
          display: inline-block;
          text-align: center;
          vertical-align: top;
          overflow: hidden;
          position: relative;
          margin-right: 10px;

          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    .popular-products {
      margin-top: 20px;
    }
  }

  .filter-row {
    padding: 6px 25px 0px;
    border-bottom: 1px solid var(--theme-default-grey);
    border-top: 1px solid var(--theme-default-grey);
    margin-bottom: 20px;
    box-shadow: none !important;
  }

  .all-products {
    select {
      /* margin: 50px; */
      width: 150px;
      padding: 5px 35px 5px 5px;
      font-size: 16px;
      border: none;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;

      cursor: pointer;
      background: url(/assets/images/dropdown.png) no-repeat 90%;
    }

    .col {
      padding: 9px 0 9px 0px;

      .product-box {
        min-height: 440px;
        width: 307px;
        margin-left: 9px !important;
        margin-right: 9px !important;
        box-shadow: 0 2px 14px var(--theme-color-2);
      }

      .product-item {
        .mat-card:not([class*="mat-elevation-z"]) {
          box-shadow: none !important;
          padding: 0;

          .product-img {
            background: var(--theme-default-grey);
            display: table-cell;
            vertical-align: middle;
            height: 306px;
            width: 299px;
            margin: 0 auto;

            img {
              /* max-height: 100%; */
              max-width: 85%;
              padding: 25px;
              margin: auto;
            }

            .icons {
              position: absolute;
              top: 30px;
              right: 10px;
              opacity: 0;
              transition: all 0.5s ease;

              a {
                color: var(--theme-default-dark);
                cursor: pointer;
                transition: 0.5s ease;
                margin-bottom: 5px;
              }
            }
          }

          .product-info-wrapper {
            padding: 15px;

            .stars {
              color: var(--theme-orange);
              margin: 2px 0 10px 0;

              span {
                color: var(--theme-default-grey);
                font-size: 13px;
                margin-left: 10px;
              }

              mat-icon {
                font-size: 17px;
                line-height: 17px;
                height: 17px;
                width: 17px;
                vertical-align: middle;
              }
            }

            .category {
              margin-bottom: 4px;
              display: block;
              font-weight: 300;
            }

            span {
              color: var(--theme-color-2);
              font-size: 14px;
            }

            .price-wrap {
              p {
                // font-size: 19px;
              }

              mat-icon {
                color: var(--theme-default);
              }

              a {
                background-color: var(--theme-default);
                cursor: pointer;
                transition: 0.3s;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                text-align: center;
                line-height: 46px;

                mat-icon {
                  transition: 0.3s;
                  font-size: 18px;
                }
              }

              .mat-mini-fab.mat-primary {
                box-shadow: none;

                .mat-icon {
                  transition: 0.3s;
                  font-size: 20px;
                  line-height: 24px;
                }
              }
            }

            .title-wrap {
              h4 {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-weight: 500;
              }
            }
          }

          &:hover {
            .icons {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .no-shadow {
    box-shadow: none !important;
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 4 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-4 styles
****************************************/

.home-header-three {
  background: var(--theme-default);
  border-top: 1px solid var(--theme-default-grey);

  .logo {
    padding: 25px 0;
  }

  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;

    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: var(--theme-default-dark);
      background: var(--theme-default-grey);

      input {
        border-style: none;
        border-width: 1px;
        border-radius: 3px;
        border: 2px solid var(--theme-default-grey);
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
      }

      .btn-search {
        overflow: visible;
        position: absolute;
        border: 0;
        margin: 0;
        cursor: pointer;
        height: 100%;
        min-width: 50px;
        right: -15px;
        top: 0;
        padding: 0 35px;
        transition: all 250ms ease-in-out;
        text-transform: uppercase;
        background-color: var(--theme-default);
        border-radius: 0 3px 3px 0;
        -webkit-border-radius: 0 3px 3px 0;
        text-shadow: none;
        box-shadow: none !important;
        -webkit-appearance: none;
      }
    }
  }

  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
        }
      }

      .info-text {
        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .main-menu-wrapper-3 {
      .top-navbar {
        background: transparent;

        .flex-row-button {
          background: transparent;

          .mat-button-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;

            .top-cart {
              span {
                font-size: 15px;
              }
            }

            .cart-count-wrapper {
              position: absolute;
              top: -10px;
              left: 30px;
              background: var(--theme-default);
              height: 18px;
              width: 18px;
              color: var(--theme-default);
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

.menu-container4 {
  background: var(--theme-default);
  border-top: 1px solid var(--theme-default-grey);

  .main-menu {
    margin-left: -15px;
    margin: 0 auto;

    a.mat-button {
      text-transform: uppercase;
      color: var(--theme-default);
      font-size: 1em;
      font-weight: 700;
      padding: 0 25px;
      line-height: 63px;
    }
  }
}

.topbar_four {
  background: var(--theme-default-grey) !important;

  .top-bar {
    .widget-text {
      padding: 9px 7px;

      a {
        color: var(--theme-default-dark);
      }
    }
  }
}

/***************************************
*  Home-4 info bar styles
****************************************/
.info-bar-four-wrapper {
  margin-top: 0px;
  border: none;
  background: var(--theme-default);
  border-bottom: 1px solid var(--theme-default-grey);

  .info-bar-four {
    .card-wrap {
      padding: 15px 0;

      .mat-card {
        height: 100px;
        box-shadow: none;
        border-right: 1px solid var(--theme-default-grey);
        box-shadow: none !important;
        background: transparent;

        .content {
          p {
            font-weight: 600;
            font-size: 14.5px;
          }
        }

        &.no-bd {
          border-right: none;
        }
      }

      .mat-icon {
        color: var(--theme-default);
        font-size: 41px;
        margin-right: 10px;
        line-height: 65px;
        clear: both;
        overflow: hidden;
        /* display: block; */
        width: auto;
        height: 100%;
        /* vertical-align: middle; */
        padding: 0 3px;
      }
    }
  }
}

/***************************************
*  Home-4 banner styles
****************************************/
.banners-four {
  padding: 25px 0;

  .banners-container {
    .four {
      height: 162px;
    }
  }
}

/***************************************
*  Home-4 products styles
****************************************/

.products {
  .products-four {
    h3 {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      position: relative;
      padding-bottom: 20px;

      &::before {
        content: "";
        left: 29%;
        position: absolute;
        width: 43%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: var(--theme-default-grey);
      }

      &::after {
        content: "";
        left: 48.5%;
        position: absolute;
        width: 2.5rem;
        height: 0.125rem;
        background-color: var(--theme-default-dark);
        bottom: 0.3125rem;
      }
    }
  }
}

.featured-products-wrap {
  background: var(--theme-default-grey);
}

/***************************************
*  Home-4 banner-promotion styles
****************************************/

.main-banner {
  .banner-pro {
    height: 442px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 5 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-5 styles
****************************************/

/***************************************
*  Menu-5 styles
****************************************/

.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  // padding: 0 13px !important;
  border-top: 4px solid var(--theme-default) !important;

  .mat-menu-item {
    border-bottom: 1px dashed var(--theme-default-grey);

    span {
      background: var(--theme-default) !important;
      font-size: 11px;
      font-weight: 400;
      color: var(--theme-default);
      padding: 1px 3px;
    }

    .soon {
      background: var(--theme-green) !important;
    }
  }
}

.top-navbar a {
  padding: 15px 26px;
}

.menu-container5 {
  background: transparent;

  .container {
    background: transparent;

    a.mat-button {
      text-transform: uppercase;
      color: var(--theme-default-dark);
      font-size: 1em;
      font-weight: 700;
      padding: 0 25px;
      line-height: 63px;
    }
  }
}

/***************************************
*  Categories menu styles
****************************************/
.categories-menu-wrap {
  .category-contant {
    ul {
      background-color: var(--theme-default-dark);

      li {
        width: 14.285%;
        transition: 0.3s;

        a {
          display: flex;
          flex-direction: column;
          line-height: 16px;
          letter-spacing: -0.13px;
          color: var(--theme-default);
          padding: 15px 16px 18px 16px;
          justify-content: center;
          text-align: center;
          transition: 0.3s;

          i {
            margin-bottom: 5px;
          }

          &:hover {
            color: var(--theme-default-dark) !important;
          }
        }

        &:hover {
          background: var(--theme-default-grey);
          color: var(--theme-default-dark) !important;
        }
      }
    }
  }
}

/***************************************
*  Products styles
****************************************/
.home-5-wrap {
  background: var(--theme-default);

  .products {
    position: relative;

    h3 {
      margin-bottom: 0;
      display: inline-block;
      font-size: 22px;
      font-weight: 500;
      position: absolute;
      top: 69px;
    }

    .mat-tab-labels {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

/***************************************
*  Categories section styles
****************************************/

.home-5-wrap {
  .categories {
    background: var(--theme-default-grey);

    .title {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 1.25rem;

      h3 {
        margin-bottom: 0;
        display: inline-block;
        font-size: 22px;
        font-weight: 500;

        &::before {
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
          height: 0.0625rem;
          bottom: 0.34375rem;
          background-color: var(--theme-default-grey);
        }

        &::after {
          content: "";
          left: 0;
          position: absolute;
          width: 2.5rem;
          height: 0.125rem;
          background-color: var(--theme-default-dark);
          bottom: 0.3125rem;
        }
      }
    }

    .left-section {
      padding-right: 7px;

      .item {
        background: var(--theme-default);
        height: 100%;
        padding: 20px;
        border: 1px solid var(--theme-default-grey);

        .product {
          text-align: center;

          img {
            max-width: 40%;
          }
        }

        .product-info {
          display: flex;
          flex-direction: row;
          justify-content: center;

          h4 {
            margin-right: 15px;

            a {
              color: var(--theme-default-dark);

              &:hover {
                text-decoration: underline;
              }
            }
          }

          ul {
            margin-left: 15px;

            li {
              a {
                color: var(--theme-color-2);
                font-size: 15px;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }

    .right-section {
      padding-left: 7px;

      .item {
        background: var(--theme-default);
        height: 100%;
        padding: 20px;
        border: 1px solid var(--theme-default-grey);

        .product {
          text-align: center;

          img {
            max-width: 40%;
          }
        }

        .product-info {
          display: flex;
          flex-direction: row;
          justify-content: center;

          h4 {
            margin-right: 15px;

            a {
              color: var(--theme-default-dark);

              &:hover {
                text-decoration: underline;
              }
            }
          }

          li {
            a {
              color: var(--theme-color-2);
              font-size: 15px;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - INDUSTRIAL STYLES
****************************************
****************************************
****************************************/
/***************************************


/***************************************
*  Home product section styles
****************************************/

.products-industrial {
  position: relative;

  .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
    pointer-events: auto;
  }

  .product-wraper {
    padding: 20px 0px 20px 20px;

    .product-box {
      width: 100%;
    }
  }

  .product-item-1 {
    .mat-card:not([class*="mat-elevation-z"]) {
      .product-img {
        background: var(--theme-default);
      }
    }
  }
}

/* Categories banners */

.industrial-home-wrap {
  .categories {
    background: var(--theme-default);

    .title {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 1.25rem;

      h3 {
        margin-bottom: 0;
        display: inline-block;
        font-size: 22px;
        font-weight: 500;

        &::before {
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
          height: 0.0625rem;
          bottom: 0.34375rem;
          background-color: var(--theme-default-grey);
        }

        &::after {
          content: "";
          left: 0;
          position: absolute;
          width: 2.5rem;
          height: 0.125rem;
          background-color: var(--theme-color-2);
          bottom: 0.3125rem;
        }
      }
    }

    .left-section {
      padding-right: 7px;

      .item {
        background: var(--theme-default-grey);
        height: 100%;
        padding: 20px;
        border: 1px solid var(--theme-default-grey);

        .product {
          text-align: center;

          img {
            max-width: 40%;
          }
        }

        .product-info {
          display: flex;
          flex-direction: row;
          justify-content: center;

          h4 {
            margin-right: 15px;

            a {
              color: var(--theme-default-dark);

              &:hover {
                text-decoration: underline;
              }
            }
          }

          ul {
            margin-left: 15px;

            li {
              a {
                color: var(--theme-color-2);
                font-size: 15px;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }

    .right-section {
      padding-left: 7px;

      .item {
        background: var(--theme-default-grey);
        height: 100%;
        padding: 20px;
        border: 1px solid var(--theme-default-grey);

        .product {
          text-align: center;

          img {
            max-width: 40%;
          }
        }

        .product-info {
          display: flex;
          flex-direction: row;
          justify-content: center;

          h4 {
            margin-right: 15px;

            a {
              color: var(--theme-default-dark);

              &:hover {
                text-decoration: underline;
              }
            }
          }

          li {
            a {
              color: var(--theme-color-2);
              font-size: 15px;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .h {
      height: 205px !important;
    }
  }
}

/* Info bar */

.info-wrap-industrial {
  position: relative;
  background: var(--theme-default-grey);

  .info-bar {
    .card-wrap {
      margin-bottom: 20px;

      .light-block {
        .icon {
          border: 2px solid var(--theme-default-grey);
          width: 70px;
          height: 70px;
          text-align: center;
          line-height: 89px;

          .mat-icon {
            font-size: 31px;
            margin-right: 5px;
            color: var(--theme-default) !important;
          }
        }

        .content {
          text-align: center;
          margin-top: 15px;

          p {
            font-weight: 600;
          }

          span {
            line-height: 26px;
          }
        }
      }
    }
  }

  .subscribe {
    padding: 50px;
    margin-top: 30px;
    background: var(--theme-default) !important;

    .subscribe-wrap {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;

      p {
        color: var(--theme-default);
        font-size: 16px;
      }

      .form-wrap {
        margin-left: 20px;

        input {
          background: var(--theme-default-secondary);
          width: 347px;
          border: none;
          color: var(--theme-default);
          padding: 2px 14px;
          height: 40px;
          opacity: 0.2;
        }

        button {
          width: 175px;
          height: 44px;
          color: var(--theme-default);
          background: transparent !important;
          border: 2px solid var(--theme-default);
          border-radius: 0;
          margin-left: 15px;
        }
      }
    }
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - FOOD STYLES
****************************************
****************************************
****************************************/

.topbar-food {
  background: var(--theme-default) !important;

  .top-bar {
    .widget-text {
      a {
        color: var(--theme-default);
        text-decoration: none;
        font-size: 13px;
        transition: 0.4s;

        .mat-icon {
          color: var(--theme-default) !important;
        }
      }
    }
  }
}

/* Header six section */

.header-six {
  position: relative;

  .header-wrapper {
    position: absolute;
    top: 20px;
    z-index: 9999;
    left: 0;
    right: 0;

    .logo {
      cursor: pointer;
    }

    .main-menu {
      margin-left: -15px;

      a {
        text-transform: uppercase;
        color: var(--theme-default);
      }
    }
  }
}

.mat-menu-content {
  width: 221px;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 4px solid var(--theme-default-grey) !important;
}

/* Product section */
.food-product-section {
  position: relative;
  top: -15px;
  background: url(/assets/images/background/download.svg) no-repeat 90%;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
  z-index: 989;

  .back-img1 {
    position: absolute;
    left: 0;
    top: 56px;
  }

  .back-img2 {
    position: absolute;
    left: 0;
    bottom: 56px;
  }

  .back-img3 {
    position: absolute;
    right: 0;
    bottom: 235px;
  }

  .head-title {
    text-align: center;

    h3 {
      text-align: center;
      margin-bottom: 15px;
    }

    p {
      line-height: 28px;
      margin-top: 0px;
    }
  }

  .prod-menu {
    margin-top: 45px;
    margin-bottom: 45px;

    ul {
      li {
        cursor: pointer;
        transition: 0.3s;

        .icon {
          height: 50px;

          svg {
            max-height: 38px;
            fill: var(--theme-default-dark) !important;

            .cls-1 {
              stroke: var(--theme-default-dark);
            }
          }
        }

        .text {
          p {
            color: var(--theme-color-2);
            margin: 0;
          }
        }

        &:hover {
          transition: 0.3s;

          .icon {
            svg {
              color: var(--theme-orange);
            }
          }

          .text {
            p {
              transition: 0.3s;
              color: var(--theme-orange);
            }
          }
        }
      }
    }
  }

  .product-wrapper {
    display: grid;
    // grid-template-rows: 150px 150px;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(4, 1fr);
    // grid-template-columns: 150px 150px 150px;
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    .product-box {
      .product-item {
        position: relative;
        overflow: hidden;

        // transition: all .5s ease;
        mat-card {
          padding: 0;
          box-shadow: none;
          border: 1px solid var(--theme-default-grey);

          .product-img {
            text-align: center;
            cursor: pointer;

            img {
              max-width: 180px;
            }
          }

          .product-info-wrapper {
            background: var(--theme-default-grey);
            padding: 15px;

            .category {
              text-align: left;
              font-weight: 400;
              color: var(--theme-color-2);
            }

            .title-wrap {
              margin: 7px 0;

              h4 {
                font-size: 21px;
                margin: 0;
              }

              p {
                font-size: 21px;
                font-family: "Gelasio", serif;
                font-weight: 600;
                margin: 0;
              }
            }

            .stars {
              .mat-icon {
                font-size: 20px;
                height: 22px;
                width: 22px;
                color: var(--theme-orange);
              }
            }

            .btn-wrap {
              margin-top: 10px;

              .view-more {
                font-weight: 500;
                color: var(--theme-color-2);
                cursor: pointer;
              }

              a {
                cursor: pointer;

                .mat-icon {
                  color: var(--theme-default) !important;
                }
              }

              .bucket {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                background: var(--theme-default-grey);
                text-align: center;
                vertical-align: middle;
                line-height: 49px;

                //   box-shadow: none;
                //   cursor: pointer;
                a {
                  mat-icon {
                  }
                }
              }
            }

            .details-products {
              .more-info {
                margin-top: 15px;
                overflow: hidden;

                p {
                  line-height: 24px;
                }
              }
            }

            .icons {
              position: absolute;
              top: 30px;
              right: -10px;
              opacity: 0;

              // transition: all .6s ease;
              mat-icon {
                color: var(--theme-default-dark);
                cursor: pointer;
              }
            }
          }

          &:hover {
            .icons {
              opacity: 1;
              transition: all 0.4s ease;
              transform: translateX(-20px);

              .ic-1 {
                animation-delay: 2s;
              }

              .ic-2 {
                animation: 0.5s ease-in-out fadeInRight;
              }

              .ic-3 {
                animation: 0.7s ease-in-out fadeInRight;
              }
            }
          }
        }
      }
    }
  }
}

/* Product details page */

.food-details-wrap {
  .header-title {
    background-size: cover;
    padding: 130px 0;
    position: relative;

    .title {
      text-align: center;

      h2 {
        color: var(--theme-default);
        font-size: 52px;
        margin: 0;
      }

      p {
        color: var(--theme-default);
        line-height: 26px;
        margin: 0;
      }
    }
  }

  .details-wrapper {
    position: relative;
    top: -15px;
    background-size: auto 75px;
    background-repeat: repeat-x;
    background-position: center 0;

    .product-image {
      box-shadow: none !important;
      border: 1px solid var(--theme-default-grey);
      border-radius: 0;

      img {
        max-width: 100%;
      }

      a {
        cursor: pointer;
      }
    }

    .product-right {
      padding: 0 30px 0 25px;

      .product-details {
        padding-bottom: 15px;
        border-bottom: 1px dashed var(--theme-color-2);

        h2 {
          margin-bottom: 10px;
        }

        h4 {
          del {
            color: var(--theme-color-2);
            font-size: 17px;
          }

          span {
            padding-left: 5px;
            color: var(--theme-main-red);
            font-size: 24px;
          }
        }

        .avalibility {
          padding-bottom: 15px;

          span {
            color: var(--theme-main-red);
            font-weight: 500;
          }
        }

        p.description {
          line-height: 26px;
        }

        h3.price {
          font-size: 26px;
          font-weight: 400;
          margin: 10px 0;
        }
      }

      .py-1 {
        .red {
          font-weight: 600;
          color: var(--theme-default);
        }
      }

      .quantity {
        margin-top: 10px;

        span {
          margin-bottom: 0;
          display: block;

          .mat-icon {
            font-weight: 300;
            font-size: 20px;
          }
        }

        .quantity-box {
          border: 1px solid var(--theme-default-grey);
          margin-right: 10px;

          input {
            width: 48px;
            text-align: center;
            background-color: var(--theme-default);
            height: 100%;
            border: none;
            border-left: 1px solid var(--theme-default-grey);
            border-right: 1px solid var(--theme-default-grey);
          }

          button {
            line-height: 44px;
          }
        }
      }

      .social-icons {
        padding-top: 15px;
        border-top: 1px dashed var(--theme-color-2);

        ul {
          li {
            width: 33px;
            height: 33px;
            border: 1px solid var(--theme-default-grey);
            border-radius: 50%;
            text-align: center;
            margin-right: 5px;

            a {
              text-decoration: none;
              font-size: 15px;
              line-height: 32px;
              color: var(--theme-color-2);

              i {
                color: var(--theme-default-dark);
              }
            }
          }
        }
      }
    }

    .info-bar {
      border: 1px solid var(--theme-color-2);
      padding: 0 20px;
      margin-top: 0px !important;

      .mat-icon {
        color: var(--theme-default-dark);
        font-size: 41px;
        margin-right: 15px;
        line-height: 23px;
      }

      .content {
        p {
          text-transform: capitalize;
          font-weight: 500;
        }
      }

      .mat-card {
        box-shadow: none !important;
        padding: 20px 0;
        border-bottom: 1px solid var(--theme-color-2);
      }

      .mat-card.no-border {
        border-bottom: none;
      }
    }

    .review-wrapper {
      padding-right: 30px;
      margin-top: 30px;

      .mat-tab-label {
        color: var(--theme-default-dark) !important;
        height: 48px;
        padding: 0 24px;
        cursor: pointer;
        box-sizing: border-box;
        /* opacity: 0.1; */
        min-width: 33%;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        position: relative;
        background: var(--theme-default-grey);
        margin: 0 2px;

        .mat-tab-label-content {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          color: var(--theme-default-dark) !important;
          font-family: "Gelasio", serif;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .full-desc {
        padding: 20px;

        p {
          line-height: 24px;
        }

        ul {
          margin-top: 15px;
          padding-left: 20px;

          li {
            margin-bottom: 7px;
          }
        }
      }

      .Reviews {
        .reviews {
          .name {
            font-weight: 500;
          }

          p {
            line-height: 22px;
            white-space: normal;
          }

          .text {
            margin-top: 10px;
          }

          .mat-list-item {
            .mat-list-item-content {
              align-items: start !important;
            }
          }
        }
      }

      .writte-reviews {
        .mat-form-field {
          width: 100%;
        }

        .rating {
          h3 {
            font-size: 22px;
            margin-top: 20px;
          }
        }

        .btn-project {
          background: var(--theme-orange);
          padding: 3px 32px;
          color: var(--theme-default);
          border: 2px solid var(--theme-orange);
          border-radius: 0;
          transition: 0.3s;

          &:hover {
            background: var(--theme-default);
            color: var(--theme-orange);
            border: 2px solid var(--theme-orange);
          }
        }
      }

      .products {
        padding-right: 30px;

        .title {
          margin-bottom: 15px;
          position: relative;
          padding-bottom: 1.25rem;

          &::before {
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
            height: 0.0625rem;
            bottom: 0.34375rem;
            background-color: var(--theme-default-grey);
          }

          &::after {
            content: "";
            left: 0;
            position: absolute;
            width: 2.5rem;
            height: 0.125rem;
            background-color: var(--theme-default-secondary);
            bottom: 0.3125rem;
          }
        }
      }
    }

    .sale-widget {
      padding: 15px 20px;
      background: var(--theme-default-dark);
      text-align: center;
      margin-bottom: 25px;
      margin-top: 25px;
      height: auto;

      .sale-widget1 {
        color: var(--theme-default);
        font-size: 36px;
        font-weight: 600;
        display: block;
        letter-spacing: 0.5;
      }

      .sale-widget2 {
        color: var(--theme-default);
        font-size: 18px;
        font-weight: 600;
        display: block;
        letter-spacing: 0.5;
      }

      .discount {
        display: inline-block;
        color: var(--theme-default);
        font-size: 77px;
        font-weight: 600;
        position: relative;
        letter-spacing: 0.5;
      }

      .percent {
        display: inline-block;
        line-height: 31px;
        color: var(--theme-default);

        span {
          display: block;
          font-size: 3.067em;
          font-weight: 500;
          color: var(--theme-default);
        }
      }

      p {
        color: var(--theme-default);
        line-height: 24px;
        font-weight: 300;
        font-size: 15px;
      }
    }

    .small-carousel {
      .swiper-slide {
        mat-card {
          img {
            max-width: 110px;
            margin-bottom: 12px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
          }
        }
      }
    }

    .small-carousel {
      position: relative;
      margin-top: 10px;

      .swiper-container {
        height: 180px;

        .swiper-slide {
          mat-card {
            max-height: 120px;
            min-height: 120px;
          }
        }

        .swiper-button-prev {
          position: absolute;
          top: 32%;
          left: 0px;
        }

        .swiper-button-next {
          position: absolute;
          top: 32%;
          right: 0px;
        }
      }
    }

    .product-item {
      position: relative;
      overflow: hidden;

      // transition: all .5s ease;
      mat-card {
        padding: 0;
        box-shadow: none;
        border: 1px solid var(--theme-default-grey);

        .product-img {
          text-align: center;
          cursor: pointer;

          img {
            max-width: 180px;
          }
        }

        .product-info-wrapper {
          background: var(--theme-default-grey);
          padding: 15px;

          .category {
            text-align: left;
            font-weight: 400;
            color: var(--theme-color-2);
          }

          .title-wrap {
            margin: 7px 0;

            h4 {
              font-size: 21px;
              margin: 0;
            }

            p {
              font-size: 21px;
              font-family: "Gelasio", serif;
              font-weight: 600;
              margin: 0;
            }
          }

          .stars {
            .mat-icon {
              font-size: 20px;
              height: 22px;
              width: 22px;
              color: var(--theme-orange);
            }
          }

          .btn-wrap {
            margin-top: 10px;

            .view-more {
              font-weight: 500;
              color: var(--theme-color-2);
              cursor: pointer;
            }

            .bucket {
              background: var(--theme-default-grey);
              width: 38px;
              height: 38px;
              border-radius: 50%;
              text-align: center;
              vertical-align: middle;
              line-height: 49px;
              cursor: pointer;

              a {
                mat-icon {
                  font-size: 21px;
                }
              }
            }
          }

          .details-products {
            .more-info {
              margin-top: 15px;
              overflow: hidden;
            }
          }

          .icons {
            position: absolute;
            top: 30px;
            right: -10px;
            opacity: 0;

            // transition: all .6s ease;
            mat-icon {
              color: var(--theme-default-dark);
              cursor: pointer;
            }
          }
        }

        &:hover {
          .icons {
            opacity: 1;
            transition: all 0.4s ease;
            transform: translateX(-20px);

            .ic-1 {
              animation-delay: 2s;
            }

            .ic-2 {
              animation: 0.5s ease-in-out fadeInRight;
            }

            .ic-3 {
              animation: 0.7s ease-in-out fadeInRight;
            }
          }
        }
      }
    }

    .title {
      margin-bottom: 22px;
    }
  }

  .no-bg {
    background: transparent !important;

    .title {
      h4 {
        font-size: 22px;
      }
    }
  }
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-list-base .mat-list-item.mat-3-line,
.mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/* Galery section*/

.popular-recipes-box {
  padding: 90px 0 0 0;

  background-size: cover;
  position: relative;

  .recipes-contain {
    .head-title {
      text-align: center;
      color: var(--theme-default);
      margin-bottom: 60px;

      h3 {
        text-align: center;
        margin-bottom: 15px;
        color: var(--theme-default);
      }

      p {
        line-height: 28px;
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }

    .gallery {
      .gallery-section {
        grid-column: full-start / full-end;
        display: grid;
        /*grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(7, 5vw);*/
        grid-template: repeat(5, 5vw) / repeat(8, 1fr);
        grid-gap: 1.5rem;
        padding: 1.5rem;

        img {
          max-width: 100%;
          height: auto;
          -webkit-transition: 0.5s;
          -o-transition: 0.5s;
          transition: 0.5s;
          will-change: transform;
        }

        .gallery__item {
          overflow: hidden;

          &:nth-child(1) {
            overflow: hidden;
            grid-row: 1 / span 2;
            grid-column: 1 / span 2;
          }

          &:nth-child(2) {
            grid-row: 1 / span 3;
            grid-column: 3 / span 3;
          }

          &:nth-child(3) {
            grid-row: 1 / span 2;
            grid-column: 8/8;
          }

          &:nth-child(4) {
            grid-row: 3 / span 3;
            grid-column: 1 / span 2;
          }

          &:nth-child(5) {
            grid-row: 4 / span 2;
            grid-column: 3 / span 2;
          }

          &:nth-child(6) {
            grid-row: 4/6;
            grid-column: 5/8;
          }

          &:nth-child(7) {
            grid-row: 1/4;
            grid-column: 6 / span 2;
          }

          &:nth-child(8) {
            grid-row: 3 / span 3;
            grid-column: 8 / -1;
          }

          &:hover {
            img {
              -webkit-transform: scale(1.02);
              -ms-transform: scale(1.02);
              transform: scale(1.02);
            }
          }
        }

        .gallery__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          border-radius: 0px;
        }
      }
    }
  }
}

/* About products section*/

.about-products {
  padding: 90px 0;
  background: var(--theme-default-grey);

  .about-products-wrap {
    .left {
      .text {
        text-align: center;
        padding: 0 50px;

        h3 {
          text-align: center;
          margin-bottom: 10px;
        }

        span {
          font-weight: 500;
          line-height: 26px;
          margin-top: 10px;
        }

        p {
          line-height: 26px;
        }

        .btn-project {
          margin-top: 20px;
        }
      }
    }

    .right {
      .img-wrap {
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

/***************************************
*  Widget three styles
****************************************/
.widgets-three {
  width: 30px;
  margin-left: 0;
  position: relative;
  padding-top: 10px;

  .bucket {
    .mat-button-wrapper {
      width: 30px;

      svg {
        max-height: 40px;
        fill: var(--theme-default);
      }

      .mat-badge {
        margin-left: -10px;
      }

      .cart-count-wrapper {
        position: absolute;
        text-align: center;
        top: -3px;
        left: 37px;
        background: var(--theme-default);
        height: 18px;
        width: 18px;
        line-height: 18px;
        border-radius: 50%;
        font-size: 11px;
        color: var(--theme-default);
        font-weight: 700;
      }
    }

    .top-cart {
      span {
        color: var(--theme-default);
      }
    }
  }
}

.spCard-main {
  width: 100%;

  .mat-menu-content {
    width: 239px !important;
  }

  .spCard-dropdown {
    background-color: var(--theme-default);
    padding: 15px 10px;
    max-width: 300px;
    z-index: 400;

    .card-list-title {
      padding: 0 0 10px 0;
    }

    h4 {
      padding-bottom: 10px;
    }

    .new-product {
      .product {
        img {
          width: 70px;
        }
      }

      .close-circle {
        flex: 1;

        a {
          cursor: pointer;
          color: var(--theme-default-grey);
          transition: 0.3s;
          float: right;

          i {
            font-size: 20px;
          }
        }
      }

      .item {
        padding: 10px 0;

        .product {
          margin-right: 7px;
          background: var(--theme-default);
          border: 1px solid var(--theme-default-grey);
        }

        .desc {
          p {
            font-weight: 500;
          }

          span {
            color: var(--theme-color-2);
            font-size: 15px;
            margin-top: 5px;
            /* padding-top: 7px; */
            display: block;
          }
        }
      }

      .total {
        padding-top: 15px;

        p {
          font-size: 15px;
        }

        span {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .card-list-title {
      a {
        color: var(--theme-default-dark);
        font-size: 14px;
        font-weight: 500;
      }

      p {
        color: var(--theme-default-dark);
        font-size: 14px;
        font-weight: 500;
      }
    }

    .woo-message {
      text-align: center;
      padding: 0;
      margin: 1.5em 0 0 0;
    }
  }
}

/***************************************
*  Carousel Food styles
****************************************/
.main-slider-food {
  height: 760px;

  .sl-wrap {
    position: relative;
    height: 100%;

    .content {
      height: 100%;
      position: relative;
      z-index: 9;
      /* padding-left: 294px; */
      vertical-align: middle;
      position: relative;
      text-align: center;

      /* top: 58%; */
      button {
        background: var(--theme-default) !important;
        border-radius: 0;
        padding: 5px 33px;
        width: 200px;
        margin: 0 auto;
        margin-top: 20px;

        span {
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      h1 {
        font-size: 48px;
        text-align: center;
        color: var(--theme-default);
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 0;
        // color: var(--theme-default);
      }

      p {
        font-size: 19px;
        text-align: center;
        color: var(--theme-default);
        margin-bottom: 30px;
        font-weight: 400;
        width: 756px;
        margin: 0 auto;
        line-height: 35px;
      }
    }
  }

  .slide-item {
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}

.swiper-pagination-bullet-active {
  background: var(--theme-color-2) !important;
}

.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - FURNITURE STYLES
****************************************
****************************************
****************************************/
/*  Topbar styles */
.main-topbar-furniture {
  background: var(--theme-default-grey) !important;

  .top-bar {
    .widget-text {
      a {
        color: var(--theme-default-dark);
        text-decoration: none;
        font-size: 13px;
        transition: 0.4s;

        .mat-icon {
          vertical-align: middle;
          margin-right: 1px !important;
          font-size: 23px;
        }

        &:hover {
          color: var(--theme-orange);
        }
      }

      &:not(:last-child) {
        margin: 0 20px 0 0;
      }

      .mat-button-wrapper {
        img {
          margin-right: 3px;
        }
      }
    }

    .welcome-message {
      p {
        color: var(--theme-color-2);
        font-size: 13.5px;
        font-weight: 400;
      }
    }

    span {
      .mat-icon {
        color: var(--theme-orange);
      }
    }

    .cur-icon {
      color: var(--theme-color-2) !important;
    }
  }

  .company-email {
    p {
      font-size: 14px;
      color: var(--theme-default-dark);
    }
  }
}

/***************************************
*  Header-2 styles
****************************************/

.home-header-seven {
  background: var(--theme-default);

  .logo {
    padding: 25px 0;
    margin-left: -15px;
  }

  .search-form {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 6px; */
    padding: 13px 0;

    .form-wrap {
      background-color: transparent;
      white-space: nowrap;
      zoom: 1;
      width: 100%;
      margin: 0;
      position: relative;
      background: var(--theme-default-dark);
      background: var(--theme-default-grey);

      input {
        border-style: none;
        border-width: 1px;
        border-radius: 70px;
        border: 1px solid var(--theme-default-grey);
        -webkit-transition: box-shadow 0.3s ease-out;
        transition: box-shadow 0.3s ease-out;
        height: 40px;
        width: 100%;
        max-width: 100%;
        padding: 0 0 0 12px;
        float: left;
        outline: none;
        box-shadow: none;
      }

      .btn-search {
        overflow: visible;
        position: absolute;
        border: 0;
        margin: 0;
        cursor: pointer;
        height: 100%;
        min-width: 50px;
        right: -15px;
        top: 0;
        padding: 0 35px;
        color: var(--theme-default);
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
        text-transform: uppercase;
        background-color: var(--theme-default) !important;
        border-radius: 0 3px 3px 0;
        -webkit-border-radius: 0 3px 3px 0;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none !important;
        -webkit-appearance: none;
        border-radius: 0 50px 50px 0;
      }
    }
  }

  .call-bucket-wrap {
    .info-message-icon {
      .icon {
        mat-icon {
          font-size: 35px;
          display: block;
          float: left;
          height: 49px;
          margin: 0 16px 0 0;
        }
      }

      .info-text {
        p {
          font-size: 12px;
          line-height: 0px;
          margin-bottom: 7px;
        }

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    .main-menu-wrapper-2 {
      .top-navbar {
        background: var(--theme-default);
        padding: 0;

        .mat-button-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          line-height: 22px;

          .top-cart {
            span {
              font-size: 15px;
            }
          }
        }

        .commerce-buttons {
          .mat-button-wrapper {
            .cart-count-wrapper {
              position: absolute;
              top: -10px;
              left: 30px;
              background: var(--theme-default) !important;
              color: var(--theme-default);
              height: 18px;
              width: 18px;
              line-height: 18px;
              border-radius: 50%;
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .widget-text {
    a {
      color: var(--theme-default-dark);
      text-decoration: none;
      font-size: 13px;
      transition: 0.4s;

      .mat-icon {
        vertical-align: middle;
        margin-right: 1px !important;
        font-size: 23px;
      }

      &:hover {
        color: var(--theme-default) !important;
      }
    }

    &:not(:last-child) {
      margin: 0 16px 0 0;
    }

    .mat-button-wrapper {
      img {
        margin-right: 3px;
      }
    }
  }

  .user {
    .mat-icon {
      font-size: 26px !important;
    }
  }
}

/***************************************
*  Slider and menu styles
****************************************/
.carousel-furniture {
  .ctn-box {
    box-sizing: content-box !important;

    .main-slider {
      height: 503px;

      .sl-wrap {
        position: relative;
        height: 100%;

        .content {
          padding-left: 20px;
          padding-right: 400px;

          height: 100%;
          position: relative;
          z-index: 9;
          /* padding-left: 294px; */
          vertical-align: middle;
          position: relative;
          /* top: 58%; */
          transform: none;
        }
      }
    }

    .slider-categories {
      padding-right: 15px;

      .categories-wrap {
        border: 1px solid var(--theme-default-grey);

        .title {
          background: var(--theme-default-grey);
          border-bottom: 1px solid var(--theme-default-grey);
          color: var(--theme-default-dark);
          padding: 15px 20px 15px 10px;
          font-weight: 500;
          font-size: 14.5px;
        }

        .category-contant {
          ul {
            li {
              display: block;

              a {
                padding-top: 12px;
                padding-bottom: 12px !important;
                border-top: 1px solid var(--theme-default-grey);
                margin: 0 10px -1px;
              }

              .no-brd {
                border-top: none;
              }
            }
          }
        }

        .btn-sale {
          margin: 15px 10px;

          button {
            padding: 5px 33px;
            width: 100%;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.main-menu {
  margin-left: -15px;

  a.mat-button {
    text-transform: uppercase;
    color: var(--theme-default);
    font-size: 1em;
    font-weight: 700;
    padding: 0 20px;
    line-height: 63px;
  }
}

/***************************************
*  Products section styles
****************************************/
.products-furniture.products {
  position: relative;

  h3 {
    margin-bottom: 0;
    display: inline-block;
    font-size: 23px;
    font-weight: 580;
    position: absolute;
  }

  .mat-tab-labels {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

/***************************************
*  Banner section styles
****************************************/
.commercial-banner {
  .image-column {
    background-repeat: no-repeat 90%;
    background-size: cover;

    align-items: center;
  }

  .inner-column {
    h1 {
      text-transform: capitalize;
      font-size: 52px;
      margin-bottom: 20px;
    }

    button {
      padding: 5px 33px;
      width: 200px;
      box-shadow: none !important;
    }
  }

  @media only screen and (max-width: 50em) {
    .image-column {
      grid-column: 1 / -1;
      padding: 6rem;
    }
  }

  .story__content {
    background-color: var(--theme-default-grey);
    grid-column: col-start 5 / full-end;
    padding: 6rem 8vw;
    /*
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    */
    display: grid;
    align-content: center;
    justify-items: start;
  }

  @media only screen and (max-width: 50em) {
    .story__content {
      grid-column: 1 / -1;
      grid-row: 5 / 6;
    }
  }

  .story__text {
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 4rem;
  }
}

/***************************************
    ***************************************
    ***************************************
*  HOME - DECOR STYLES
****************************************
****************************************
****************************************/

/***************************************
*  Header section styles
****************************************/

.header-decor {
  > div {
    width: 100%;
    background-color: var(--theme-color-2);

    .widget-text {
      a {
        .mat-icon {
          vertical-align: middle;
          margin-right: 1px !important;
          font-size: 23px;
          color: var(--theme-default);
        }
      }
    }

    .call-bucket-wrap {
      .main-menu-wrapper-2 {
        .top-navbar {
          background: transparent !important;

          .mat-icon {
            color: var(--theme-default);
          }

          .top-cart {
            span {
              color: var(--theme-default);
            }
          }
        }
      }
    }
  }
}

/***************************************
*  Carousel section styles
****************************************/
.carousel-home-decor {
  position: relative;

  .ctn-box {
    box-sizing: content-box !important;
    position: absolute;
    top: -64px;
    z-index: 9999;
  }

  .main-slider {
    height: 640px;

    .sl-wrap {
      position: relative;
      height: 100%;

      .content {
        padding-left: 21%;
        padding-right: 400px;

        height: 100%;
        position: relative;
        z-index: 9;
        /* padding-left: 294px; */
        vertical-align: middle;
        position: relative;
        /* top: 58%; */
        transform: none;
      }
    }
  }

  .slider-categories {
    background-color: var(--theme-default);

    .categories-wrap {
      border: 1px solid var(--theme-default-grey);

      .title {
        background: var(--theme-default-grey);
        border-bottom: 1px solid var(--theme-default-grey);
        color: var(--theme-default-dark);
        padding: 19px 20px 19px 10px;
        font-weight: 500;
        font-size: 14.5px;

        .mat-icon {
          margin-right: 4px;
        }
      }

      .category-contant {
        ul {
          li {
            display: block;

            a {
              padding-top: 12px;
              padding-bottom: 12px !important;
              border-top: 1px solid var(--theme-default-grey);
              margin: 0 10px -1px;
            }

            .no-brd {
              border-top: none;
            }
          }
        }
      }

      .btn-sale {
        margin: 15px 10px;

        button {
          padding: 5px 33px;
          width: 100%;
          box-shadow: none;
        }
      }
    }
  }

  .carousel-2-wrapper {
    max-width: 100%;
    position: relative;

    .main-slider {
      height: 640px;

      .content {
        padding-left: 23%;
        padding-right: 485px;
      }
    }
  }

  .decor-menu {
    .main-menu {
      margin-left: 293px;
    }
  }
}

/***************************************
*  Home baners section styles
****************************************/
.banners-decor {
  margin-top: -70px;
  z-index: 9999999999999;
  position: relative;
  background: var(--theme-default);
  border: 1px solid var(--theme-default-grey);

  .baners {
    background: var(--theme-default-grey) !important;
  }

  .baner {
    padding: 10px 0 0 10px;
    height: 100%;
    transition: 0.15s ease-in;
    text-align: left;

    h4 {
      font-size: 19px;
      font-weight: 500;
      color: var(--theme-default-dark);
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    .info {
      .big-price {
        padding: 10px 25px !important;
        box-shadow: none !important;
        width: 135px;
        border-radius: 5px;
        text-align: center;

        span {
          font-size: 27px !important;
        }
      }
    }

    p {
      color: var(--theme-default-dark);
    }

    a {
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
      color: var(--theme-default-dark);
      margin-top: 15px;
      display: block;
    }

    ul {
      li {
        a {
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
          color: var(--theme-color-2);
          margin-top: 5px;
          display: block;
        }
      }
    }
  }

  .first {
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;

    .info {
      .text-box {
        text-align: center;
        padding: 15px 20px;

        span {
          color: var(--theme-default);
          margin-bottom: 10px;
          line-height: 31px;
        }

        h4 {
          color: var(--theme-default);
          line-height: 27px;
        }

        a {
          color: var(--theme-default);
          font-weight: 500;
        }
      }
    }
  }

  .seccond {
    /* height: 200px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px;
  }

  .media {
    border-right: 1px solid var(--theme-default-grey);
    border-bottom: 1px solid var(--theme-default-grey);
  }

  .electrics {
    border-left: 1px solid var(--theme-default-grey);
  }

  .decors {
    border-bottom: 1px solid var(--theme-default-grey);
  }
}

/***************************************
*  Commercial banners section styles
****************************************/
.commercial-banners {
  .image-column {
    background: var(--theme-default-grey);
    padding: 0px 30px;

    h3 {
      margin-bottom: 20px;
    }

    span {
      color: var(--theme-main-red);
      font-weight: 500;
    }

    a {
      color: var(--theme-default-dark);
      font-weight: 500;
      text-decoration: underline;
    }

    .img {
      img {
        max-width: 85%;
      }
    }
  }
}

/***************************************
*  Home footer-2 section styles
****************************************/
.footer-two {
  background-color: var(--theme-default-dark);

  .footer-widget {
    padding-right: 15px;

    .social {
      margin-top: 20px;

      li {
        width: 33px;
        height: 33px;
        border: 1px solid var(--theme-default-grey);
        border-radius: 50%;
        text-align: center;
        margin-right: 5px;

        a {
          color: var(--theme-color-2);

          i {
            color: var(--theme-orange);
          }
        }

        .mat-icon-button {
          padding: 0;
          min-width: 0;
          width: auto;
          height: auto;
          flex-shrink: 0;
          line-height: 32px;
          border-radius: 50%;

          i {
            color: var(--theme-default-grey);
          }
        }
      }
    }

    .ped-btn-footer {
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      z-index: 222;
      background: var(--theme-default) !important;
      box-shadow: none !important;
      width: 100%;
      border-radius: 3px;
      line-height: 45px;
    }

    .title {
      margin-bottom: 10px;

      h5 {
        font-size: 18px;
        font-weight: 600;
        color: var(--theme-default);
      }
    }

    a {
      text-decoration: none;
      font-size: 15px;
      line-height: 32px;
      color: var(--theme-color-2);
    }

    .newsletter-widget {
      position: relative;
      margin: 15px 0 20px 0;
    }

    p {
      line-height: 24px;
      color: var(--theme-color-2);
    }

    ul {
      list-style-type: none;
    }

    .about-widget {
      .logo {
        img {
          max-width: 240px;
        }
      }
    }

    .tel-box {
      .icon {
        i {
          display: block;
          line-height: 27px;
          vertical-align: middle;
          margin-right: 5px;
          color: var(--theme-default) !important;
          width: 18px;
          text-align: center;
          font-size: 18px;
        }
      }

      .text {
        p {
          a {
            line-height: 22px;
          }
        }
      }
    }

    .mat-icon-button {
      padding: 0;
      min-width: 0;
      width: auto;
      height: auto;
      flex-shrink: 0;
      line-height: 32px;
      border-radius: 50%;
    }
  }

  .subscribe-widget {
    input {
      height: 45px;
      background: var(--theme-default);
      font-size: 15px;
      line-height: 45px;
      border: 1px solid var(--theme-default-grey);
      border-radius: 4px;
      padding: 0 65px 0 20px;
      color: var(--theme-color-2);
    }
  }
}

/***************************************
*  Popular product page styles
****************************************/

.popular-products {
  .reviews {
    .content {
      height: auto;
      margin-bottom: 10px;
      margin-left: -15px !important;

      .media {
        margin-right: 7px;
        background: var(--theme-default-grey);
        border-radius: 3px;

        img {
          max-width: 80px;
          margin-right: 8px;
        }
      }

      .info {
        h5 {
          color: var(--theme-color-2);
          text-decoration: none;
          font-size: 17px;
          font-weight: 600;
          -webkit-transition: color 0.4s ease;
          transition: color 0.4s ease;
        }

        .price {
          font-weight: 400;
        }
      }
    }
  }

  .title-header {
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 1.25rem;

    h4 {
      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: var(--theme-default-grey);
      }

      &::after {
        content: "";
        left: 0;
        position: absolute;
        width: 2.5rem;
        height: 0.125rem;
        background-color: var(--theme-default-dark);
        bottom: 0.3125rem;
      }
    }
  }
}

/***************************************
*  Login page styles
****************************************/
.login {
  .login-wrap {
    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;

      > h2 {
      }

      &:before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: var(--theme-default-grey);
      }

      &:after {
        content: "";
        left: 0;
        position: absolute;
        width: 2.5rem;
        height: 0.125rem;
        background-color: var(--theme-default-dark);
        bottom: 0.3125rem;
      }
    }

    form {
      .mat-form-field {
        width: 100%;
        margin-bottom: 10px;
        font-family: "Red Hat Text", sans-serif !important;
      }
    }

    .register-wrapper {
      .register-body {
        .mat-form-field {
          width: 100%;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 26px;
        }

        .button-wrap {
          margin-top: 20px;
        }
      }
    }
  }
}

/***************************************
*  Price slider styles
****************************************/

.price-slider {
  .filter-price {
    .mat-slider-horizontal {
      min-width: 110px;
    }
  }
}

/***************************************
*  Product dialog styles
****************************************/

.product-dialog {
  .mat-dialog-container {
    overflow: visible;
  }

  .swiper-container {
    .swiper-wrapper {
      img.img-fluid {
        margin: auto;
        max-width: 100%;
      }
    }
  }

  .product-right {
    padding: 0 30px;

    .product-details {
      .discription {
        padding-top: 15px;
        padding-bottom: 20px;
        border-top: 1px dashed var(--theme-color-2);
        border-bottom: 1px dashed var(--theme-color-2);

        .bold {
          font-weight: 600;
        }

        p.productDescription {
          line-height: 24px;
          margin-top: 5px;
        }
      }

      h4 {
        span {
          padding-left: 5px;
          color: var(--theme-main-red);
          font-size: 25px;
        }

        del {
          color: var(--theme-color-2);
          font-size: 18px;
        }
      }

      h3.price {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0;
      }
    }

    .quantity {
      input {
        width: 80px;
        text-align: center;
        background-color: var(--theme-default-grey);
        height: 35px;
        border: 1px solid var(--theme-default-grey);
      }

      span {
        margin-bottom: 5px;
      }
    }

    .avalibility {
      .red {
        color: var(--theme-main-red);
        font-weight: 500;
      }
    }

    .buttons {
      .mat-icon {
        color: var(--theme-color-2);
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }

  .mat-dialog-container {
    .close-btn-outer button {
      position: absolute;
      right: -44px;
      top: -44px;
    }
  }

  .product-dialog {
    .close-btn-outer {
      position: relative;
    }
  }
}

.product-pagination .ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
  padding: 10px 5px;
}

.product-pagination .ngx-pagination .current {
  padding: 4px 12px;
  background: var(--theme-default-grey);
  color: var(--theme-default-dark);
  cursor: default;
  border-radius: 2px;
  line-height: 22px;
}

.product-dialog .mat-dialog-content {
  max-width: 52vw;
}

/***************************************
*  Popular products styles
****************************************/
.popular-products {
  .reviews {
    .stars {
      color: var(--theme-orange);
      margin: 0px 0 5px 0;

      span {
        color: var(--theme-default-grey);
        font-size: 13px;
        margin-left: 10px;
      }

      mat-icon {
        font-size: 17px;
        line-height: 17px;
        height: 17px;
        width: 17px;
        vertical-align: middle;
      }
    }
  }
}

.mega-menu-pane {
  .mat-menu-panel {
    width: auto !important;

    .mat-menu-content {
      width: 100% !important;
    }
  }
}

/***************************************
*  Error page styles
****************************************/
.error {
  text-align: center;

  .page404 {
    font-size: 67px;
    font-weight: 700;
    color: var(--theme-color-2);
    margin-bottom: 30px;
  }

  .not-found {
    font-size: 39px;
    font-weight: 700;
    color: var(--theme-default-dark);
    margin-bottom: 30px;
  }

  .error_description {
    margin-bottom: 30px;

    p {
      font-weight: 600;
    }
  }

  button {
    background: var(--theme-orange);
    color: var(--theme-default-dark);
    padding: 5px 33px;
    width: 200px;
  }
}

/***************************************
*  FAQ page styles
****************************************/

.faq {
  .questions {
    padding-right: 15px;

    .faq-wrap {
      .mat-expansion-panel {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border: 1px solid var(--theme-color-2);
        box-shadow: none;
        margin-bottom: -1px;
        box-shadow: none !important;

        .mat-expansion-panel-header-title {
          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
          }
        }

        .mat-expansion-panel-header:hover {
          span {
            color: var(--theme-main-blue);
          }
        }

        .mat-expansion-panel-body {
          p {
            line-height: 24px;
          }
        }
      }
    }
  }

  .faq-form {
    padding-left: 15px;

    .text-wrap {
      p {
        line-height: 26px;
      }
    }

    form {
      margin: 30px 0;
    }
  }
}

/***************************************
*  About us page styles
****************************************/

.about-us-wrapper {
  .mat-expansion-panel-header:hover {
    span {
      color: var(--theme-main-blue);
    }
  }

  .about-section {
    .left-info-panel {
      overflow: hidden;

      .about-us-intro {
        h2 {
          margin-bottom: 20px;
        }

        p {
          line-height: 26px;
        }

        .accordions {
          margin-top: 20px;

          .mat-expansion-panel {
            margin-bottom: 10px;
            box-shadow: none;
            border: 1px solid var(--theme-default-grey);
            border-radius: 5px;

            h4 {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .our-values {
    background: var(--theme-default-grey);

    .box-wraper {
      .box {
        padding-left: 15px;
        padding-right: 15px;

        .content {
          padding: 41px 20px 31px;
          background: var(--theme-default);

          .icon-box {
            i {
              font-size: 55px;
              color: var(--theme-default);
            }
          }

          h4 {
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 5px;
            margin-bottom: 10px;
          }

          p {
            line-height: 26px;
          }
        }
      }
    }
  }

  .title-wrapper {
    text-align: center;
    margin-bottom: 35px;

    h2 {
      margin-bottom: 10px;
    }

    p {
      color: var(--theme-color-2);
    }
  }

  .our-team {
    .team-members {
      .item {
        padding-left: 15px;
        padding-right: 15px;

        .single-team-member {
          transition: 0.3s;

          .img-box {
            position: relative;

            img {
              width: 100%;
              -webkit-transition: opacity 0.4s ease;
              transition: opacity 0.4s ease;
            }

            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;

              .box {
                display: table;
                width: 100%;
                height: 100%;

                .content {
                  display: table-cell;
                  vertical-align: bottom;
                  overflow: hidden;

                  ul {
                    background: var(--theme-default);
                    margin: 0;
                    margin-left: -12px;
                    margin-right: -12px;
                    -webkit-transform: translate3d(0, 35px, 0);
                    transform: translate3d(0, 35px, 0);
                    -webkit-transition:
                      -webkit-transform 0.4s ease,
                      visibility 0.4s ease,
                      opacity 0.4s ease;
                    transition:
                      transform 0.4s ease,
                      visibility 0.4s ease,
                      opacity 0.4s ease;
                    visibility: hidden;

                    li {
                      padding: 0 12px;

                      i {
                        color: var(--theme-default);
                        font-size: 16px;
                        line-height: 35px;
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }

          .text-box {
            border: 1px solid var(--theme-default-grey);
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
          }

          &:hover {
            .img-box {
              img {
                opacity: 0.4;
              }

              .overlay {
                .box {
                  .content {
                    .social {
                      opacity: 1;
                      visibility: visible;
                      -webkit-transform: translate3d(0, 0px, 0);
                      transform: translate3d(0, 0px, 0);
                    }
                  }
                }
              }
            }

            .text-box {
              background: var(--theme-default-dark);

              h4 {
                color: var(--theme-default);
              }

              p {
                color: var(--theme-default);
              }
            }
          }
        }
      }
    }
  }
}

/***************************************
*  Order success page styles
****************************************/
.succesfull-message {
  text-align: center;
  padding: 50px 0;

  .material-icons {
    color: var(--theme-green);
    font-size: 63px;
  }

  h2 {
    margin: 10px 0;
  }

  p {
    line-height: 30px;
  }
}

.order-success {
  .billing-details {
    h3 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .product-order-details {
      .order-img {
        img {
          max-width: 130px;
        }
      }

      p {
        font-size: 17px;
        font-weight: 500;
      }

      span {
        font-size: 17px;
        margin-top: 5px;
      }
    }

    ul.price-list {
      li {
        padding: 10px 0;
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        p {
          line-height: 30px;
          font-weight: 500;
        }

        span {
          position: relative;
          width: 35%;
          float: right;
          font-size: 18px;
          text-align: right;
          color: var(--theme-color-2);
        }

        img {
          max-width: 30px;
          height: 23px;
          margin: 0 10px;
        }
      }
    }
  }

  .mainOrder-wrapper {
    .main-order {
      background: var(--theme-default-grey);
      padding: 30px;
      padding: 30px;

      .order-box {
        .title-box {
          position: relative;
          padding-bottom: 25px;
          color: var(--theme-default-dark);
          font-weight: 500;
          font-size: 20px;
          border-bottom: 1px solid var(--theme-default-grey);
          margin-bottom: 20px;

          span.tl-amount {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
          }
        }

        ul.price-list {
          position: relative;
          border-bottom: 1px solid var(--theme-default-grey);
          margin-bottom: 20px;
          padding-bottom: 20px;

          li {
            padding: 10px 0;
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            p {
              line-height: 30px;
              font-weight: 500;
            }

            span {
              position: relative;
              width: 35%;
              float: right;
              font-size: 18px;
              text-align: right;
              color: var(--theme-color-2);
            }
          }
        }
      }

      ul.total-amount {
        position: relative;
        border-bottom: 1px solid var(--theme-default-grey);
        margin-bottom: 30px;
        padding-bottom: 20px;

        .shipping {
          width: 35%;
          float: right;

          .shopping-option {
            line-height: 30px;
          }
        }

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: var(--theme-default-dark);
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;

          span.amount {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: var(--theme-main-red);
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      ul.quantity {
        position: relative;
        border-bottom: 1px solid var(--theme-default-grey);
        margin-bottom: 30px;
        padding-bottom: 20px;

        li {
          span.total {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: var(--theme-main-red);
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      ul.total {
        position: relative;

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: var(--theme-default-dark);
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;

          span.count {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: var(--theme-main-red);
            font-weight: 400;
            width: 35%;
            float: right;
            text-align: right;
          }
        }
      }

      .paymentFinal {
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}

/***************************************
*  Breadcrumb  styles
****************************************/
.breadcrumb-section {
  padding: 20px 0;
  border-bottom: 1px solid var(--theme-default-grey);

  .breadcrumb-title {
    h4 {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .breadcrumb-path {
    .breadcrumb {
      background-color: transparent;
      justify-content: flex-end;
      text-transform: uppercase;
      font-size: 14px;
      color: var(--theme-color-2);
      font-weight: 500;
      margin: 0;
      padding-right: 0;

      .breadcrumb-item {
        padding: 0 0.5rem;

        &:active {
          color: var(--theme-color-2);
        }
      }

      a {
        color: var(--theme-color-2);
      }
    }
  }
}

/***************************************
*  Checkout page styles
****************************************/

.checkout {
  .billing-details {
    .mat-form-field {
      width: 100%;
    }

    .add-info {
      margin-top: 15px;
    }
  }

  .mainOrder-wrapper {
    .main-order {
      border: 1px solid var(--theme-default-grey);
      padding: 30px;

      .order-box {
        .title-box {
          position: relative;
          padding-bottom: 25px;
          color: var(--theme-default-dark);
          font-weight: 500;
          font-size: 20px;
          border-bottom: 1px solid var(--theme-default-grey);
          margin-bottom: 20px;

          span.tl-amount {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
          }
        }

        ul.price-list {
          position: relative;
          border-bottom: 1px solid var(--theme-default-grey);
          margin-bottom: 30px;
          padding-bottom: 20px;

          li {
            line-height: 44px;

            span {
              position: relative;
              width: 35%;
              float: right;
              font-size: 20px;
            }
          }
        }
      }

      ul.total-amount {
        position: relative;
        border-bottom: 1px solid var(--theme-default-grey);
        margin-bottom: 30px;
        padding-bottom: 20px;

        .shipping {
          width: 35%;
          float: right;

          .shopping-option {
            line-height: 30px;
          }
        }

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: var(--theme-default-dark);
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;

          span.amount {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: var(--theme-main-red);
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      ul.quantity {
        position: relative;
        border-bottom: 1px solid var(--theme-default-grey);
        margin-bottom: 30px;
        padding-bottom: 20px;

        li {
          span.total {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: var(--theme-main-red);
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      ul.total {
        position: relative;
        border-bottom: 1px solid var(--theme-default-grey);
        margin-bottom: 30px;
        padding-bottom: 20px;

        li {
          position: relative;
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          color: var(--theme-default-dark);
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;

          span.count {
            position: relative;
            font-size: 20px;
            line-height: 20px;
            color: var(--theme-main-red);
            font-weight: 400;
            width: 35%;
            float: right;
          }
        }
      }

      .paymentFinal {
        margin-bottom: 20px;
        line-height: 34px;
      }
    }
  }
}

.mat-radio-label-content {
  color: var(--theme-color-2);
  font-weight: 400;
}

/***************************************
*  Cart page styles
****************************************/
.cart-wrapper {
  .mat-card {
    text-align: center;

    .cart-table.mat-table {
      display: block;
      overflow-x: auto;

      .mat-row {
        min-height: 100px;
        min-height: 100px;
        border-bottom: 1px solid var(--theme-default-grey);

        .product-name {
          color: inherit;
          text-decoration: none;
          font-weight: 500;
        }

        .grand-total {
          font-weight: 500;
        }

        span:nth-child(3) {
          font-size: 16px;
        }

        input {
          width: 80px;
          text-align: center;
          background-color: var(--theme-default-grey);
          height: 35px;
          border: 1px solid var(--theme-default-grey);
        }

        .price {
          font-size: 21px;
          font-weight: 500;
        }

        .total {
          font-size: 21px;
          font-weight: 500;
          color: var(--theme-main-red);
        }
      }

      .mat-cell,
      .mat-header-cell {
        flex: 1;
        overflow: hidden;
        word-wrap: break-word;
        font-size: 14px;

        img {
          width: 100px;
          cursor: pointer;
        }

        mat-icon {
          cursor: pointer;
        }
      }
    }
  }
}

.mat-row,
.mat-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
  min-width: 870px;
}

/***************************************
*  Compare page styles
****************************************/
.compare-component {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    table {
      width: 100%;
      border: 1px solid var(--theme-default-grey);

      thead {
        .th-compare {
          td {
            padding: 15px;
            vertical-align: top;
            border-bottom: 1px solid var(--theme-default-grey);
            border-top: 1px solid var(--theme-default-grey);
            border-right: 1px solid var(--theme-default-grey);
            background: var(--theme-default-grey);
            font-weight: 600;
            font-size: 14px;
          }

          a {
            cursor: pointer;
          }
        }
      }

      tbody {
        tr {
          th {
            padding: 15px;
            vertical-align: top;
            border-bottom: 1px solid var(--theme-default-grey);
            border-top: 1px solid var(--theme-default-grey);
            border-right: 1px solid var(--theme-default-grey);
            background: var(--theme-default-grey);
            text-align: left;
          }

          .product-name {
            width: 15%;
            font-size: 14px;
          }

          td {
            .description-compare {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  img.img-fluid.mb-4 {
    max-width: 100px;
  }

  .empty-cart-cls {
    h4 {
      font-weight: 500;
      color: var(--theme-color-2);
    }

    h3 {
      text-align: center;
    }
  }
}

.compare-component .table-responsive .table tbody tr td,
.compare-component .table-responsive .table tbody tr th {
  padding: 15px;
  vertical-align: top;
  border-top: 1px solid var(--theme-default-grey);
}

/***************************************
*  Details page styles
****************************************/
.details-wrapper {
  .product-image {
    img {
      max-width: 100%;
    }
  }

  .product-right {
    padding: 0px;

    .product-details {
      padding-bottom: 15px;
      border-bottom: 1px dashed var(--theme-color-2);

      h2 {
        margin-bottom: 10px;
      }

      h4 {
        del {
          color: var(--theme-color-2);
          font-size: 17px;
        }

        span {
          padding-left: 5px;
          color: var(--theme-main-red);
          font-size: 24px;
        }
      }

      .avalibility {
        span {
          color: var(--theme-main-red);
          font-weight: 500;
        }
      }

      p.description {
        line-height: 26px;
      }

      h3.price {
        font-size: 26px;
        font-weight: 400;
        margin: 10px 0;
      }
    }

    .quantity {
      margin-top: 10px;

      span {
        margin-bottom: 7px;
        display: block;
      }

      input {
        width: 80px;
        text-align: center;
        background-color: var(--theme-default);
        height: 35px;
        border: 1px solid var(--theme-default-secondary);
      }
    }

    .red {
      color: var(--theme-main-red);
      font-weight: 700;
    }
  }

  .info-bar {
    border: 1px solid var(--theme-color-2);
    padding: 0 20px;
    margin-top: 0px !important;

    .mat-icon {
      color: var(--theme-default-dark);
      font-size: 41px;
      margin-right: 15px;
      line-height: 23px;
    }

    .content {
      p {
        text-transform: capitalize;
        font-weight: 500;
      }
    }

    .mat-card {
      box-shadow: none !important;
      padding: 20px 0;
      border-bottom: 1px solid var(--theme-color-2);
    }

    .mat-card.no-border {
      border-bottom: none;
    }
  }

  .review-wrapper {
    .full-desc {
      padding: 20px;

      p {
        line-height: 26px;
      }

      ul {
        margin-top: 15px;
        padding-left: 20px;

        li {
          margin-bottom: 7px;
        }
      }
    }

    .Reviews {
      .reviews {
        .name {
          font-weight: 500;
        }

        p {
          line-height: 22px;
          white-space: normal;
        }

        .text {
          margin-top: 10px;
        }

        .mat-list-item {
          .mat-list-item-content {
            align-items: start !important;
          }
        }
      }

      .writte-reviews {
        .mat-form-field {
          width: 100%;
        }
      }
    }
  }

  .products {
    padding-right: 30px;

    .title {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 1.25rem;

      &::before {
        content: "";
        left: 0;
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        bottom: 0.34375rem;
        background-color: var(--theme-default-grey);
      }

      &::after {
        content: "";
        left: 0;
        position: absolute;
        width: 2.5rem;
        height: 0.125rem;
        background-color: var(--theme-default-secondary);
        bottom: 0.3125rem;
      }
    }
  }

  .sale-widget {
    height: 14%;
    margin-top: 25px;
    padding: 15px 20px;
    text-align: center;
    background-color: var(--theme-default);

    .sale-widget1 {
      color: var(--theme-default);
      font-size: 36px;
      font-weight: 600;
      display: block;
      letter-spacing: 0.5;
    }

    .sale-widget2 {
      color: var(--theme-default);
      font-size: 18px;
      font-weight: 600;
      display: block;
      letter-spacing: 0.5;
    }

    .discount {
      display: inline-block;
      color: var(--theme-default-dark);
      font-size: 77px;
      font-weight: 600;
      position: relative;
      letter-spacing: 0.5;
    }

    .percent {
      display: inline-block;
      line-height: 31px;

      span {
        display: block;
        font-size: 3.111em;
        font-weight: 500;
      }
    }

    p {
      color: var(--theme-default);
      line-height: 24px;
      font-weight: 300;
      font-size: 15px;
    }
  }

  .small-carousel {
    position: relative;
    margin-top: 10px;

    .swiper-container {
      height: 180px;

      @media only screen and (max-width: 600px) {
        height: 190px;
      }

      .swiper-slide {
        mat-card {
          padding: 0px;
          max-width: 100%;
          max-height: 100%;

          img {
            max-width: 100%;
            max-height: 100%;
            margin-bottom: 12px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
          }
        }
      }

      .swiper-button-prev {
        position: absolute;
        top: 32%;
        left: 0px;
      }

      .swiper-button-next {
        position: absolute;
        top: 32%;
        right: 0px;
      }
    }
  }

  .sale-baner {
    background: transparent;
  }

  .mat-expansion-panel {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border: 1px solid var(--theme-color-2);
    box-shadow: none !important;
    margin-bottom: 20px;
    background: transparent !important;
  }
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-list-base .mat-list-item.mat-3-line,
.mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/***************************************
*  Brands page styles
****************************************/

.brands-items {
  .mat-radio-button {
    margin-bottom: 15px;
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background: var(--theme-default-blackish) !important;
  }
}

/***************************************
*  Categorie page styles
****************************************/

.category-contant {
  ul {
    li {
      a {
        color: var(--theme-color-2);
        padding-bottom: 10px;
        display: block;
        font-size: 15px;
      }
    }
  }
}

/***************************************
*  Contact us page styles
****************************************/

.contact-page {
  iframe {
    width: 100%;
    height: 400px;
    border: none;
  }

  .left-info-panel {
    // padding-right: 100px;
    ul {
      list-style-type: none;
      padding: 0;

      > *:not(:last-child) {
        border-bottom: 1px solid var(--theme-default-grey);
        padding-bottom: 20px;
      }

      li {
        i {
          float: left;
          font-size: 33px;
          line-height: 74px;
          margin-right: 7px;
          color: var(--theme-default);
        }

        h4 {
          display: inline-block;
          font-size: 20px;
        }
      }
    }
  }

  .contact-form {
    .title {
      margin-bottom: 20px;
    }

    .w-100 {
      width: 100% !important;
    }

    .mat-form-field {
      caret-color: var(--theme-default) !important;
      font-size: 14px !important;

      .mat-input-element {
        caret-color: var(--theme-default) !important;
      }
    }

    .px-1 {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 599px) {
  .contact-page {
    .left-info-panel {
      padding-right: 0px;
      width: 100%;
    }
  }
}

.sidenav {
  .divider {
    margin: 10px 0;
  }
}

:host {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;

  .mat-list-item.active {
    background-color: mat-color(var(--theme-orange), 50);
  }

  &:hover,
  &:focus {
    > .mat-list-item:not(.expanded) {
      background-color: mat-color(var(--theme-orange), 100) !important;
    }
  }
}

.mat-list-item {
  padding: 10px 0;
  display: flex;
  width: auto;

  .mat-list-item-content {
    width: 100%;
  }

  .routeIcon {
    margin-right: 40px;
  }
}

.btn-default,
.mat-button {
  color: var(--theme-default-dark);
}

.header.mat-toolbar {
  box-shadow: 0 0 5px grey;
  z-index: 13;
  font-size: 20px;
  max-height: 64px;
  height: 64px;
  padding: 0px 10px;
}

@media (min-width: 580px) {
  .header.mat-toolbar.header-long {
    height: 120px !important;
    max-height: 120px !important;
  }
}

.sub-container .mat-card {
  box-shadow: none !important;
}

.search_box-n {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search_box-n .search_input {
  width: 85% !important;
}

.search_box-n .search_input input {
  height: 40px !important;
}

table.mat-elevation-z8 thead tr.b-b.ng-star-inserted {
  display: table-row !important;
}

table.mat-elevation-z8 tbody tr.b-b.ng-star-inserted {
  display: table-row !important;
}

.mat-dialog-content {
  width: 100%;
}

.example-form {
  max-width: 100% !important;
}

.mat-dialog-container {
  position: relative !important;
}

.mat-dialog-container .close-button {
  top: 0px !important;
  right: 0px !important;
  position: absolute !important;
}

.edb-space {
  margin-top: 10px;
}

.mat-icon.mat-warn {
  color: var(--theme-default-secondary) !important;
}

.vl {
  margin: unset;
  border-left: 1px solid var(--theme-default-secondary);
  height: 30px;
  margin-top: 15px;
}

.c-info {
  text-align: center;
}

.c-info h3 {
  text-align: center;
}

.c-info a {
  background: none !important;
}

.c-info a:hover {
  background: none !important;
}

.c-info .mat-toolbar {
  background: none !important;
}

.c-info .mat-toolbar a i {
  width: 40px;
  height: 40px;
  background: var(--theme-default-secondary);
  border-radius: 500px;
  font-size: 20px !important;
  line-height: 40px !important;
  color: var(--theme-default) !important;
}

.home-header .top-navbar .mat-button-wrapper {
  text-transform: uppercase;
}

.invoiceDialog {
  .mat-dialog-container {
    margin: 20px 0px;
    padding: 0px;
  }
}

.ds-summary-cart {
  ::ng-deep .mat-drawer {
    width: 420px;
    padding: 0px;
  }

  ::ng-deep .mat-icon-button {
    padding: 8px;
  }
}

.mat-badge-content {
  background-color: var(--theme-main-blue);
}

.cdk-global-overlay-wrapper {
  pointer-events: auto;
  overflow: auto;
}
.height-fit-content {
  height: fit-content;
}
#product-no-sidebar-component {
  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    border-radius: 0 !important;
  }
}
.bundles-section {
  .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px !important;
    width: 12px;
    height: 12px;
  }
}
.new-5star-header .commerce-buttons {
  margin-left: -12px !important;
  margin-top: 10px !important;
}

.color-blackish {
  color: var(--theme-default-blackish) !important;
}

.color-white {
  color: var(--theme-white) !important;
}

.mat-checkbox {
    font-family: 'Red Hat Text';
}
.no-checkbox-container {
  .checkBox {
    border: 1px solid #4a4a4afa;
    border-radius: 6px;
    padding: 6px 20px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Red Hat Text';
    // background-color: #fff !important;
    // opacity: 0.35;
    background-color: #E6E7E8 !important;
    opacity: 0.6;
  }
  .checkbox-selected {
    opacity: 1;
    background-color: #ffffff !important;
    .mat-checkbox-label {
      // color: #fff !important;
      font-weight: 500;
    }
  }
  .mat-checkbox-inner-container {
    display: none;
  }
} 
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.fade-80 {
  opacity: 0.8;
}

.loyalty-program-dialog {
  width: 100%;
  max-width: 799px !important;
  border-radius: 0 !important;
  .mat-dialog-container {
    border-radius: 0 !important;
  }
}

@media (max-width: 599px) {
  .loyalty-program-dialog {
    margin: 30px;
  }
  .cart-count-wrapper {
    left: 55px !important;
  }
}

.bg-white {
  background-color: #ffffff !important;
}

.mat-tab-label, .mat-tab-link {
  font-family: "Red Hat Text" !important
}