/**************************************************************************
***************** Master Stylesheet for ecommerce************************
***************************************************************************
=========================== Content Index =================================
* 1. General styles
* 2. HOME-1 STYLES
     - Info bar styles
     - Header-1 styles
     - Shopping widget styles
     - Home product section styles
     - Home baners section styles
     - Home vertical-product section styles
     - Home footer section styles
* 3. HOME-2 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-3 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-4 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 3. Menu-1 styles
* 4. Menu-2 styles
* 5. Products left sidebar page styles
* 6. Popular product page styles
* 7. My account page styles
* 11. Price slider styles
* 12. Product dialog styles
* 13. Popular products styles

***************************************************************************
**************************************************************************/
/***************************************/
/* Icon Pulse Shrink */
:root {
  --theme-default-secondary: #004876 !important;
  --theme-default-blackish: #373A36 !important;
  --theme-default-grey: #eeedee !important;
  --theme-color-2: #9c9c9c !important;
  --theme-default-dark: #373A36 !important;
  --theme-main-red: #f33927 !important;
  --theme-main-yellow: #ffeb3b !important;
  --theme-main-blue: #cc9e67 !important;
  --theme-green: #4caf50 !important;
  --theme-orange: #ff9800 !important;
  --theme-default: #ffffff !important;
  --theme-color-1: #eeeeee !important;
  --theme-color-2: #9c9c9c !important;
  --theme-color-3: #383838 !important;
  --theme-white: #fff !important;
  --theme-brand-color-1: #232b63 !important;
  --theme-brand-color-2: #eeeeee !important;
}

@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px var(--theme-default-secondary);
}

.hvr-icon-pulse-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon-pulse-shrink:hover .hvr-icon,
.hvr-icon-pulse-shrink:focus .hvr-icon,
.hvr-icon-pulse-shrink:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-shrink;
  animation-name: hvr-icon-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
.color-options {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 350px;
  right: -360px;
  background-color: var(--theme-default);
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 var(--theme-default-grey);
  transition: all 0.5s ease;
}
.color-options .options-icon {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--theme-default-grey);
  width: 40px;
  height: 40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 5px 0px var(--theme-color-2);
  transition: all 0.5s ease;
  z-index: 2;
}
.color-options .options-icon .mat-icon {
  -webkit-animation: rotation 2s infinite linear;
  color: var(--theme-default-dark);
}
.color-options .mat-card {
  position: absolute;
  padding: 14px;
  width: 100%;
  height: 100%;
}
.color-options.show {
  right: -2px;
  -webkit-transition: max-height 0.25s ease-in;
  -moz-transition: max-height 0.25s ease-in;
  transition: all 0.5s ease;
}
.color-options .card-title-text {
  padding: 1rem 1rem;
  background: var(--theme-default-grey);
}
.color-options .card-title-text h4 {
  color: var(--theme-default-dark) !important;
}
.color-options .configuration-content {
  padding: 1rem 1rem;
}
.color-options .configuration-content .setting-contant {
  border-bottom: 1px solid var(--theme-default-grey) !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
}
.color-options .configuration-content .setting-contant .color-box li {
  display: flex;
  align-items: center;
}
.color-options .configuration-content .setting-contant .color-box li input {
  border: none;
  box-shadow: none;
  width: 34px;
  height: 34px;
  background-color: transparent;
  margin-right: 5px;
  cursor: pointer;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 30px;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products1 {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px var(--theme-color-2);
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products1 img {
  width: 100%;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products2 {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px var(--theme-color-2);
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products2 img {
  width: 100%;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products3 {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px var(--theme-color-2);
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products3 img {
  width: 100%;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .demo-text {
  text-align: center;
  background: transparent;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .demo-text .btn-preview {
  background: transparent;
  margin-top: 14px;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .demo-text .btn-preview a {
  border: 1px solid var(--theme-color-2);
  background-color: var(--theme-default);
  width: 90px;
  color: var(--theme-default);
  padding: 4px 12px;
  border-radius: 30px;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item:hover .products1,
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item:hover .products2,
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item:hover .products2 {
  background-position: center 100% !important;
}
.color-options .configuration-content .setting-contant .theme-layout-version {
  cursor: pointer;
}
.color-options .configuration-content h6 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 1rem;
}

/***************************************
* 1. General styles
****************************************/
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Red Hat Text", sans-serif;
  position: relative;
  font-size: 1rem;
  color: var(--theme-default-blackish) !important;
}

h1 {
  font-size: 60px;
  color: var(--theme-default-dark);
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 36px;
  color: var(--theme-default-dark);
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 28px;
  text-align: left;
  color: var(--theme-default-dark);
  font-weight: 500;
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-default-dark);
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--theme-default-dark);
  line-height: 24px;
}

* {
  margin: 0;
  padding: 0;
}

.mat-raised-button {
  font-family: "Red Hat Text" !important;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-slider-track-fill {
  background-color: var(--theme-orange);
}

.mat-primary .mat-slider-thumb {
  background-color: var(--theme-orange);
}

.mat-raised-button.mat-warn {
  background-color: var(--theme-main-red);
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px var(--theme-default-grey), 0 1px 1px 0 var(--theme-default-secondary), 0 1px 3px 0 var(--theme-default-grey) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--theme-default-secondary) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--theme-default-secondary) !important;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: var(--theme-default-dark);
}

.mat-expansion-panel:hover .mat-expansion-indicator::after,
.mat-expansion-panel:focus .mat-expansion-indicator::after {
  color: var(--theme-main-blue);
}

.sophia-app .mat-drawer-container {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
  background-color: var(--theme-default);
}

.mat-menu-panel {
  min-width: 100% !important;
  max-width: 100% !important;
}

.mat-mini-fab.mat-accent {
  background-color: var(--theme-orange);
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center !important;
}

li {
  list-style-type: none;
}

.mat-snack-bar-container.error {
  background: var(--theme-main-red) !important;
}

.mat-snack-bar-container.info {
  background: var(--theme-color-2) !important;
  color: var(--theme-default);
}

.mat-snack-bar-container.warning {
  background: var(--theme-main-yellow) !important;
  color: var(--theme-default-secondary) 0;
}

.mat-snack-bar-container.success {
  background: var(--theme-green) !important;
  color: var(--theme-default);
}

.mat-snack-bar-container button {
  width: 10px !important;
  color: var(--theme-default) !important;
  background-color: transparent !important;
  padding: 0px !important;
}

.mat-drawer-backdrop {
  background-color: var(--theme-color-2);
}

.mat-drawer-container {
  color: var(--theme-default-dark);
}

.mat-radio-label-content {
  color: var(--theme-color-2);
  font-weight: 400;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: var(--theme-main-red);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: var(--theme-default-blackish) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--theme-default-blackish) !important;
}

.mat-raised-button.mat-primary {
  background-color: var(--theme-default-blackish);
  color: var(--theme-default);
  border-radius: 23px;
}

.mat-input-element {
  caret-color: var(--theme-orange) !important;
}

.mat-tab-group.mat-primary .mat-tab-label {
  opacity: 0.7;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--theme-default);
}

.container {
  max-width: 1390px;
  margin: 0 auto;
  padding: 0 15px !important;
}

.divider {
  border-top: 1px solid var(--theme-default-grey);
}

.bg {
  background: var(--theme-default-grey);
}

.ml-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.w-50 {
  width: 50%;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.no-pdn {
  padding: 0 !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.devider {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed var(--theme-color-2);
}

.w-100 {
  width: 100% !important;
}

.text-muted {
  color: var(--theme-color-2) !important;
}

.text-right {
  text-align: right;
}

.sec-padding {
  padding: 60px 0;
}

.btn-project {
  padding: 5px 32px !important;
  box-shadow: none !important;
}

/******** Product-1 styles *********/
.product-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  -moz-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  padding: 0;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default-grey);
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a {
  color: var(--theme-default-dark);
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a:hover {
  color: var(--theme-default) !important;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
  color: var(--theme-color-2);
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-default);
  font-size: inherit;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap {
  margin: 10px 0 15px 0;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .stars {
  color: var(--theme-orange);
  margin: 2px 0 10px 0;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .stars span {
  color: var(--theme-default-grey);
  font-size: 13px;
  margin-left: 10px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .stars mat-icon {
  font-size: 17px;
  color: var(--theme-orange);
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: var(--theme-default);
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a {
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  border-radius: 0;
  text-align: center;
  line-height: 39px;
  color: var(--theme-default);
  font-size: 14px;
  box-shadow: none !important;
  transition: 0.4s ease;
  border: 2px solid var(--theme-default) !important;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a mat-icon {
  font-size: 18px;
  vertical-align: middle;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a:hover {
  border: 2px solid var(--theme-default) !important;
  background: var(--theme-default);
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a:hover span {
  color: var(--theme-default) !important;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]):hover .price-wrap a {
  background: var(--theme-orange);
}
.product-item-1 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

/******** Pack-1 styles *********/
.pack-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 0px 0px 0px 12px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  -moz-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  padding: 0;
  border: 1px solid var(--theme-color-2);
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default);
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 145px;
  height: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a {
  color: var(--theme-default-dark);
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a:hover {
  color: var(--theme-default) !important;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
  color: var(--theme-color-2);
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-default);
  font-size: 14px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  letter-spacing: -0.3px;
  color: var(--theme-color-2);
  font-size: 12px;
  font-weight: 400;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a {
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  border-radius: 6px;
  height: 38px;
  color: var(--theme-default);
  font-size: 11px;
  letter-spacing: -0.25px;
  font-family: "Red Hat Text", sans-serif;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--theme-color-2);
  font-size: 14px;
  font-family: "Red Hat Text", sans-serif;
  margin-top: 5px;
  letter-spacing: -0.35px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.pack-item-1 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}
.pack-item-1 .footerButton .mat-button {
  min-width: 42px;
}

/******** customer-type-1 styles *********/
.customer-item-1 {
  position: unset;
  height: 290px;
  transition: 0.4s;
  padding: 0px 0px 0px 12px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  -moz-box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  box-shadow: 2px 1px 8px -1px var(--theme-default-grey) !important;
  padding: 0;
  border: 1px solid var(--theme-color-2);
  height: 270px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default-grey);
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a {
  color: var(--theme-default-dark);
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a:hover {
  color: var(--theme-default) !important;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
  color: var(--theme-color-2);
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-default);
  font-size: 14px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  letter-spacing: -0.3px;
  color: var(--theme-color-2);
  font-size: 12px;
  font-weight: 400;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a {
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  border-radius: 6px;
  width: 100px;
  height: 30px;
  color: var(--theme-default);
  font-size: 11px;
  letter-spacing: -0.25px;
  font-family: "Red Hat Text", sans-serif;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--theme-color-2);
  font-size: 14px;
  font-family: "Red Hat Text", sans-serif;
  margin-top: 5px;
  letter-spacing: -0.35px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.customer-item-1 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: 46px;
  z-index: 10;
  background-image: none;
}
.customer-item-1 .footerButton .mat-button {
  min-width: 42px;
}

/******** Product-2 styles *********/
.product-item-2 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px var(--theme-default-grey), 0 1px 1px 0 var(--theme-default-grey), 0 1px 3px 0 var(--theme-default-grey) !important;
  padding: 0;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default-grey);
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img .icons a {
  color: var(--theme-default-dark);
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img .icons a:hover {
  opacity: 1;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: var(--theme-orange);
  margin: 2px 0 10px 0;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: var(--theme-default-grey);
  font-size: 13px;
  margin-left: 10px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-color-2);
  font-size: 14px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: var(--theme-default);
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background-color: var(--theme-default);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background-color: var(--theme-default);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.product-item-2 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

/******** Product-3 styles *********/
.product-item-3 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px var(--theme-default-grey), 0 1px 1px 0 var(--theme-default-grey), 0 1px 3px 0 var(--theme-default-grey) !important;
  padding: 0;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default-grey);
  position: relative;
  overflow: hidden;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img .content {
  display: table-cell;
  vertical-align: bottom;
  overflow: hidden;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img .content .icons {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  width: 100%;
  text-indent: 0;
  height: auto;
  padding: 0.75rem 0 0.45rem 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background: var(--theme-default);
  z-index: 2;
  transition: 0.3s ease;
  -webkit-transform: translate3d(0, 35px, 0);
  transform: translate3d(0, 35px, 0);
  -webkit-transition: -webkit-transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  transition: transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img .content .icons a {
  cursor: pointer;
  transition: 0.5s ease;
  line-height: 22px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: var(--theme-orange);
  margin: 2px 0 10px 0;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: var(--theme-default-grey);
  font-size: 13px;
  margin-left: 10px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-color-2);
  font-size: 14px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: var(--theme-default);
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary {
  box-shadow: none;
  background: var(--theme-default);
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background: var(--theme-orange);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  background: var(--theme-default-grey);
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a:hover {
  background: var(--theme-orange);
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]):hover .content .icons {
  opacity: 0.85;
  visibility: visible;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.product-item-3 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

/***************************************
    ***************************************
    ***************************************
*  HOME-1 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-1 styles
****************************************/
.main-slider {
  height: 550px;
}
.main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.main-slider .sl-wrap .content {
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
}
.main-slider .sl-wrap .content button {
  background-color: var(--theme-default);
  color: var(--theme-default);
  padding: 5px 33px;
  width: 200px;
  box-shadow: none !important;
}
.main-slider .sl-wrap .content button span {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.main-slider .sl-wrap .content h1 {
  font-size: 48px;
  text-align: left;
  color: var(--theme-default-dark);
  text-transform: uppercase;
  letter-spacing: 3px;
}
.main-slider .sl-wrap .content h3 {
  font-size: 36px;
  text-align: left;
  color: var(--theme-default-dark);
  margin-bottom: 30px;
  font-weight: 300;
  letter-spacing: 2px;
}
.main-slider .slide-item {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

/***************************************
*  Home info bar styles
****************************************/
.info-bar {
  margin-top: 30px;
  border-bottom: 1px solid var(--theme-default-grey);
}
.info-bar .card-wrap {
  padding: 15px 0;
}
.info-bar .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid var(--theme-default-grey);
}
.info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.info-bar .card-wrap .mat-icon {
  color: var(--theme-default-dark);
  font-size: 41px;
  margin-right: 15px;
  line-height: 23px;
}

span:not(.builder-text) {
  font-size: 14px;
}

.sidenav {
  width: 250px;
  position: fixed !important;
  z-index: 1000000000000000100 !important;
}

.mat-toolbar-multiple-rows {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
}

.welcome-message p {
  font-size: 13.5px;
  color: var(--theme-color-2);
}

/***************************************
*  Header-1 styles
****************************************/
/*  Topbar styles */
.main-topbar {
  background: var(--theme-default) !important;
}
.main-topbar .top-bar .widget-text {
  padding: 9px 7px;
}
.main-topbar .top-bar .widget-text a {
  color: var(--theme-default-dark);
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.main-topbar .top-bar .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
}
.main-topbar .top-bar .widget-text a:hover {
  color: var(--theme-color-2);
}
.main-topbar .top-bar .widget-text:not(:last-child) {
  margin: 0 20px 0 -15px;
}
.main-topbar .top-bar .widget-text .mat-button-wrapper img {
  margin-right: 3px;
}
.main-topbar .top-bar .welcome-message p {
  color: var(--theme-color-2);
  font-size: 13.5px;
  font-weight: 400;
}
.main-topbar .top-bar span .mat-icon {
  color: var(--theme-orange);
}
.main-topbar .top-bar .cur-icon {
  color: var(--theme-color-2) !important;
}

/*  header styles */
.home-header.yellow {
  display: none;
}
.home-header .kuro {
  background-color: var(--theme-brand-color-1);
  padding: 13px 0;
  color: var(--theme-default-);
}
.home-header .call-bucket-wrap .info-message-icon {
  color: var(--theme-default-secondary) !important;
}
.home-header .top-navbar {
  background: transparent !important;
}
.home-header .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--theme-default) !important;
}
.home-header .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header .logo {
  padding: 25px 0;
}
.home-header .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: var(--theme-default-dark);
  background: var(--theme-default-grey);
}
.home-header .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border-color: var(--theme-default);
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header .search-form .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: var(--theme-default);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-default-dark);
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 var(--theme-default-grey);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.home-header .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-white);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

.home-header-yellow {
  background: var(--theme-main-yellow);
  padding: 13px 0;
}
.home-header-yellow.green {
  display: none;
}
.home-header-yellow.blue {
  display: none;
}
.home-header-yellow.red {
  display: none;
}
.home-header-yellow.pink {
  display: none;
}
.home-header-yellow.purple {
  display: none;
}
.home-header-yellow.grey {
  display: none;
}
.home-header-yellow.orange {
  display: none;
}
.home-header-yellow .kuro {
  background-color: var(--theme-brand-color-1);
}
.home-header-yellow .top-navbar {
  background: transparent !important;
}
.home-header-yellow .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-yellow .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-yellow .top-navbar .mat-button-wrapper .commerce-buttons .flex-row-button .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-default);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}
.home-header-yellow .logo {
  padding: 25px 0;
}
.home-header-yellow .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-yellow .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: var(--theme-default-dark);
  background: var(--theme-default-grey);
}
.home-header-yellow .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border-color: var(--theme-default);
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-yellow .search-form .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: var(--theme-default);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-default-dark);
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 var(--theme-default-grey);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  color: var(--theme-default-secondary);
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .info-text {
  color: var(--theme-default-secondary);
}
.home-header-yellow .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-yellow .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-default);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

/*  shopping widgets styles */
.spCard-main {
  width: 100%;
}
.spCard-main .mat-menu-content {
  width: 239px !important;
}
.spCard-main .spCard-dropdown {
  background-color: var(--theme-default);
  padding: 15px 10px;
  max-width: 300px;
  z-index: 400;
}
.spCard-main .spCard-dropdown .card-list-title {
  padding: 0 0 10px 0;
}
.spCard-main .spCard-dropdown h4 {
  padding-bottom: 10px;
}
.spCard-main .spCard-dropdown .new-product .product img {
  width: 70px;
}
.spCard-main .spCard-dropdown .new-product .close-circle {
  flex: 1;
}
.spCard-main .spCard-dropdown .new-product .close-circle a {
  cursor: pointer;
  color: var(--theme-default-grey);
  transition: 0.3s;
  float: right;
}
.spCard-main .spCard-dropdown .new-product .close-circle a:hover {
  color: var(--theme-orange);
}
.spCard-main .spCard-dropdown .new-product .close-circle a i {
  font-size: 20px;
}
.spCard-main .spCard-dropdown .new-product .item {
  padding: 10px 0;
}
.spCard-main .spCard-dropdown .new-product .item .product {
  margin-right: 7px;
  background: var(--theme-default-grey);
  border-radius: 3px;
}
.spCard-main .spCard-dropdown .new-product .item .desc p {
  font-weight: 500;
}
.spCard-main .spCard-dropdown .new-product .item .desc span {
  color: var(--theme-color-2);
  font-size: 15px;
  margin-top: 5px;
  /* padding-top: 7px; */
  display: block;
}
.spCard-main .spCard-dropdown .new-product .total {
  padding-top: 15px;
}
.spCard-main .spCard-dropdown .new-product .total p {
  font-size: 15px;
}
.spCard-main .spCard-dropdown .new-product .total span {
  font-weight: 500;
  font-size: 16px;
}
.spCard-main .spCard-dropdown .card-list-title a {
  color: var(--theme-default-dark);
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .card-list-title p {
  color: var(--theme-default-dark);
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .woo-message {
  text-align: center;
  padding: 0;
  margin: 1.5em 0 0 0;
}

/***************************************
*  Shopping widget styles
****************************************/
.card-list-title2 {
  padding: 5px 0 15px 0;
}

/***************************************
*  Home product section styles
****************************************/
.products {
  position: relative;
}
.products .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
  pointer-events: auto;
}
.products .product-wraper {
  padding: 20px 0px 20px 20px;
}
.products .product-wraper .product-box {
  width: 100%;
}

/***************************************
*  Home baners section styles
****************************************/
.banners-container .baners {
  background: var(--theme-default-grey) !important;
  margin-right: 15px;
}
.banners-container .bn-wrap {
  margin-bottom: 18px;
}
.banners-container .baner {
  height: 100%;
  transition: 0.15s ease-in;
  text-align: left;
}
.banners-container .baner .info {
  padding: 20px;
}
.banners-container .baner .info h4 {
  font-size: 21px;
  font-weight: 600;
  color: var(--theme-default-dark);
  margin-bottom: 5px;
  text-transform: uppercase;
}
.banners-container .baner h4.big-title {
  font-size: 37px;
  font-weight: 500;
  color: var(--theme-default-dark);
  margin-bottom: 5px;
  text-transform: uppercase;
}
.banners-container .baner .info .big-price {
  padding: 10px 25px !important;
  box-shadow: none !important;
  width: 135px;
  border-radius: 5px;
  text-align: center;
}
.banners-container .baner .info .big-price span {
  font-size: 27px !important;
}
.banners-container .baner p {
  color: var(--theme-default-dark);
}
.banners-container .baner a {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: var(--theme-default-dark);
  margin-top: 15px;
  display: block;
}
.banners-container .first {
  height: 450px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 4px;
}
.banners-container .seccond {
  /* height: 200px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin: 0 10px;
  background-color: var(--theme-default-grey);
}

/***************************************
*  Home vertical-product section styles
****************************************/
.vertical-products .widget-column .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.vertical-products .widget-column .title .widget-title {
  font-size: 21px;
  font-weight: 500;
}
.vertical-products .widget-column .title .widget-title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.vertical-products .widget-column .title .widget-title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}
.vertical-products .widget-column .media-image {
  background: var(--theme-default-grey);
  margin-right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 3px;
}
.vertical-products .widget-column .media-image .small {
  width: 100%;
}
.vertical-products .widget-column .media-body span {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 1;
  color: var(--theme-default-dark);
}
.vertical-products .widget-column .media-body .stars mat-icon {
  color: var(--theme-orange);
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.vertical-products .widget-column .media-body p.price {
  margin-top: 5px;
}
.vertical-products .widget-column .product-list li:not(:last-child) {
  margin-bottom: 20px;
}
.vertical-products .product-panel:not(:last-child) {
  padding-right: 30px;
}

/***************************************
*  Home footer section styles
****************************************/
.footer {
  background-color: var(--theme-default-grey);
}
.footer .footer-widget {
  padding-right: 15px;
}
.footer .footer-widget .social {
  margin-top: 20px;
}
.footer .footer-widget .social li {
  border: 1px solid var(--theme-default-blackish);
  border-radius: 50%;
  text-align: center;
  margin-right: 5px;
}
.footer .footer-widget .social li a i {
  color: var(--theme-default-blackish);
}
.footer .footer-widget .ped-btn-footer {
  box-shadow: none !important;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background-color: var(--theme-default-blackish) !important;
  color: var(--theme-default);
  border-radius: 3px;
  line-height: 45px;
}
.footer .footer-widget .title {
  margin-bottom: 10px;
}
.footer .footer-widget .title h5 {
  font-size: 18px;
  font-weight: 600;
}
.footer .footer-widget a {
  text-decoration: none;
  font-size: 15px;
  line-height: 32px;
  color: var(--theme-default-blackish);
}
.footer .footer-widget .newsletter-widget {
  position: relative;
  margin: 15px 0 20px 0;
}
.footer .footer-widget p {
  line-height: 24px;
  color: var(--theme-default-blackish);
}
.footer .footer-widget ul {
  list-style-type: none;
}
.footer .footer-widget .about-widget .logo img {
  max-width: 240px;
}
.footer .footer-widget .tel-box .icon i {
  color: var(--theme-default-blackish);
  display: block;
  line-height: 27px;
  vertical-align: middle;
  margin-right: 5px;
  width: 18px;
  text-align: center;
  font-size: 18px;
}
.footer .footer-widget .tel-box .text p a {
  line-height: 22px;
}
.footer .subscribe-widget input {
  height: 45px;
  background: var(--theme-default);
  font-size: 15px;
  line-height: 45px;
  border: 1px solid var(--theme-default-grey);
  border-radius: 4px;
  padding: 0 65px 0 20px;
  color: var(--theme-color-2);
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 2 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-2 styles
****************************************/
.carousel-2 {
  padding-top: 25px;
}
.carousel-2 .ctn-box {
  box-sizing: content-box !important;
}
.carousel-2 .main-slider {
  height: 461px;
}
.carousel-2 .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-2 .main-slider .sl-wrap .content {
  padding-left: 20px;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
  transform: none;
}
.carousel-2 .slider-categories {
  padding-right: 15px;
}
.carousel-2 .slider-categories .categories-wrap {
  border: 1px solid var(--theme-default-grey);
}
.carousel-2 .slider-categories .categories-wrap .title {
  background: var(--theme-default-grey);
  border-bottom: 1px solid var(--theme-color-2);
  color: var(--theme-default-dark);
  padding: 15px 20px 15px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.carousel-2 .slider-categories .categories-wrap .category-contant ul li {
  display: block;
}
.carousel-2 .slider-categories .categories-wrap .category-contant ul li a {
  padding-top: 13px;
  padding-bottom: 13px !important;
  border-top: 1px solid var(--theme-default-grey);
  margin: 0 10px -1px;
}
.carousel-2 .slider-categories .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.carousel-2 .slider-categories .categories-wrap .btn-sale {
  margin: 20px 10px;
}
.carousel-2 .slider-categories .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none;
}
.carousel-2 .carousel-2-wrapper {
  max-width: 70%;
}
.carousel-2 .carousel-2-wrapper .main-slider {
  height: 461px;
}
.carousel-2 .carousel-2-wrapper .main-slider .content {
  padding-left: 20px;
  padding-right: 485px;
}

/***************************************
*  Header-2 styles
****************************************/
.home-header-2 {
  background: var(--theme-default);
}
.home-header-2 .logo {
  padding: 25px 0;
}
.home-header-2 .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-2 .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: var(--theme-default-dark);
  background: var(--theme-default-grey);
}
.home-header-2 .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border: 1px solid var(--theme-default-grey);
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-2 .search-form .form-wrap .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: var(--theme-default);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-default-dark);
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 var(--theme-default-grey);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.home-header-2 .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-2 .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-2 .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: var(--theme-default);
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .commerce-buttons .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  color: var(--theme-default);
  background-color: var(--theme-default);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

/***************************************
*  Header-5 styles
****************************************/
/*  header styles */
.home-header-five {
  background: var(--theme-default);
}
.home-header-five .header-wrapper {
  border-bottom: 1px solid var(--theme-default-grey);
  padding: 20px 0;
}
.home-header-five .header-wrapper .phone-wrap .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-five .header-wrapper .phone-wrap .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-five .header-wrapper .top-navbar {
  background: transparent;
}
.home-header-five .header-wrapper .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-five .header-wrapper .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-five .header-wrapper .thm-button button {
  color: var(--theme-default) !important;
  border-radius: 0;
  padding: 4px 40px;
}

/*  menu styles */
.menu-wraper-five {
  background: var(--theme-default);
}
.menu-wraper-five .menu-container {
  padding: 14px 0;
  height: 67px;
}
.menu-wraper-five .menu-container .mat-toolbar-row {
  height: 100%;
}
.menu-wraper-five .menu-container .main-menu {
  margin-left: -15px;
}
.menu-wraper-five .menu-container .main-menu a {
  text-transform: uppercase;
  color: var(--theme-color-2);
}
.menu-wraper-five .menu-container .call-bucket-wrap {
  padding-top: 10px;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar {
  flex-direction: row !important;
  box-sizing: border-box;
  display: flex;
  background: transparent !important;
  /*  shopping widgets styles */
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .info-message-icon .icon mat-icon {
  font-size: 35px;
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons {
  margin-top: 7px;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-default);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
  color: var(--theme-default);
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .badge .mat-badge-content {
  color: var(--theme-default);
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form {
  margin-right: 20px;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form .mat-form-field-wrapper .mat-form-field-underline {
  background-color: var(--theme-default-grey) !important;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form .mat-form-field-wrapper .mat-form-field-label {
  display: none;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .devider2 {
  height: 31px;
  width: 1px;
  background: var(--theme-default-grey);
  margin: 0 20px;
}

.spCard-main {
  width: 100%;
}
.spCard-main .mat-menu-content {
  width: 239px !important;
}
.spCard-main .spCard-dropdown {
  background-color: var(--theme-default);
  padding: 15px 10px;
  max-width: 300px;
  z-index: 400;
}
.spCard-main .spCard-dropdown .card-list-title {
  padding: 0 0 10px 0;
}
.spCard-main .spCard-dropdown h4 {
  padding-bottom: 10px;
}
.spCard-main .spCard-dropdown .new-product .product img {
  width: 70px;
}
.spCard-main .spCard-dropdown .new-product .close-circle {
  flex: 1;
}
.spCard-main .spCard-dropdown .new-product .close-circle a {
  cursor: pointer;
  color: var(--theme-default-grey);
  transition: 0.3s;
  float: right;
}
.spCard-main .spCard-dropdown .new-product .close-circle a i {
  font-size: 20px;
}
.spCard-main .spCard-dropdown .new-product .item {
  padding: 10px 0;
}
.spCard-main .spCard-dropdown .new-product .item .product {
  margin-right: 7px;
  background: var(--theme-default);
  /* border-radius: 3px; */
  border: 1px solid var(--theme-default-grey);
}
.spCard-main .spCard-dropdown .new-product .item .desc p {
  font-weight: 500;
}
.spCard-main .spCard-dropdown .new-product .item .desc span {
  color: var(--theme-color-2);
  font-size: 15px;
  margin-top: 5px;
  /* padding-top: 7px; */
  display: block;
}
.spCard-main .spCard-dropdown .new-product .total {
  padding-top: 15px;
}
.spCard-main .spCard-dropdown .new-product .total p {
  font-size: 15px;
}
.spCard-main .spCard-dropdown .new-product .total span {
  font-weight: 500;
  font-size: 16px;
}
.spCard-main .spCard-dropdown .card-list-title a {
  color: var(--theme-default-dark);
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .card-list-title p {
  color: var(--theme-default-dark);
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .woo-message {
  text-align: center;
  padding: 0;
  margin: 1.5em 0 0 0;
}

/***************************************
*  Home-2 info bar styles
****************************************/
.info-bar {
  margin-top: 30px;
  border: 1px solid var(--theme-default-grey);
}
.info-bar .card-wrap {
  padding: 15px 0;
}
.info-bar .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid var(--theme-default-grey);
  box-shadow: none !important;
  background: transparent !important;
}
.info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.info-bar .card-wrap .mat-icon {
  color: var(--theme-default);
  font-size: 41px;
  margin-right: 10px;
  line-height: 65px;
  clear: both;
  /* display: block; */
  width: auto;
  height: 100%;
  /* vertical-align: middle; */
  padding: 0 3px;
}

/***************************************
*  Home-2 product section styles
****************************************/
.home-2-wrap .products-section {
  background: var(--theme-default-grey);
}
.home-2-wrap .products-section .products {
  padding: 0 !important;
}
.home-2-wrap .products-section .products .product-wraper .product-box .product-item {
  width: 240px;
}
.home-2-wrap .products-section .products .product-wraper .product-box .product-item .product-img {
  background: var(--theme-default);
}
.home-2-wrap .products-section .products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.home-2-wrap .products-section h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 23px;
  font-weight: 580;
  position: absolute;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 3 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-3 styles
****************************************/
.carousel-3 {
  background: var(--theme-default);
  padding: 25px 0;
}
.carousel-3 .ctn-box {
  box-sizing: content-box !important;
}
.carousel-3 .carousel-2-wrapper {
  max-width: 70%;
}
.carousel-3 .carousel-2-wrapper .main-slider {
  height: 461px;
}
.carousel-3 .carousel-2-wrapper .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-3 .carousel-2-wrapper .main-slider .sl-wrap .content {
  padding-left: 20px;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
}
.carousel-3 .carousel-2-wrapper .main-slider .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 5px;
  display: inline-block;
  border-radius: 13px;
  background: var(--theme-default-dark);
  opacity: 0.6;
}
.carousel-3 .slider-categories {
  padding: 0 !important;
}
.carousel-3 .slider-categories .carousel-banners-wrapper {
  padding-left: 15px;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner {
  width: 100%;
  height: 223px;
  background: var(--theme-default-grey);
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin: 0 10px;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info {
  padding: 40px 0;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
  width: 100%;
  height: auto;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .text-box h4 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .text-box button {
  color: var(--theme-default);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  vertical-align: baseline;
  text-align: left;
  margin-top: 21px;
  min-width: auto !important;
  line-height: 0 !important;
  padding: 0 !important;
  border-radius: 4px;
  overflow: visible;
  box-shadow: none !important;
}

.home-3-wrap .home3-sidebar {
  padding-right: 15px;
}
.home-3-wrap .home3-sidebar .categories-wrap {
  border: 1px solid var(--theme-color-2);
  margin-bottom: 25px;
}
.home-3-wrap .home3-sidebar .categories-wrap .title {
  background: var(--theme-default-grey);
  border-bottom: 1px solid var(--theme-color-2);
  color: var(--theme-default-dark);
  padding: 15px 20px 15px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.home-3-wrap .home3-sidebar .categories-wrap .category-contant ul li {
  display: block;
}
.home-3-wrap .home3-sidebar .categories-wrap .category-contant ul li a {
  padding-top: 13px;
  padding-bottom: 13px !important;
  border-top: 1px solid var(--theme-color-2);
  margin: 0 10px -1px;
}
.home-3-wrap .home3-sidebar .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.home-3-wrap .home3-sidebar .categories-wrap .btn-sale {
  margin: 20px 10px;
}
.home-3-wrap .home3-sidebar .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none !important;
}
.home-3-wrap .home3-sidebar .brands {
  border: 1px solid var(--theme-color-2);
  padding: 15px 20px;
  margin-bottom: 25px;
}
.home-3-wrap .home3-sidebar .brands .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.home-3-wrap .home3-sidebar .brands .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.home-3-wrap .home3-sidebar .brands .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}
.home-3-wrap .home3-sidebar .brands .brand-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.home-3-wrap .home3-sidebar .brands .brand-logos .brand-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.home-3-wrap .home3-sidebar .brands .brand-logos .brand-item a img {
  width: 100%;
}
.home-3-wrap .home3-sidebar .mat-toolbar {
  height: 17%;
  margin-bottom: 25px;
  background-color: var(--theme-default);
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget {
  padding: 15px 20px;
  text-align: center;
  margin-bottom: 25px;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .sale-widget1 {
  color: var(--theme-default);
  font-size: 36px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .sale-widget2 {
  color: var(--theme-default);
  font-size: 18px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .discount {
  display: inline-block;
  color: var(--theme-default-dark);
  font-size: 77px;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .percent {
  display: inline-block;
  line-height: 31px;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .percent span {
  display: block;
  font-size: 2.5em;
  font-weight: 500;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget p {
  color: var(--theme-default);
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.home-3-wrap .home3-sidebar .subscribe-widget {
  padding: 15px 20px;
  background: var(--theme-default-grey);
  text-align: center;
}
.home-3-wrap .home3-sidebar .subscribe-widget span {
  font-weight: 600;
  display: block;
}
.home-3-wrap .home3-sidebar .subscribe-widget p {
  color: var(--theme-default-dark);
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.home-3-wrap .home3-sidebar .subscribe-widget .form {
  position: relative;
  margin-top: 20px;
}
.home-3-wrap .home3-sidebar .subscribe-widget .form .newsletter-widget input {
  height: 45px;
  background: var(--theme-default);
  font-size: 15px;
  line-height: 45px;
  border: 1px solid var(--theme-default-grey);
  border-radius: 4px;
  padding: 0 65px 0 20px;
  color: var(--theme-color-2);
}
.home-3-wrap .home3-sidebar .subscribe-widget .form .newsletter-widget .ped-btn-footer {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  width: 100%;
  border-radius: 3px;
  line-height: 45px;
  box-shadow: none !important;
}
.home-3-wrap .main-home3-wrapper {
  padding-left: 15px;
}
.home-3-wrap .main-home3-wrapper .info-bar {
  margin-top: 0px;
  border: 1px solid var(--theme-default-grey);
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap {
  padding: 15px 0;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid var(--theme-default-grey);
  padding: 9px;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-icon {
  font-size: 41px;
  margin-right: 10px;
  line-height: 65px;
  width: 25%;
}
.home-3-wrap .main-home3-wrapper .products {
  background: var(--theme-default);
  position: relative;
}
.home-3-wrap .main-home3-wrapper .products .product-wraper .product-box .product-item {
  width: 227px;
}
.home-3-wrap .main-home3-wrapper .products .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  height: 100%;
}
.home-3-wrap .main-home3-wrapper .products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.home-3-wrap .main-home3-wrapper h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  position: absolute;
  top: 69px;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap {
  width: 100%;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner {
  position: relative;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
  position: absolute;
  top: 33px;
  left: 35px;
  font-size: 95%;
  z-index: 9999999999999;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .subtitle {
  color: var(--theme-default);
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
  color: var(--theme-orange);
  font-size: 38px;
  font-weight: 600;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
  border: 1px solid var(--theme-orange);
  color: var(--theme-default-dark) !important;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin-top: 14px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background: var(--theme-orange);
  width: 120px;
  border-radius: 3px;
  line-height: 40px;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner img {
  position: relative;
  display: block;
  height: auto;
  max-width: 100%;
  width: calc(100% + 60px);
}

.menu-container1 {
  background: var(--theme-default-grey);
}
.menu-container1 a.mat-button {
  text-transform: uppercase;
  color: var(--theme-default-dark);
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

/***************************************
*  Menu-2 styles
****************************************/
.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 4px solid var(--theme-orange) !important;
}
.mat-menu-content .mat-menu-item {
  border-bottom: 1px dashed var(--theme-default-grey);
}

.top-navbar a {
  padding: 15px 26px;
}

.menu-container2 {
  background: var(--theme-default);
}
.menu-container2 .mat-toolbar {
  background: transparent !important;
}
.menu-container2 a.mat-button {
  text-transform: uppercase;
  color: var(--theme-default) !important;
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

/***************************************
*  Sidebar styles
****************************************/
.mat-drawer {
  width: 240px;
  position: fixed;
  margin-left: 0px;
}

.toggle-header {
  height: 64px;
  background: var(--theme-main-red);
}

.sidebar-menu {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--theme-default-grey);
}
.sidebar-menu .menu-item a {
  color: var(--theme-default-dark);
  padding: 8px 15px;
  font-size: 16px;
  display: block;
}

/***************************************
*  Products left sidebar page styles
****************************************/
.left-sidebar-wrapper mat-sidenav.filter-sidenav.mat-drawer.mat-sidenav.ng-tns-c15-4.ng-trigger.ng-trigger-transform.ng-tns-c13-3.ng-star-inserted.mat-drawer-over {
  padding-right: 10px;
}

.left-sidebar-wrapper .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid var(--theme-color-2);
  box-shadow: none !important;
  margin-bottom: 20px;
  background: transparent !important;
}
.left-sidebar-wrapper .mat-expansion-panel .mat-expansion-panel-header-title span {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}
.left-sidebar-wrapper .filter-sidenav {
  width: 280px;
  border: 0 !important;
}
.left-sidebar-wrapper .filter-sidenav .brands .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.left-sidebar-wrapper .filter-sidenav .brands .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.left-sidebar-wrapper .filter-sidenav .brands .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}
.left-sidebar-wrapper .filter-sidenav .brands .brand-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.left-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.left-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item a img {
  width: 100%;
}
.left-sidebar-wrapper .filter-sidenav .popular-products {
  margin-top: 20px;
}
.left-sidebar-wrapper .filter-row {
  padding: 6px 25px 0px;
  border-bottom: 1px solid var(--theme-default-grey);
  border-top: 1px solid var(--theme-default-grey);
  margin-bottom: 20px;
  box-shadow: none !important;
}
.left-sidebar-wrapper .all-products {
  padding-left: 17px;
}
.left-sidebar-wrapper .all-products select {
  /* margin: 50px; */
  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: none;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  background: url(/assets/images/dropdown.png) no-repeat 90%;
}
.left-sidebar-wrapper .all-products .col {
  padding: 9px 0 9px 0px;
}
.left-sidebar-wrapper .all-products .col .product-item {
  position: relative;
  height: auto;
  padding: 0 10px;
  transition: 0.4s;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px var(--theme-default-grey), 0 1px 1px 0 var(--theme-default-grey), 0 1px 3px 0 var(--theme-default-grey) !important;
  padding: 0;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default-grey);
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons a {
  color: var(--theme-default-dark);
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: var(--theme-orange);
  margin: 2px 0 10px 0;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: var(--theme-default-grey);
  font-size: 13px;
  margin-left: 10px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-color-2);
  font-size: 14px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: var(--theme-default);
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  background: var(--theme-default);
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary {
  box-shadow: none;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary .mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.left-sidebar-wrapper .no-shadow {
  box-shadow: none !important;
}

/***************************************
*  Products left sidebar page styles
****************************************/
.right-sidebar-wrapper .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid var(--theme-color-2);
  box-shadow: none !important;
  margin-bottom: 20px;
  background: transparent !important;
}
.right-sidebar-wrapper .mat-expansion-panel .mat-expansion-panel-header-title span {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}
.right-sidebar-wrapper .filter-sidenav {
  width: 280px;
  border: 0 !important;
}
.right-sidebar-wrapper .filter-sidenav .brands .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.right-sidebar-wrapper .filter-sidenav .brands .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.right-sidebar-wrapper .filter-sidenav .brands .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}
.right-sidebar-wrapper .filter-sidenav .brands .brand-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.right-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.right-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item a img {
  width: 100%;
}
.right-sidebar-wrapper .filter-sidenav .popular-products {
  margin-top: 20px;
}
.right-sidebar-wrapper .filter-row {
  padding: 6px 25px 0px;
  border-bottom: 1px solid var(--theme-default-grey);
  border-top: 1px solid var(--theme-default-grey);
  margin-bottom: 20px;
  box-shadow: none !important;
}
.right-sidebar-wrapper .all-products select {
  /* margin: 50px; */
  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: none;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  background: url(/assets/images/dropdown.png) no-repeat 90%;
}
.right-sidebar-wrapper .all-products .col {
  padding: 9px 0 9px 0px;
}
.right-sidebar-wrapper .all-products .col .product-box {
  min-height: 440px;
  width: 307px;
  margin-left: 9px !important;
  margin-right: 9px !important;
  box-shadow: 0 2px 14px var(--theme-color-2);
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  padding: 0;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default-grey);
  display: table-cell;
  vertical-align: middle;
  height: 306px;
  width: 299px;
  margin: 0 auto;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img img {
  /* max-height: 100%; */
  max-width: 85%;
  padding: 25px;
  margin: auto;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons a {
  color: var(--theme-default-dark);
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: var(--theme-orange);
  margin: 2px 0 10px 0;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: var(--theme-default-grey);
  font-size: 13px;
  margin-left: 10px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: var(--theme-color-2);
  font-size: 14px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: var(--theme-default);
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background-color: var(--theme-default);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary {
  box-shadow: none;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary .mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.right-sidebar-wrapper .no-shadow {
  box-shadow: none !important;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 4 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-4 styles
****************************************/
.home-header-three {
  background: var(--theme-default);
  border-top: 1px solid var(--theme-default-grey);
}
.home-header-three .logo {
  padding: 25px 0;
}
.home-header-three .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-three .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: var(--theme-default-dark);
  background: var(--theme-default-grey);
}
.home-header-three .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border: 2px solid var(--theme-default-grey);
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-three .search-form .form-wrap .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-default);
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: none;
  box-shadow: none !important;
  -webkit-appearance: none;
}
.home-header-three .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-three .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-three .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar {
  background: transparent;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button {
  background: transparent;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-default);
  height: 18px;
  width: 18px;
  color: var(--theme-default);
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

.menu-container4 {
  background: var(--theme-default);
  border-top: 1px solid var(--theme-default-grey);
}
.menu-container4 .main-menu {
  margin-left: -15px;
  margin: 0 auto;
}
.menu-container4 .main-menu a.mat-button {
  text-transform: uppercase;
  color: var(--theme-default);
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

.topbar_four {
  background: var(--theme-default-grey) !important;
}
.topbar_four .top-bar .widget-text {
  padding: 9px 7px;
}
.topbar_four .top-bar .widget-text a {
  color: var(--theme-default-dark);
}

/***************************************
*  Home-4 info bar styles
****************************************/
.info-bar-four-wrapper {
  margin-top: 0px;
  border: none;
  background: var(--theme-default);
  border-bottom: 1px solid var(--theme-default-grey);
}
.info-bar-four-wrapper .info-bar-four .card-wrap {
  padding: 15px 0;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid var(--theme-default-grey);
  box-shadow: none !important;
  background: transparent;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-card.no-bd {
  border-right: none;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-icon {
  color: var(--theme-default);
  font-size: 41px;
  margin-right: 10px;
  line-height: 65px;
  clear: both;
  overflow: hidden;
  /* display: block; */
  width: auto;
  height: 100%;
  /* vertical-align: middle; */
  padding: 0 3px;
}

/***************************************
*  Home-4 banner styles
****************************************/
.banners-four {
  padding: 25px 0;
}
.banners-four .banners-container .four {
  height: 162px;
}

/***************************************
*  Home-4 products styles
****************************************/
.products .products-four h3 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}
.products .products-four h3::before {
  content: "";
  left: 29%;
  position: absolute;
  width: 43%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.products .products-four h3::after {
  content: "";
  left: 48.5%;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}

.featured-products-wrap {
  background: var(--theme-default-grey);
}

/***************************************
*  Home-4 banner-promotion styles
****************************************/
.main-banner .banner-pro {
  height: 442px;
}
.main-banner .banner-pro img {
  max-width: 100%;
  height: auto;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 5 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-5 styles
****************************************/
/***************************************
*  Menu-5 styles
****************************************/
.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 4px solid var(--theme-default) !important;
}
.mat-menu-content .mat-menu-item {
  border-bottom: 1px dashed var(--theme-default-grey);
}
.mat-menu-content .mat-menu-item span {
  background: var(--theme-default) !important;
  font-size: 11px;
  font-weight: 400;
  color: var(--theme-default);
  padding: 1px 3px;
}
.mat-menu-content .mat-menu-item .soon {
  background: var(--theme-green) !important;
}

.top-navbar a {
  padding: 15px 26px;
}

.menu-container5 {
  background: transparent;
}
.menu-container5 .container {
  background: transparent;
}
.menu-container5 .container a.mat-button {
  text-transform: uppercase;
  color: var(--theme-default-dark);
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

/***************************************
*  Categories menu styles
****************************************/
.categories-menu-wrap .category-contant ul {
  background-color: var(--theme-default-dark);
}
.categories-menu-wrap .category-contant ul li {
  width: 14.285%;
  transition: 0.3s;
}
.categories-menu-wrap .category-contant ul li a {
  display: flex;
  flex-direction: column;
  line-height: 16px;
  letter-spacing: -0.13px;
  color: var(--theme-default);
  padding: 15px 16px 18px 16px;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
}
.categories-menu-wrap .category-contant ul li a i {
  margin-bottom: 5px;
}
.categories-menu-wrap .category-contant ul li a:hover {
  color: var(--theme-default-dark) !important;
}
.categories-menu-wrap .category-contant ul li:hover {
  background: var(--theme-default-grey);
  color: var(--theme-default-dark) !important;
}

/***************************************
*  Products styles
****************************************/
.home-5-wrap {
  background: var(--theme-default);
}
.home-5-wrap .products {
  position: relative;
}
.home-5-wrap .products h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  position: absolute;
  top: 69px;
}
.home-5-wrap .products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/***************************************
*  Categories section styles
****************************************/
.home-5-wrap .categories {
  background: var(--theme-default-grey);
}
.home-5-wrap .categories .title {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 1.25rem;
}
.home-5-wrap .categories .title h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
}
.home-5-wrap .categories .title h3::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.home-5-wrap .categories .title h3::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}
.home-5-wrap .categories .left-section {
  padding-right: 7px;
}
.home-5-wrap .categories .left-section .item {
  background: var(--theme-default);
  height: 100%;
  padding: 20px;
  border: 1px solid var(--theme-default-grey);
}
.home-5-wrap .categories .left-section .item .product {
  text-align: center;
}
.home-5-wrap .categories .left-section .item .product img {
  max-width: 40%;
}
.home-5-wrap .categories .left-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home-5-wrap .categories .left-section .item .product-info h4 {
  margin-right: 15px;
}
.home-5-wrap .categories .left-section .item .product-info h4 a {
  color: var(--theme-default-dark);
}
.home-5-wrap .categories .left-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.home-5-wrap .categories .left-section .item .product-info ul {
  margin-left: 15px;
}
.home-5-wrap .categories .left-section .item .product-info ul li a {
  color: var(--theme-color-2);
  font-size: 15px;
}
.home-5-wrap .categories .left-section .item .product-info ul li a:hover {
  text-decoration: underline;
}
.home-5-wrap .categories .right-section {
  padding-left: 7px;
}
.home-5-wrap .categories .right-section .item {
  background: var(--theme-default);
  height: 100%;
  padding: 20px;
  border: 1px solid var(--theme-default-grey);
}
.home-5-wrap .categories .right-section .item .product {
  text-align: center;
}
.home-5-wrap .categories .right-section .item .product img {
  max-width: 40%;
}
.home-5-wrap .categories .right-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home-5-wrap .categories .right-section .item .product-info h4 {
  margin-right: 15px;
}
.home-5-wrap .categories .right-section .item .product-info h4 a {
  color: var(--theme-default-dark);
}
.home-5-wrap .categories .right-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.home-5-wrap .categories .right-section .item .product-info li a {
  color: var(--theme-color-2);
  font-size: 15px;
}
.home-5-wrap .categories .right-section .item .product-info li a:hover {
  text-decoration: underline;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - INDUSTRIAL STYLES
****************************************
****************************************
****************************************/
/***************************************


/***************************************
*  Home product section styles
****************************************/
.products-industrial {
  position: relative;
}
.products-industrial .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
  pointer-events: auto;
}
.products-industrial .product-wraper {
  padding: 20px 0px 20px 20px;
}
.products-industrial .product-wraper .product-box {
  width: 100%;
}
.products-industrial .product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: var(--theme-default);
}

/* Categories banners */
.industrial-home-wrap .categories {
  background: var(--theme-default);
}
.industrial-home-wrap .categories .title {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 1.25rem;
}
.industrial-home-wrap .categories .title h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
}
.industrial-home-wrap .categories .title h3::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.industrial-home-wrap .categories .title h3::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-color-2);
  bottom: 0.3125rem;
}
.industrial-home-wrap .categories .left-section {
  padding-right: 7px;
}
.industrial-home-wrap .categories .left-section .item {
  background: var(--theme-default-grey);
  height: 100%;
  padding: 20px;
  border: 1px solid var(--theme-default-grey);
}
.industrial-home-wrap .categories .left-section .item .product {
  text-align: center;
}
.industrial-home-wrap .categories .left-section .item .product img {
  max-width: 40%;
}
.industrial-home-wrap .categories .left-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.industrial-home-wrap .categories .left-section .item .product-info h4 {
  margin-right: 15px;
}
.industrial-home-wrap .categories .left-section .item .product-info h4 a {
  color: var(--theme-default-dark);
}
.industrial-home-wrap .categories .left-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .left-section .item .product-info ul {
  margin-left: 15px;
}
.industrial-home-wrap .categories .left-section .item .product-info ul li a {
  color: var(--theme-color-2);
  font-size: 15px;
}
.industrial-home-wrap .categories .left-section .item .product-info ul li a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .right-section {
  padding-left: 7px;
}
.industrial-home-wrap .categories .right-section .item {
  background: var(--theme-default-grey);
  height: 100%;
  padding: 20px;
  border: 1px solid var(--theme-default-grey);
}
.industrial-home-wrap .categories .right-section .item .product {
  text-align: center;
}
.industrial-home-wrap .categories .right-section .item .product img {
  max-width: 40%;
}
.industrial-home-wrap .categories .right-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.industrial-home-wrap .categories .right-section .item .product-info h4 {
  margin-right: 15px;
}
.industrial-home-wrap .categories .right-section .item .product-info h4 a {
  color: var(--theme-default-dark);
}
.industrial-home-wrap .categories .right-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .right-section .item .product-info li a {
  color: var(--theme-color-2);
  font-size: 15px;
}
.industrial-home-wrap .categories .right-section .item .product-info li a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .h {
  height: 205px !important;
}

/* Info bar */
.info-wrap-industrial {
  position: relative;
  background: var(--theme-default-grey);
}
.info-wrap-industrial .info-bar .card-wrap {
  margin-bottom: 20px;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .icon {
  border: 2px solid var(--theme-default-grey);
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 89px;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .icon .mat-icon {
  font-size: 31px;
  margin-right: 5px;
  color: var(--theme-default) !important;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .content {
  text-align: center;
  margin-top: 15px;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .content p {
  font-weight: 600;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .content span {
  line-height: 26px;
}
.info-wrap-industrial .subscribe {
  padding: 50px;
  margin-top: 30px;
  background: var(--theme-default) !important;
}
.info-wrap-industrial .subscribe .subscribe-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.info-wrap-industrial .subscribe .subscribe-wrap p {
  color: var(--theme-default);
  font-size: 16px;
}
.info-wrap-industrial .subscribe .subscribe-wrap .form-wrap {
  margin-left: 20px;
}
.info-wrap-industrial .subscribe .subscribe-wrap .form-wrap input {
  background: var(--theme-default-secondary);
  width: 347px;
  border: none;
  color: var(--theme-default);
  padding: 2px 14px;
  height: 40px;
  opacity: 0.2;
}
.info-wrap-industrial .subscribe .subscribe-wrap .form-wrap button {
  width: 175px;
  height: 44px;
  color: var(--theme-default);
  background: transparent !important;
  border: 2px solid var(--theme-default);
  border-radius: 0;
  margin-left: 15px;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - FOOD STYLES
****************************************
****************************************
****************************************/
.topbar-food {
  background: var(--theme-default) !important;
}
.topbar-food .top-bar .widget-text a {
  color: var(--theme-default);
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.topbar-food .top-bar .widget-text a .mat-icon {
  color: var(--theme-default) !important;
}

/* Header six section */
.header-six {
  position: relative;
}
.header-six .header-wrapper {
  position: absolute;
  top: 20px;
  z-index: 9999;
  left: 0;
  right: 0;
}
.header-six .header-wrapper .logo {
  cursor: pointer;
}
.header-six .header-wrapper .main-menu {
  margin-left: -15px;
}
.header-six .header-wrapper .main-menu a {
  text-transform: uppercase;
  color: var(--theme-default);
}

.mat-menu-content {
  width: 221px;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 4px solid var(--theme-default-grey) !important;
}

/* Product section */
.food-product-section {
  position: relative;
  top: -15px;
  background: url(/assets/images/background/download.svg) no-repeat 90%;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
  z-index: 989;
}
.food-product-section .back-img1 {
  position: absolute;
  left: 0;
  top: 56px;
}
.food-product-section .back-img2 {
  position: absolute;
  left: 0;
  bottom: 56px;
}
.food-product-section .back-img3 {
  position: absolute;
  right: 0;
  bottom: 235px;
}
.food-product-section .head-title {
  text-align: center;
}
.food-product-section .head-title h3 {
  text-align: center;
  margin-bottom: 15px;
}
.food-product-section .head-title p {
  line-height: 28px;
  margin-top: 0px;
}
.food-product-section .prod-menu {
  margin-top: 45px;
  margin-bottom: 45px;
}
.food-product-section .prod-menu ul li {
  cursor: pointer;
  transition: 0.3s;
}
.food-product-section .prod-menu ul li .icon {
  height: 50px;
}
.food-product-section .prod-menu ul li .icon svg {
  max-height: 38px;
  fill: var(--theme-default-dark) !important;
}
.food-product-section .prod-menu ul li .icon svg .cls-1 {
  stroke: var(--theme-default-dark);
}
.food-product-section .prod-menu ul li .text p {
  color: var(--theme-color-2);
  margin: 0;
}
.food-product-section .prod-menu ul li:hover {
  transition: 0.3s;
}
.food-product-section .prod-menu ul li:hover .icon svg {
  color: var(--theme-orange);
}
.food-product-section .prod-menu ul li:hover .text p {
  transition: 0.3s;
  color: var(--theme-orange);
}
.food-product-section .product-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
.food-product-section .product-wrapper .product-box .product-item {
  position: relative;
  overflow: hidden;
}
.food-product-section .product-wrapper .product-box .product-item mat-card {
  padding: 0;
  box-shadow: none;
  border: 1px solid var(--theme-default-grey);
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-img {
  text-align: center;
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-img img {
  max-width: 180px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper {
  background: var(--theme-default-grey);
  padding: 15px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .category {
  text-align: left;
  font-weight: 400;
  color: var(--theme-color-2);
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap {
  margin: 7px 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap h4 {
  font-size: 21px;
  margin: 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap p {
  font-size: 21px;
  font-family: "Gelasio", serif;
  font-weight: 600;
  margin: 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .stars .mat-icon {
  font-size: 20px;
  height: 22px;
  width: 22px;
  color: var(--theme-orange);
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap {
  margin-top: 10px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap .view-more {
  font-weight: 500;
  color: var(--theme-color-2);
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap a {
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap a .mat-icon {
  color: var(--theme-default) !important;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap .bucket {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: var(--theme-default-grey);
  text-align: center;
  vertical-align: middle;
  line-height: 49px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .details-products .more-info {
  margin-top: 15px;
  overflow: hidden;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .details-products .more-info p {
  line-height: 24px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: -10px;
  opacity: 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .icons mat-icon {
  color: var(--theme-default-dark);
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons {
  opacity: 1;
  transition: all 0.4s ease;
  transform: translateX(-20px);
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons .ic-1 {
  animation-delay: 2s;
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons .ic-2 {
  animation: 0.5s ease-in-out fadeInRight;
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons .ic-3 {
  animation: 0.7s ease-in-out fadeInRight;
}

/* Product details page */
.food-details-wrap .header-title {
  background-size: cover;
  padding: 130px 0;
  position: relative;
}
.food-details-wrap .header-title .title {
  text-align: center;
}
.food-details-wrap .header-title .title h2 {
  color: var(--theme-default);
  font-size: 52px;
  margin: 0;
}
.food-details-wrap .header-title .title p {
  color: var(--theme-default);
  line-height: 26px;
  margin: 0;
}
.food-details-wrap .details-wrapper {
  position: relative;
  top: -15px;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
}
.food-details-wrap .details-wrapper .product-image {
  box-shadow: none !important;
  border: 1px solid var(--theme-default-grey);
  border-radius: 0;
}
.food-details-wrap .details-wrapper .product-image img {
  max-width: 100%;
}
.food-details-wrap .details-wrapper .product-image a {
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-right {
  padding: 0 30px 0 25px;
}
.food-details-wrap .details-wrapper .product-right .product-details {
  padding-bottom: 15px;
  border-bottom: 1px dashed var(--theme-color-2);
}
.food-details-wrap .details-wrapper .product-right .product-details h2 {
  margin-bottom: 10px;
}
.food-details-wrap .details-wrapper .product-right .product-details h4 del {
  color: var(--theme-color-2);
  font-size: 17px;
}
.food-details-wrap .details-wrapper .product-right .product-details h4 span {
  padding-left: 5px;
  color: var(--theme-main-red);
  font-size: 24px;
}
.food-details-wrap .details-wrapper .product-right .product-details .avalibility {
  padding-bottom: 15px;
}
.food-details-wrap .details-wrapper .product-right .product-details .avalibility span {
  color: var(--theme-main-red);
  font-weight: 500;
}
.food-details-wrap .details-wrapper .product-right .product-details p.description {
  line-height: 26px;
}
.food-details-wrap .details-wrapper .product-right .product-details h3.price {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0;
}
.food-details-wrap .details-wrapper .product-right .py-1 .red {
  font-weight: 600;
  color: var(--theme-default);
}
.food-details-wrap .details-wrapper .product-right .quantity {
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .product-right .quantity span {
  margin-bottom: 0;
  display: block;
}
.food-details-wrap .details-wrapper .product-right .quantity span .mat-icon {
  font-weight: 300;
  font-size: 20px;
}
.food-details-wrap .details-wrapper .product-right .quantity .quantity-box {
  border: 1px solid var(--theme-default-grey);
  margin-right: 10px;
}
.food-details-wrap .details-wrapper .product-right .quantity .quantity-box input {
  width: 48px;
  text-align: center;
  background-color: var(--theme-default);
  height: 100%;
  border: none;
  border-left: 1px solid var(--theme-default-grey);
  border-right: 1px solid var(--theme-default-grey);
}
.food-details-wrap .details-wrapper .product-right .quantity .quantity-box button {
  line-height: 44px;
}
.food-details-wrap .details-wrapper .product-right .social-icons {
  padding-top: 15px;
  border-top: 1px dashed var(--theme-color-2);
}
.food-details-wrap .details-wrapper .product-right .social-icons ul li {
  width: 33px;
  height: 33px;
  border: 1px solid var(--theme-default-grey);
  border-radius: 50%;
  text-align: center;
  margin-right: 5px;
}
.food-details-wrap .details-wrapper .product-right .social-icons ul li a {
  text-decoration: none;
  font-size: 15px;
  line-height: 32px;
  color: var(--theme-color-2);
}
.food-details-wrap .details-wrapper .product-right .social-icons ul li a i {
  color: var(--theme-default-dark);
}
.food-details-wrap .details-wrapper .info-bar {
  border: 1px solid var(--theme-color-2);
  padding: 0 20px;
  margin-top: 0px !important;
}
.food-details-wrap .details-wrapper .info-bar .mat-icon {
  color: var(--theme-default-dark);
  font-size: 41px;
  margin-right: 15px;
  line-height: 23px;
}
.food-details-wrap .details-wrapper .info-bar .content p {
  text-transform: capitalize;
  font-weight: 500;
}
.food-details-wrap .details-wrapper .info-bar .mat-card {
  box-shadow: none !important;
  padding: 20px 0;
  border-bottom: 1px solid var(--theme-color-2);
}
.food-details-wrap .details-wrapper .info-bar .mat-card.no-border {
  border-bottom: none;
}
.food-details-wrap .details-wrapper .review-wrapper {
  padding-right: 30px;
  margin-top: 30px;
}
.food-details-wrap .details-wrapper .review-wrapper .mat-tab-label {
  color: var(--theme-default-dark) !important;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  /* opacity: 0.1; */
  min-width: 33%;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  background: var(--theme-default-grey);
  margin: 0 2px;
}
.food-details-wrap .details-wrapper .review-wrapper .mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: var(--theme-default-dark) !important;
  font-family: "Gelasio", serif;
  font-weight: 600;
  font-size: 15px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc {
  padding: 20px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc p {
  line-height: 24px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc ul {
  margin-top: 15px;
  padding-left: 20px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc ul li {
  margin-bottom: 7px;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews .name {
  font-weight: 500;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews p {
  line-height: 22px;
  white-space: normal;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews .text {
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews .mat-list-item .mat-list-item-content {
  align-items: start !important;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .mat-form-field {
  width: 100%;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .rating h3 {
  font-size: 22px;
  margin-top: 20px;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .btn-project {
  background: var(--theme-orange);
  padding: 3px 32px;
  color: var(--theme-default);
  border: 2px solid var(--theme-orange);
  border-radius: 0;
  transition: 0.3s;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .btn-project:hover {
  background: var(--theme-default);
  color: var(--theme-orange);
  border: 2px solid var(--theme-orange);
}
.food-details-wrap .details-wrapper .review-wrapper .products {
  padding-right: 30px;
}
.food-details-wrap .details-wrapper .review-wrapper .products .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.food-details-wrap .details-wrapper .review-wrapper .products .title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.food-details-wrap .details-wrapper .review-wrapper .products .title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-secondary);
  bottom: 0.3125rem;
}
.food-details-wrap .details-wrapper .sale-widget {
  padding: 15px 20px;
  background: var(--theme-default-dark);
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  height: auto;
}
.food-details-wrap .details-wrapper .sale-widget .sale-widget1 {
  color: var(--theme-default);
  font-size: 36px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.food-details-wrap .details-wrapper .sale-widget .sale-widget2 {
  color: var(--theme-default);
  font-size: 18px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.food-details-wrap .details-wrapper .sale-widget .discount {
  display: inline-block;
  color: var(--theme-default);
  font-size: 77px;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5;
}
.food-details-wrap .details-wrapper .sale-widget .percent {
  display: inline-block;
  line-height: 31px;
  color: var(--theme-default);
}
.food-details-wrap .details-wrapper .sale-widget .percent span {
  display: block;
  font-size: 3.067em;
  font-weight: 500;
  color: var(--theme-default);
}
.food-details-wrap .details-wrapper .sale-widget p {
  color: var(--theme-default);
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-slide mat-card img {
  max-width: 110px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .small-carousel {
  position: relative;
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container {
  height: 180px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container .swiper-slide mat-card {
  max-height: 120px;
  min-height: 120px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container .swiper-button-prev {
  position: absolute;
  top: 32%;
  left: 0px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container .swiper-button-next {
  position: absolute;
  top: 32%;
  right: 0px;
}
.food-details-wrap .details-wrapper .product-item {
  position: relative;
  overflow: hidden;
}
.food-details-wrap .details-wrapper .product-item mat-card {
  padding: 0;
  box-shadow: none;
  border: 1px solid var(--theme-default-grey);
}
.food-details-wrap .details-wrapper .product-item mat-card .product-img {
  text-align: center;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-img img {
  max-width: 180px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper {
  background: var(--theme-default-grey);
  padding: 15px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .category {
  text-align: left;
  font-weight: 400;
  color: var(--theme-color-2);
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .title-wrap {
  margin: 7px 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .title-wrap h4 {
  font-size: 21px;
  margin: 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .title-wrap p {
  font-size: 21px;
  font-family: "Gelasio", serif;
  font-weight: 600;
  margin: 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .stars .mat-icon {
  font-size: 20px;
  height: 22px;
  width: 22px;
  color: var(--theme-orange);
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap {
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap .view-more {
  font-weight: 500;
  color: var(--theme-color-2);
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap .bucket {
  background: var(--theme-default-grey);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 49px;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap .bucket a mat-icon {
  font-size: 21px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .details-products .more-info {
  margin-top: 15px;
  overflow: hidden;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: -10px;
  opacity: 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .icons mat-icon {
  color: var(--theme-default-dark);
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons {
  opacity: 1;
  transition: all 0.4s ease;
  transform: translateX(-20px);
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons .ic-1 {
  animation-delay: 2s;
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons .ic-2 {
  animation: 0.5s ease-in-out fadeInRight;
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons .ic-3 {
  animation: 0.7s ease-in-out fadeInRight;
}
.food-details-wrap .details-wrapper .title {
  margin-bottom: 22px;
}
.food-details-wrap .no-bg {
  background: transparent !important;
}
.food-details-wrap .no-bg .title h4 {
  font-size: 22px;
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-list-base .mat-list-item.mat-3-line,
.mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/* Galery section*/
.popular-recipes-box {
  padding: 90px 0 0 0;
  background-size: cover;
  position: relative;
}
.popular-recipes-box .recipes-contain .head-title {
  text-align: center;
  color: var(--theme-default);
  margin-bottom: 60px;
}
.popular-recipes-box .recipes-contain .head-title h3 {
  text-align: center;
  margin-bottom: 15px;
  color: var(--theme-default);
}
.popular-recipes-box .recipes-contain .head-title p {
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section {
  grid-column: full-start/full-end;
  display: grid;
  /*grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(7, 5vw);*/
  grid-template: repeat(5, 5vw)/repeat(8, 1fr);
  grid-gap: 1.5rem;
  padding: 1.5rem;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section img {
  max-width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  will-change: transform;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item {
  overflow: hidden;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(1) {
  overflow: hidden;
  grid-row: 1/span 2;
  grid-column: 1/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(2) {
  grid-row: 1/span 3;
  grid-column: 3/span 3;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(3) {
  grid-row: 1/span 2;
  grid-column: 8/8;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(4) {
  grid-row: 3/span 3;
  grid-column: 1/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(5) {
  grid-row: 4/span 2;
  grid-column: 3/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(6) {
  grid-row: 4/6;
  grid-column: 5/8;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(7) {
  grid-row: 1/4;
  grid-column: 6/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(8) {
  grid-row: 3/span 3;
  grid-column: 8/-1;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:hover img {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 0px;
}

/* About products section*/
.about-products {
  padding: 90px 0;
  background: var(--theme-default-grey);
}
.about-products .about-products-wrap .left .text {
  text-align: center;
  padding: 0 50px;
}
.about-products .about-products-wrap .left .text h3 {
  text-align: center;
  margin-bottom: 10px;
}
.about-products .about-products-wrap .left .text span {
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
}
.about-products .about-products-wrap .left .text p {
  line-height: 26px;
}
.about-products .about-products-wrap .left .text .btn-project {
  margin-top: 20px;
}
.about-products .about-products-wrap .right .img-wrap img {
  max-width: 100%;
  height: auto;
}

/***************************************
*  Widget three styles
****************************************/
.widgets-three {
  width: 30px;
  margin-left: 0;
  position: relative;
  padding-top: 10px;
}
.widgets-three .bucket .mat-button-wrapper {
  width: 30px;
}
.widgets-three .bucket .mat-button-wrapper svg {
  max-height: 40px;
  fill: var(--theme-default);
}
.widgets-three .bucket .mat-button-wrapper .mat-badge {
  margin-left: -10px;
}
.widgets-three .bucket .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  text-align: center;
  top: -3px;
  left: 37px;
  background: var(--theme-default);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
  color: var(--theme-default);
  font-weight: 700;
}
.widgets-three .bucket .top-cart span {
  color: var(--theme-default);
}

.spCard-main {
  width: 100%;
}
.spCard-main .mat-menu-content {
  width: 239px !important;
}
.spCard-main .spCard-dropdown {
  background-color: var(--theme-default);
  padding: 15px 10px;
  max-width: 300px;
  z-index: 400;
}
.spCard-main .spCard-dropdown .card-list-title {
  padding: 0 0 10px 0;
}
.spCard-main .spCard-dropdown h4 {
  padding-bottom: 10px;
}
.spCard-main .spCard-dropdown .new-product .product img {
  width: 70px;
}
.spCard-main .spCard-dropdown .new-product .close-circle {
  flex: 1;
}
.spCard-main .spCard-dropdown .new-product .close-circle a {
  cursor: pointer;
  color: var(--theme-default-grey);
  transition: 0.3s;
  float: right;
}
.spCard-main .spCard-dropdown .new-product .close-circle a i {
  font-size: 20px;
}
.spCard-main .spCard-dropdown .new-product .item {
  padding: 10px 0;
}
.spCard-main .spCard-dropdown .new-product .item .product {
  margin-right: 7px;
  background: var(--theme-default);
  border: 1px solid var(--theme-default-grey);
}
.spCard-main .spCard-dropdown .new-product .item .desc p {
  font-weight: 500;
}
.spCard-main .spCard-dropdown .new-product .item .desc span {
  color: var(--theme-color-2);
  font-size: 15px;
  margin-top: 5px;
  /* padding-top: 7px; */
  display: block;
}
.spCard-main .spCard-dropdown .new-product .total {
  padding-top: 15px;
}
.spCard-main .spCard-dropdown .new-product .total p {
  font-size: 15px;
}
.spCard-main .spCard-dropdown .new-product .total span {
  font-weight: 500;
  font-size: 16px;
}
.spCard-main .spCard-dropdown .card-list-title a {
  color: var(--theme-default-dark);
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .card-list-title p {
  color: var(--theme-default-dark);
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .woo-message {
  text-align: center;
  padding: 0;
  margin: 1.5em 0 0 0;
}

/***************************************
*  Carousel Food styles
****************************************/
.main-slider-food {
  height: 760px;
}
.main-slider-food .sl-wrap {
  position: relative;
  height: 100%;
}
.main-slider-food .sl-wrap .content {
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  text-align: center;
  /* top: 58%; */
}
.main-slider-food .sl-wrap .content button {
  background: var(--theme-default) !important;
  border-radius: 0;
  padding: 5px 33px;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}
.main-slider-food .sl-wrap .content button span {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.main-slider-food .sl-wrap .content h1 {
  font-size: 48px;
  text-align: center;
  color: var(--theme-default);
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0;
}
.main-slider-food .sl-wrap .content p {
  font-size: 19px;
  text-align: center;
  color: var(--theme-default);
  margin-bottom: 30px;
  font-weight: 400;
  width: 756px;
  margin: 0 auto;
  line-height: 35px;
}
.main-slider-food .slide-item {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.swiper-pagination-bullet-active {
  background: var(--theme-color-2) !important;
}

.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - FURNITURE STYLES
****************************************
****************************************
****************************************/
/*  Topbar styles */
.main-topbar-furniture {
  background: var(--theme-default-grey) !important;
}
.main-topbar-furniture .top-bar .widget-text a {
  color: var(--theme-default-dark);
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.main-topbar-furniture .top-bar .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
}
.main-topbar-furniture .top-bar .widget-text a:hover {
  color: var(--theme-orange);
}
.main-topbar-furniture .top-bar .widget-text:not(:last-child) {
  margin: 0 20px 0 0;
}
.main-topbar-furniture .top-bar .widget-text .mat-button-wrapper img {
  margin-right: 3px;
}
.main-topbar-furniture .top-bar .welcome-message p {
  color: var(--theme-color-2);
  font-size: 13.5px;
  font-weight: 400;
}
.main-topbar-furniture .top-bar span .mat-icon {
  color: var(--theme-orange);
}
.main-topbar-furniture .top-bar .cur-icon {
  color: var(--theme-color-2) !important;
}
.main-topbar-furniture .company-email p {
  font-size: 14px;
  color: var(--theme-default-dark);
}

/***************************************
*  Header-2 styles
****************************************/
.home-header-seven {
  background: var(--theme-default);
}
.home-header-seven .logo {
  padding: 25px 0;
  margin-left: -15px;
}
.home-header-seven .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-seven .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: var(--theme-default-dark);
  background: var(--theme-default-grey);
}
.home-header-seven .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 70px;
  border: 1px solid var(--theme-default-grey);
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-seven .search-form .form-wrap .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: var(--theme-default);
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-default) !important;
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  -webkit-appearance: none;
  border-radius: 0 50px 50px 0;
}
.home-header-seven .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-seven .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-seven .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: var(--theme-default);
  padding: 0;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 22px;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .commerce-buttons .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-default) !important;
  color: var(--theme-default);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}
.home-header-seven .widget-text a {
  color: var(--theme-default-dark);
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.home-header-seven .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
}
.home-header-seven .widget-text a:hover {
  color: var(--theme-default) !important;
}
.home-header-seven .widget-text:not(:last-child) {
  margin: 0 16px 0 0;
}
.home-header-seven .widget-text .mat-button-wrapper img {
  margin-right: 3px;
}
.home-header-seven .user .mat-icon {
  font-size: 26px !important;
}

/***************************************
*  Slider and menu styles
****************************************/
.carousel-furniture .ctn-box {
  box-sizing: content-box !important;
}
.carousel-furniture .ctn-box .main-slider {
  height: 503px;
}
.carousel-furniture .ctn-box .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-furniture .ctn-box .main-slider .sl-wrap .content {
  padding-left: 20px;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
  transform: none;
}
.carousel-furniture .ctn-box .slider-categories {
  padding-right: 15px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap {
  border: 1px solid var(--theme-default-grey);
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .title {
  background: var(--theme-default-grey);
  border-bottom: 1px solid var(--theme-default-grey);
  color: var(--theme-default-dark);
  padding: 15px 20px 15px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li {
  display: block;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li a {
  padding-top: 12px;
  padding-bottom: 12px !important;
  border-top: 1px solid var(--theme-default-grey);
  margin: 0 10px -1px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .btn-sale {
  margin: 15px 10px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none;
}

.main-menu {
  margin-left: -15px;
}
.main-menu a.mat-button {
  text-transform: uppercase;
  color: var(--theme-default);
  font-size: 1em;
  font-weight: 700;
  padding: 0 20px;
  line-height: 63px;
}

/***************************************
*  Products section styles
****************************************/
.products-furniture.products {
  position: relative;
}
.products-furniture.products h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 23px;
  font-weight: 580;
  position: absolute;
}
.products-furniture.products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/***************************************
*  Banner section styles
****************************************/
.commercial-banner .image-column {
  background-repeat: no-repeat 90%;
  background-size: cover;
  align-items: center;
}
.commercial-banner .inner-column h1 {
  text-transform: capitalize;
  font-size: 52px;
  margin-bottom: 20px;
}
.commercial-banner .inner-column button {
  padding: 5px 33px;
  width: 200px;
  box-shadow: none !important;
}
@media only screen and (max-width: 50em) {
  .commercial-banner .image-column {
    grid-column: 1/-1;
    padding: 6rem;
  }
}
.commercial-banner .story__content {
  background-color: var(--theme-default-grey);
  grid-column: col-start 5/full-end;
  padding: 6rem 8vw;
  /*
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  */
  display: grid;
  align-content: center;
  justify-items: start;
}
@media only screen and (max-width: 50em) {
  .commercial-banner .story__content {
    grid-column: 1/-1;
    grid-row: 5/6;
  }
}
.commercial-banner .story__text {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 4rem;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - DECOR STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Header section styles
****************************************/
.header-decor > div {
  width: 100%;
  background-color: var(--theme-color-2);
}
.header-decor > div .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
  color: var(--theme-default);
}
.header-decor > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: transparent !important;
}
.header-decor > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-icon {
  color: var(--theme-default);
}
.header-decor > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .top-cart span {
  color: var(--theme-default);
}

/***************************************
*  Carousel section styles
****************************************/
.carousel-home-decor {
  position: relative;
}
.carousel-home-decor .ctn-box {
  box-sizing: content-box !important;
  position: absolute;
  top: -64px;
  z-index: 9999;
}
.carousel-home-decor .main-slider {
  height: 640px;
}
.carousel-home-decor .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-home-decor .main-slider .sl-wrap .content {
  padding-left: 21%;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
  transform: none;
}
.carousel-home-decor .slider-categories {
  background-color: var(--theme-default);
}
.carousel-home-decor .slider-categories .categories-wrap {
  border: 1px solid var(--theme-default-grey);
}
.carousel-home-decor .slider-categories .categories-wrap .title {
  background: var(--theme-default-grey);
  border-bottom: 1px solid var(--theme-default-grey);
  color: var(--theme-default-dark);
  padding: 19px 20px 19px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.carousel-home-decor .slider-categories .categories-wrap .title .mat-icon {
  margin-right: 4px;
}
.carousel-home-decor .slider-categories .categories-wrap .category-contant ul li {
  display: block;
}
.carousel-home-decor .slider-categories .categories-wrap .category-contant ul li a {
  padding-top: 12px;
  padding-bottom: 12px !important;
  border-top: 1px solid var(--theme-default-grey);
  margin: 0 10px -1px;
}
.carousel-home-decor .slider-categories .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.carousel-home-decor .slider-categories .categories-wrap .btn-sale {
  margin: 15px 10px;
}
.carousel-home-decor .slider-categories .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none;
}
.carousel-home-decor .carousel-2-wrapper {
  max-width: 100%;
  position: relative;
}
.carousel-home-decor .carousel-2-wrapper .main-slider {
  height: 640px;
}
.carousel-home-decor .carousel-2-wrapper .main-slider .content {
  padding-left: 23%;
  padding-right: 485px;
}
.carousel-home-decor .decor-menu .main-menu {
  margin-left: 293px;
}

/***************************************
*  Home baners section styles
****************************************/
.banners-decor {
  margin-top: -70px;
  z-index: 9999999999999;
  position: relative;
  background: var(--theme-default);
  border: 1px solid var(--theme-default-grey);
}
.banners-decor .baners {
  background: var(--theme-default-grey) !important;
}
.banners-decor .baner {
  padding: 10px 0 0 10px;
  height: 100%;
  transition: 0.15s ease-in;
  text-align: left;
}
.banners-decor .baner h4 {
  font-size: 19px;
  font-weight: 500;
  color: var(--theme-default-dark);
  margin-bottom: 5px;
  text-transform: capitalize;
}
.banners-decor .baner .info .big-price {
  padding: 10px 25px !important;
  box-shadow: none !important;
  width: 135px;
  border-radius: 5px;
  text-align: center;
}
.banners-decor .baner .info .big-price span {
  font-size: 27px !important;
}
.banners-decor .baner p {
  color: var(--theme-default-dark);
}
.banners-decor .baner a {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: var(--theme-default-dark);
  margin-top: 15px;
  display: block;
}
.banners-decor .baner ul li a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: var(--theme-color-2);
  margin-top: 5px;
  display: block;
}
.banners-decor .first {
  height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
.banners-decor .first .info .text-box {
  text-align: center;
  padding: 15px 20px;
}
.banners-decor .first .info .text-box span {
  color: var(--theme-default);
  margin-bottom: 10px;
  line-height: 31px;
}
.banners-decor .first .info .text-box h4 {
  color: var(--theme-default);
  line-height: 27px;
}
.banners-decor .first .info .text-box a {
  color: var(--theme-default);
  font-weight: 500;
}
.banners-decor .seccond {
  /* height: 200px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0px;
}
.banners-decor .media {
  border-right: 1px solid var(--theme-default-grey);
  border-bottom: 1px solid var(--theme-default-grey);
}
.banners-decor .electrics {
  border-left: 1px solid var(--theme-default-grey);
}
.banners-decor .decors {
  border-bottom: 1px solid var(--theme-default-grey);
}

/***************************************
*  Commercial banners section styles
****************************************/
.commercial-banners .image-column {
  background: var(--theme-default-grey);
  padding: 0px 30px;
}
.commercial-banners .image-column h3 {
  margin-bottom: 20px;
}
.commercial-banners .image-column span {
  color: var(--theme-main-red);
  font-weight: 500;
}
.commercial-banners .image-column a {
  color: var(--theme-default-dark);
  font-weight: 500;
  text-decoration: underline;
}
.commercial-banners .image-column .img img {
  max-width: 85%;
}

/***************************************
*  Home footer-2 section styles
****************************************/
.footer-two {
  background-color: var(--theme-default-dark);
}
.footer-two .footer-widget {
  padding-right: 15px;
}
.footer-two .footer-widget .social {
  margin-top: 20px;
}
.footer-two .footer-widget .social li {
  width: 33px;
  height: 33px;
  border: 1px solid var(--theme-default-grey);
  border-radius: 50%;
  text-align: center;
  margin-right: 5px;
}
.footer-two .footer-widget .social li a {
  color: var(--theme-color-2);
}
.footer-two .footer-widget .social li a i {
  color: var(--theme-orange);
}
.footer-two .footer-widget .social li .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: auto;
  height: auto;
  flex-shrink: 0;
  line-height: 32px;
  border-radius: 50%;
}
.footer-two .footer-widget .social li .mat-icon-button i {
  color: var(--theme-default-grey);
}
.footer-two .footer-widget .ped-btn-footer {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background: var(--theme-default) !important;
  box-shadow: none !important;
  width: 100%;
  border-radius: 3px;
  line-height: 45px;
}
.footer-two .footer-widget .title {
  margin-bottom: 10px;
}
.footer-two .footer-widget .title h5 {
  font-size: 18px;
  font-weight: 600;
  color: var(--theme-default);
}
.footer-two .footer-widget a {
  text-decoration: none;
  font-size: 15px;
  line-height: 32px;
  color: var(--theme-color-2);
}
.footer-two .footer-widget .newsletter-widget {
  position: relative;
  margin: 15px 0 20px 0;
}
.footer-two .footer-widget p {
  line-height: 24px;
  color: var(--theme-color-2);
}
.footer-two .footer-widget ul {
  list-style-type: none;
}
.footer-two .footer-widget .about-widget .logo img {
  max-width: 240px;
}
.footer-two .footer-widget .tel-box .icon i {
  display: block;
  line-height: 27px;
  vertical-align: middle;
  margin-right: 5px;
  color: var(--theme-default) !important;
  width: 18px;
  text-align: center;
  font-size: 18px;
}
.footer-two .footer-widget .tel-box .text p a {
  line-height: 22px;
}
.footer-two .footer-widget .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: auto;
  height: auto;
  flex-shrink: 0;
  line-height: 32px;
  border-radius: 50%;
}
.footer-two .subscribe-widget input {
  height: 45px;
  background: var(--theme-default);
  font-size: 15px;
  line-height: 45px;
  border: 1px solid var(--theme-default-grey);
  border-radius: 4px;
  padding: 0 65px 0 20px;
  color: var(--theme-color-2);
}

/***************************************
*  Popular product page styles
****************************************/
.popular-products .reviews .content {
  height: auto;
  margin-bottom: 10px;
  margin-left: -15px !important;
}
.popular-products .reviews .content .media {
  margin-right: 7px;
  background: var(--theme-default-grey);
  border-radius: 3px;
}
.popular-products .reviews .content .media img {
  max-width: 80px;
  margin-right: 8px;
}
.popular-products .reviews .content .info h5 {
  color: var(--theme-color-2);
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}
.popular-products .reviews .content .info .price {
  font-weight: 400;
}
.popular-products .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.popular-products .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.popular-products .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}

/***************************************
*  Login page styles
****************************************/
.login .login-wrap .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.login .login-wrap .title:before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.login .login-wrap .title:after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-dark);
  bottom: 0.3125rem;
}
.login .login-wrap form .mat-form-field {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Red Hat Text", sans-serif !important;
}
.login .login-wrap .register-wrapper .register-body .mat-form-field {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.login .login-wrap .register-wrapper .register-body p {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}
.login .login-wrap .register-wrapper .register-body .button-wrap {
  margin-top: 20px;
}

/***************************************
*  Price slider styles
****************************************/
.price-slider .filter-price .mat-slider-horizontal {
  min-width: 110px;
}

/***************************************
*  Product dialog styles
****************************************/
.product-dialog .mat-dialog-container {
  overflow: visible;
}
.product-dialog .swiper-container .swiper-wrapper img.img-fluid {
  margin: auto;
  max-width: 100%;
}
.product-dialog .product-right {
  padding: 0 30px;
}
.product-dialog .product-right .product-details .discription {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed var(--theme-color-2);
  border-bottom: 1px dashed var(--theme-color-2);
}
.product-dialog .product-right .product-details .discription .bold {
  font-weight: 600;
}
.product-dialog .product-right .product-details .discription p.productDescription {
  line-height: 24px;
  margin-top: 5px;
}
.product-dialog .product-right .product-details h4 span {
  padding-left: 5px;
  color: var(--theme-main-red);
  font-size: 25px;
}
.product-dialog .product-right .product-details h4 del {
  color: var(--theme-color-2);
  font-size: 18px;
}
.product-dialog .product-right .product-details h3.price {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0;
}
.product-dialog .product-right .quantity input {
  width: 80px;
  text-align: center;
  background-color: var(--theme-default-grey);
  height: 35px;
  border: 1px solid var(--theme-default-grey);
}
.product-dialog .product-right .quantity span {
  margin-bottom: 5px;
}
.product-dialog .product-right .avalibility .red {
  color: var(--theme-main-red);
  font-weight: 500;
}
.product-dialog .product-right .buttons .mat-icon {
  color: var(--theme-color-2);
  margin-left: 17px;
  cursor: pointer;
}
.product-dialog .mat-dialog-container .close-btn-outer button {
  position: absolute;
  right: -44px;
  top: -44px;
}
.product-dialog .product-dialog .close-btn-outer {
  position: relative;
}

.product-pagination .ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
  padding: 10px 5px;
}

.product-pagination .ngx-pagination .current {
  padding: 4px 12px;
  background: var(--theme-default-grey);
  color: var(--theme-default-dark);
  cursor: default;
  border-radius: 2px;
  line-height: 22px;
}

.product-dialog .mat-dialog-content {
  max-width: 52vw;
}

/***************************************
*  Popular products styles
****************************************/
.popular-products .reviews .stars {
  color: var(--theme-orange);
  margin: 0px 0 5px 0;
}
.popular-products .reviews .stars span {
  color: var(--theme-default-grey);
  font-size: 13px;
  margin-left: 10px;
}
.popular-products .reviews .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}

.mega-menu-pane .mat-menu-panel {
  width: auto !important;
}
.mega-menu-pane .mat-menu-panel .mat-menu-content {
  width: 100% !important;
}

/***************************************
*  Error page styles
****************************************/
.error {
  text-align: center;
}
.error .page404 {
  font-size: 67px;
  font-weight: 700;
  color: var(--theme-color-2);
  margin-bottom: 30px;
}
.error .not-found {
  font-size: 39px;
  font-weight: 700;
  color: var(--theme-default-dark);
  margin-bottom: 30px;
}
.error .error_description {
  margin-bottom: 30px;
}
.error .error_description p {
  font-weight: 600;
}
.error button {
  background: var(--theme-orange);
  color: var(--theme-default-dark);
  padding: 5px 33px;
  width: 200px;
}

/***************************************
*  FAQ page styles
****************************************/
.faq .questions {
  padding-right: 15px;
}
.faq .questions .faq-wrap .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid var(--theme-color-2);
  box-shadow: none;
  margin-bottom: -1px;
  box-shadow: none !important;
}
.faq .questions .faq-wrap .mat-expansion-panel .mat-expansion-panel-header-title span {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
.faq .questions .faq-wrap .mat-expansion-panel .mat-expansion-panel-header:hover span {
  color: var(--theme-main-blue);
}
.faq .questions .faq-wrap .mat-expansion-panel .mat-expansion-panel-body p {
  line-height: 24px;
}
.faq .faq-form {
  padding-left: 15px;
}
.faq .faq-form .text-wrap p {
  line-height: 26px;
}
.faq .faq-form form {
  margin: 30px 0;
}

/***************************************
*  About us page styles
****************************************/
.about-us-wrapper .mat-expansion-panel-header:hover span {
  color: var(--theme-main-blue);
}
.about-us-wrapper .about-section .left-info-panel {
  overflow: hidden;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro h2 {
  margin-bottom: 20px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro p {
  line-height: 26px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions {
  margin-top: 20px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions .mat-expansion-panel {
  margin-bottom: 10px;
  box-shadow: none;
  border: 1px solid var(--theme-default-grey);
  border-radius: 5px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions .mat-expansion-panel h4 {
  font-weight: 500;
}
.about-us-wrapper .our-values {
  background: var(--theme-default-grey);
}
.about-us-wrapper .our-values .box-wraper .box {
  padding-left: 15px;
  padding-right: 15px;
}
.about-us-wrapper .our-values .box-wraper .box .content {
  padding: 41px 20px 31px;
  background: var(--theme-default);
}
.about-us-wrapper .our-values .box-wraper .box .content .icon-box i {
  font-size: 55px;
  color: var(--theme-default);
}
.about-us-wrapper .our-values .box-wraper .box .content h4 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 5px;
  margin-bottom: 10px;
}
.about-us-wrapper .our-values .box-wraper .box .content p {
  line-height: 26px;
}
.about-us-wrapper .title-wrapper {
  text-align: center;
  margin-bottom: 35px;
}
.about-us-wrapper .title-wrapper h2 {
  margin-bottom: 10px;
}
.about-us-wrapper .title-wrapper p {
  color: var(--theme-color-2);
}
.about-us-wrapper .our-team .team-members .item {
  padding-left: 15px;
  padding-right: 15px;
}
.about-us-wrapper .our-team .team-members .item .single-team-member {
  transition: 0.3s;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box {
  position: relative;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box img {
  width: 100%;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box {
  display: table;
  width: 100%;
  height: 100%;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content {
  display: table-cell;
  vertical-align: bottom;
  overflow: hidden;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content ul {
  background: var(--theme-default);
  margin: 0;
  margin-left: -12px;
  margin-right: -12px;
  -webkit-transform: translate3d(0, 35px, 0);
  transform: translate3d(0, 35px, 0);
  -webkit-transition: -webkit-transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  transition: transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  visibility: hidden;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content ul li {
  padding: 0 12px;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content ul li i {
  color: var(--theme-default);
  font-size: 16px;
  line-height: 35px;
  display: block;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .text-box {
  border: 1px solid var(--theme-default-grey);
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .img-box img {
  opacity: 0.4;
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .img-box .overlay .box .content .social {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .text-box {
  background: var(--theme-default-dark);
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .text-box h4 {
  color: var(--theme-default);
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .text-box p {
  color: var(--theme-default);
}

/***************************************
*  Order success page styles
****************************************/
.succesfull-message {
  text-align: center;
  padding: 50px 0;
}
.succesfull-message .material-icons {
  color: var(--theme-green);
  font-size: 63px;
}
.succesfull-message h2 {
  margin: 10px 0;
}
.succesfull-message p {
  line-height: 30px;
}

.order-success .billing-details h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
.order-success .billing-details .product-order-details .order-img img {
  max-width: 130px;
}
.order-success .billing-details .product-order-details p {
  font-size: 17px;
  font-weight: 500;
}
.order-success .billing-details .product-order-details span {
  font-size: 17px;
  margin-top: 5px;
}
.order-success .billing-details ul.price-list li {
  padding: 10px 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.order-success .billing-details ul.price-list li p {
  line-height: 30px;
  font-weight: 500;
}
.order-success .billing-details ul.price-list li span {
  position: relative;
  width: 35%;
  float: right;
  font-size: 18px;
  text-align: right;
  color: var(--theme-color-2);
}
.order-success .billing-details ul.price-list li img {
  max-width: 30px;
  height: 23px;
  margin: 0 10px;
}
.order-success .mainOrder-wrapper .main-order {
  background: var(--theme-default-grey);
  padding: 30px;
  padding: 30px;
}
.order-success .mainOrder-wrapper .main-order .order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: var(--theme-default-dark);
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order .order-box .title-box span.tl-amount {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list li {
  padding: 10px 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list li p {
  line-height: 30px;
  font-weight: 500;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list li span {
  position: relative;
  width: 35%;
  float: right;
  font-size: 18px;
  text-align: right;
  color: var(--theme-color-2);
}
.order-success .mainOrder-wrapper .main-order ul.total-amount {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount .shipping {
  width: 35%;
  float: right;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount .shipping .shopping-option {
  line-height: 30px;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-default-dark);
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount li span.amount {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: var(--theme-main-red);
  font-weight: 400;
  width: 35%;
  float: right;
}
.order-success .mainOrder-wrapper .main-order ul.quantity {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order ul.quantity li span.total {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: var(--theme-main-red);
  font-weight: 400;
  width: 35%;
  float: right;
}
.order-success .mainOrder-wrapper .main-order ul.total {
  position: relative;
}
.order-success .mainOrder-wrapper .main-order ul.total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-default-dark);
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-success .mainOrder-wrapper .main-order ul.total li span.count {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: var(--theme-main-red);
  font-weight: 400;
  width: 35%;
  float: right;
  text-align: right;
}
.order-success .mainOrder-wrapper .main-order .paymentFinal {
  margin-bottom: 20px;
  line-height: 34px;
}

/***************************************
*  Breadcrumb  styles
****************************************/
.breadcrumb-section {
  padding: 20px 0;
  border-bottom: 1px solid var(--theme-default-grey);
}
.breadcrumb-section .breadcrumb-title h4 {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
}
.breadcrumb-section .breadcrumb-path .breadcrumb {
  background-color: transparent;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--theme-color-2);
  font-weight: 500;
  margin: 0;
  padding-right: 0;
}
.breadcrumb-section .breadcrumb-path .breadcrumb .breadcrumb-item {
  padding: 0 0.5rem;
}
.breadcrumb-section .breadcrumb-path .breadcrumb .breadcrumb-item:active {
  color: var(--theme-color-2);
}
.breadcrumb-section .breadcrumb-path .breadcrumb a {
  color: var(--theme-color-2);
}

/***************************************
*  Checkout page styles
****************************************/
.checkout .billing-details .mat-form-field {
  width: 100%;
}
.checkout .billing-details .add-info {
  margin-top: 15px;
}
.checkout .mainOrder-wrapper .main-order {
  border: 1px solid var(--theme-default-grey);
  padding: 30px;
}
.checkout .mainOrder-wrapper .main-order .order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: var(--theme-default-dark);
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order .order-box .title-box span.tl-amount {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em;
}
.checkout .mainOrder-wrapper .main-order .order-box ul.price-list {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order .order-box ul.price-list li {
  line-height: 44px;
}
.checkout .mainOrder-wrapper .main-order .order-box ul.price-list li span {
  position: relative;
  width: 35%;
  float: right;
  font-size: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount .shipping {
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount .shipping .shopping-option {
  line-height: 30px;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-default-dark);
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount li span.amount {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: var(--theme-main-red);
  font-weight: 400;
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order ul.quantity {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.quantity li span.total {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: var(--theme-main-red);
  font-weight: 400;
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order ul.total {
  position: relative;
  border-bottom: 1px solid var(--theme-default-grey);
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-default-dark);
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.checkout .mainOrder-wrapper .main-order ul.total li span.count {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: var(--theme-main-red);
  font-weight: 400;
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order .paymentFinal {
  margin-bottom: 20px;
  line-height: 34px;
}

.mat-radio-label-content {
  color: var(--theme-color-2);
  font-weight: 400;
}

/***************************************
*  Cart page styles
****************************************/
.cart-wrapper .mat-card {
  text-align: center;
}
.cart-wrapper .mat-card .cart-table.mat-table {
  display: block;
  overflow-x: auto;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row {
  min-height: 100px;
  min-height: 100px;
  border-bottom: 1px solid var(--theme-default-grey);
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .product-name {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .grand-total {
  font-weight: 500;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row span:nth-child(3) {
  font-size: 16px;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row input {
  width: 80px;
  text-align: center;
  background-color: var(--theme-default-grey);
  height: 35px;
  border: 1px solid var(--theme-default-grey);
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .price {
  font-size: 21px;
  font-weight: 500;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .total {
  font-size: 21px;
  font-weight: 500;
  color: var(--theme-main-red);
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-cell,
.cart-wrapper .mat-card .cart-table.mat-table .mat-header-cell {
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 14px;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-cell img,
.cart-wrapper .mat-card .cart-table.mat-table .mat-header-cell img {
  width: 100px;
  cursor: pointer;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-cell mat-icon,
.cart-wrapper .mat-card .cart-table.mat-table .mat-header-cell mat-icon {
  cursor: pointer;
}

.mat-row,
.mat-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
  min-width: 870px;
}

/***************************************
*  Compare page styles
****************************************/
.compare-component .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.compare-component .table-responsive table {
  width: 100%;
  border: 1px solid var(--theme-default-grey);
}
.compare-component .table-responsive table thead .th-compare td {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid var(--theme-default-grey);
  border-top: 1px solid var(--theme-default-grey);
  border-right: 1px solid var(--theme-default-grey);
  background: var(--theme-default-grey);
  font-weight: 600;
  font-size: 14px;
}
.compare-component .table-responsive table thead .th-compare a {
  cursor: pointer;
}
.compare-component .table-responsive table tbody tr th {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid var(--theme-default-grey);
  border-top: 1px solid var(--theme-default-grey);
  border-right: 1px solid var(--theme-default-grey);
  background: var(--theme-default-grey);
  text-align: left;
}
.compare-component .table-responsive table tbody tr .product-name {
  width: 15%;
  font-size: 14px;
}
.compare-component .table-responsive table tbody tr td .description-compare {
  font-size: 14px;
  line-height: 22px;
}
.compare-component img.img-fluid.mb-4 {
  max-width: 100px;
}
.compare-component .empty-cart-cls h4 {
  font-weight: 500;
  color: var(--theme-color-2);
}
.compare-component .empty-cart-cls h3 {
  text-align: center;
}

.compare-component .table-responsive .table tbody tr td,
.compare-component .table-responsive .table tbody tr th {
  padding: 15px;
  vertical-align: top;
  border-top: 1px solid var(--theme-default-grey);
}

/***************************************
*  Details page styles
****************************************/
.details-wrapper .product-image img {
  max-width: 100%;
}
.details-wrapper .product-right {
  padding: 0px;
}
.details-wrapper .product-right .product-details {
  padding-bottom: 15px;
  border-bottom: 1px dashed var(--theme-color-2);
}
.details-wrapper .product-right .product-details h2 {
  margin-bottom: 10px;
}
.details-wrapper .product-right .product-details h4 del {
  color: var(--theme-color-2);
  font-size: 17px;
}
.details-wrapper .product-right .product-details h4 span {
  padding-left: 5px;
  color: var(--theme-main-red);
  font-size: 24px;
}
.details-wrapper .product-right .product-details .avalibility span {
  color: var(--theme-main-red);
  font-weight: 500;
}
.details-wrapper .product-right .product-details p.description {
  line-height: 26px;
}
.details-wrapper .product-right .product-details h3.price {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0;
}
.details-wrapper .product-right .quantity {
  margin-top: 10px;
}
.details-wrapper .product-right .quantity span {
  margin-bottom: 7px;
  display: block;
}
.details-wrapper .product-right .quantity input {
  width: 80px;
  text-align: center;
  background-color: var(--theme-default);
  height: 35px;
  border: 1px solid var(--theme-default-secondary);
}
.details-wrapper .product-right .red {
  color: var(--theme-main-red);
  font-weight: 700;
}
.details-wrapper .info-bar {
  border: 1px solid var(--theme-color-2);
  padding: 0 20px;
  margin-top: 0px !important;
}
.details-wrapper .info-bar .mat-icon {
  color: var(--theme-default-dark);
  font-size: 41px;
  margin-right: 15px;
  line-height: 23px;
}
.details-wrapper .info-bar .content p {
  text-transform: capitalize;
  font-weight: 500;
}
.details-wrapper .info-bar .mat-card {
  box-shadow: none !important;
  padding: 20px 0;
  border-bottom: 1px solid var(--theme-color-2);
}
.details-wrapper .info-bar .mat-card.no-border {
  border-bottom: none;
}
.details-wrapper .review-wrapper .full-desc {
  padding: 20px;
}
.details-wrapper .review-wrapper .full-desc p {
  line-height: 26px;
}
.details-wrapper .review-wrapper .full-desc ul {
  margin-top: 15px;
  padding-left: 20px;
}
.details-wrapper .review-wrapper .full-desc ul li {
  margin-bottom: 7px;
}
.details-wrapper .review-wrapper .Reviews .reviews .name {
  font-weight: 500;
}
.details-wrapper .review-wrapper .Reviews .reviews p {
  line-height: 22px;
  white-space: normal;
}
.details-wrapper .review-wrapper .Reviews .reviews .text {
  margin-top: 10px;
}
.details-wrapper .review-wrapper .Reviews .reviews .mat-list-item .mat-list-item-content {
  align-items: start !important;
}
.details-wrapper .review-wrapper .Reviews .writte-reviews .mat-form-field {
  width: 100%;
}
.details-wrapper .products {
  padding-right: 30px;
}
.details-wrapper .products .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.details-wrapper .products .title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-grey);
}
.details-wrapper .products .title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-default-secondary);
  bottom: 0.3125rem;
}
.details-wrapper .sale-widget {
  height: 14%;
  margin-top: 25px;
  padding: 15px 20px;
  text-align: center;
  background-color: var(--theme-default);
}
.details-wrapper .sale-widget .sale-widget1 {
  color: var(--theme-default);
  font-size: 36px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.details-wrapper .sale-widget .sale-widget2 {
  color: var(--theme-default);
  font-size: 18px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.details-wrapper .sale-widget .discount {
  display: inline-block;
  color: var(--theme-default-dark);
  font-size: 77px;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5;
}
.details-wrapper .sale-widget .percent {
  display: inline-block;
  line-height: 31px;
}
.details-wrapper .sale-widget .percent span {
  display: block;
  font-size: 3.111em;
  font-weight: 500;
}
.details-wrapper .sale-widget p {
  color: var(--theme-default);
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.details-wrapper .small-carousel {
  position: relative;
  margin-top: 10px;
}
.details-wrapper .small-carousel .swiper-container {
  height: 180px;
}
@media only screen and (max-width: 600px) {
  .details-wrapper .small-carousel .swiper-container {
    height: 190px;
  }
}
.details-wrapper .small-carousel .swiper-container .swiper-slide mat-card {
  padding: 0px;
  max-width: 100%;
  max-height: 100%;
}
.details-wrapper .small-carousel .swiper-container .swiper-slide mat-card img {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.details-wrapper .small-carousel .swiper-container .swiper-button-prev {
  position: absolute;
  top: 32%;
  left: 0px;
}
.details-wrapper .small-carousel .swiper-container .swiper-button-next {
  position: absolute;
  top: 32%;
  right: 0px;
}
.details-wrapper .sale-baner {
  background: transparent;
}
.details-wrapper .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid var(--theme-color-2);
  box-shadow: none !important;
  margin-bottom: 20px;
  background: transparent !important;
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-list-base .mat-list-item.mat-3-line,
.mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/***************************************
*  Brands page styles
****************************************/
.brands-items .mat-radio-button {
  margin-bottom: 15px;
}
.brands-items .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: var(--theme-default-blackish) !important;
}

/***************************************
*  Categorie page styles
****************************************/
.category-contant ul li a {
  color: var(--theme-color-2);
  padding-bottom: 10px;
  display: block;
  font-size: 15px;
}

/***************************************
*  Contact us page styles
****************************************/
.contact-page iframe {
  width: 100%;
  height: 400px;
  border: none;
}
.contact-page .left-info-panel ul {
  list-style-type: none;
  padding: 0;
}
.contact-page .left-info-panel ul > *:not(:last-child) {
  border-bottom: 1px solid var(--theme-default-grey);
  padding-bottom: 20px;
}
.contact-page .left-info-panel ul li i {
  float: left;
  font-size: 33px;
  line-height: 74px;
  margin-right: 7px;
  color: var(--theme-default);
}
.contact-page .left-info-panel ul li h4 {
  display: inline-block;
  font-size: 20px;
}
.contact-page .contact-form .title {
  margin-bottom: 20px;
}
.contact-page .contact-form .w-100 {
  width: 100% !important;
}
.contact-page .contact-form .mat-form-field {
  caret-color: var(--theme-default) !important;
  font-size: 14px !important;
}
.contact-page .contact-form .mat-form-field .mat-input-element {
  caret-color: var(--theme-default) !important;
}
.contact-page .contact-form .px-1 {
  margin-bottom: 15px;
}

@media screen and (max-width: 599px) {
  .contact-page .left-info-panel {
    padding-right: 0px;
    width: 100%;
  }
}
.sidenav .divider {
  margin: 10px 0;
}

:host {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;
}
:host .mat-list-item.active {
  background-color: mat-color(var(--theme-orange), 50);
}
:host:hover > .mat-list-item:not(.expanded), :host:focus > .mat-list-item:not(.expanded) {
  background-color: mat-color(var(--theme-orange), 100) !important;
}

.mat-list-item {
  padding: 10px 0;
  display: flex;
  width: auto;
}
.mat-list-item .mat-list-item-content {
  width: 100%;
}
.mat-list-item .routeIcon {
  margin-right: 40px;
}

.btn-default,
.mat-button {
  color: var(--theme-default-dark);
}

.header.mat-toolbar {
  box-shadow: 0 0 5px grey;
  z-index: 13;
  font-size: 20px;
  max-height: 64px;
  height: 64px;
  padding: 0px 10px;
}

@media (min-width: 580px) {
  .header.mat-toolbar.header-long {
    height: 120px !important;
    max-height: 120px !important;
  }
}
.sub-container .mat-card {
  box-shadow: none !important;
}

.search_box-n {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search_box-n .search_input {
  width: 85% !important;
}

.search_box-n .search_input input {
  height: 40px !important;
}

table.mat-elevation-z8 thead tr.b-b.ng-star-inserted {
  display: table-row !important;
}

table.mat-elevation-z8 tbody tr.b-b.ng-star-inserted {
  display: table-row !important;
}

.mat-dialog-content {
  width: 100%;
}

.example-form {
  max-width: 100% !important;
}

.mat-dialog-container {
  position: relative !important;
}

.mat-dialog-container .close-button {
  top: 0px !important;
  right: 0px !important;
  position: absolute !important;
}

.edb-space {
  margin-top: 10px;
}

.mat-icon.mat-warn {
  color: var(--theme-default-secondary) !important;
}

.vl {
  margin: unset;
  border-left: 1px solid var(--theme-default-secondary);
  height: 30px;
  margin-top: 15px;
}

.c-info {
  text-align: center;
}

.c-info h3 {
  text-align: center;
}

.c-info a {
  background: none !important;
}

.c-info a:hover {
  background: none !important;
}

.c-info .mat-toolbar {
  background: none !important;
}

.c-info .mat-toolbar a i {
  width: 40px;
  height: 40px;
  background: var(--theme-default-secondary);
  border-radius: 500px;
  font-size: 20px !important;
  line-height: 40px !important;
  color: var(--theme-default) !important;
}

.home-header .top-navbar .mat-button-wrapper {
  text-transform: uppercase;
}

.invoiceDialog .mat-dialog-container {
  margin: 20px 0px;
  padding: 0px;
}

.ds-summary-cart ::ng-deep .mat-drawer {
  width: 420px;
  padding: 0px;
}
.ds-summary-cart ::ng-deep .mat-icon-button {
  padding: 8px;
}

.mat-badge-content {
  background-color: var(--theme-main-blue);
}

.cdk-global-overlay-wrapper {
  pointer-events: auto;
  overflow: auto;
}

.height-fit-content {
  height: fit-content;
}

#product-no-sidebar-component .mat-form-field-outline-start,
#product-no-sidebar-component .mat-form-field-outline-end {
  border-radius: 0 !important;
}

.bundles-section .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px !important;
  width: 12px;
  height: 12px;
}

.new-5star-header .commerce-buttons {
  margin-left: -12px !important;
  margin-top: 10px !important;
}

.color-blackish {
  color: var(--theme-default-blackish) !important;
}

.color-white {
  color: var(--theme-white) !important;
}

.mat-checkbox {
  font-family: "Red Hat Text";
}

.no-checkbox-container .checkBox {
  border: 1px solid rgba(74, 74, 74, 0.9803921569);
  border-radius: 6px;
  padding: 6px 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Red Hat Text";
  background-color: #E6E7E8 !important;
  opacity: 0.6;
}
.no-checkbox-container .checkbox-selected {
  opacity: 1;
  background-color: #ffffff !important;
}
.no-checkbox-container .checkbox-selected .mat-checkbox-label {
  font-weight: 500;
}
.no-checkbox-container .mat-checkbox-inner-container {
  display: none;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.fade-80 {
  opacity: 0.8;
}

.loyalty-program-dialog {
  width: 100%;
  max-width: 799px !important;
  border-radius: 0 !important;
}
.loyalty-program-dialog .mat-dialog-container {
  border-radius: 0 !important;
}

@media (max-width: 599px) {
  .loyalty-program-dialog {
    margin: 30px;
  }
  .cart-count-wrapper {
    left: 55px !important;
  }
}
.bg-white {
  background-color: #ffffff !important;
}

.mat-tab-label, .mat-tab-link {
  font-family: "Red Hat Text" !important;
}

@media (min-width: 1200px) and (max-width: 1480px) {
  .product-dialog .mat-dialog-content {
    max-width: 64vw;
  }
  .product-dialog .mat-dialog-content .product-right .buttons .btn-project {
    max-width: 125px;
    padding: 4px 18px !important;
    font-size: 10px !important;
  }
}
@media (max-width: 1199px) {
  .product-dialog .mat-dialog-content {
    max-width: 64vw;
  }
  .product-dialog .mat-dialog-content .product-right .buttons .btn-project {
    max-width: 115px;
    padding: 4px 18px !important;
    font-size: 10px !important;
  }
}
@media (min-width: 1200px) {
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 400px;
  }
}
@media (min-width: 1200px) and (max-width: 1280px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 400px;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .main-home3-wrapper {
    padding-left: 0 !important;
  }
  .main-home3-wrapper .info-bar {
    margin-top: 25px !important;
  }
  .carousel-3 .carousel-2-wrapper {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
  .carousel-3 .slider-categories {
    max-height: 100% !important;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
    max-width: 50%;
  }
}
@media (min-width: 961px) {
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
}
@media (max-width: 959px) {
  .mat-toolbar-row {
    height: 100% !important;
    padding: 15px !important;
  }
  .home-header .logo {
    padding: 0;
  }
  .home-header .search-form {
    padding: 0;
  }
  .home-5-wrap .categories .left-section {
    padding-right: 0;
    margin-bottom: 15px;
  }
  .home-5-wrap .categories .left-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .left-section .item .product-info ul {
    margin: 0;
  }
  .home-5-wrap .categories .right-section {
    padding-left: 0;
  }
  .home-5-wrap .categories .right-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .right-section .item .product-info ul {
    margin: 0;
  }
  .details-wrapper .product-right {
    padding: 0;
    margin-top: 25px;
  }
  .left-sidebar-wrapper .all-products {
    padding-left: 0;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
}
@media (min-width: 960px) and (max-width: 1199px) {
  .home-5-wrap .categories .left-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .left-section .item .product-info ul {
    margin: 0;
  }
  .home-5-wrap .categories .right-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .right-section .item .product-info ul {
    margin: 0;
  }
  .prod-three .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
    padding: 10px;
  }
  .prod-three .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
    font-size: 16px;
  }
  .prod-three .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
    width: 27px;
    height: 27px;
    line-height: 35px;
  }
  .products .product-item .mat-card:not([class*=mat-elevation-z]) .product-img img {
    max-width: 131px;
  }
  .home-3-wrap .main-home3-wrapper h3 {
    top: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }
  .carousel-2 .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .main-slider .content h3 {
    font-size: 29px;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }
  .slide-item .content h1 {
    font-size: 37px !important;
    letter-spacing: 0px;
    max-width: 190px;
  }
  .slide-item .content h3 {
    font-size: 29px;
  }
  .carousel-2 .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .main-slider .content h3 {
    font-size: 29px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
  .home-header .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .home-header-2 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .home-header-4 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .home-header-three .logo {
    margin: 0 auto;
  }
  .demo-wrapper .demo-showcase .demos .item.middle {
    margin: 0 auto !important;
    margin-bottom: 50px !important;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 34px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 24px;
  }
  .checkout .billing-details {
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  .checkout .mainOrder-wrapper {
    padding-left: 0;
  }
  .carousel-2 .carousel-2-wrapper {
    max-width: 100%;
  }
  .details-wrapper .product-image {
    margin-bottom: 20px;
  }
  .details-wrapper .product-right {
    padding: 0px;
  }
  .home-3-wrap .container {
    padding: 0 15px;
  }
  .home-3-wrap .container .home3-sidebar {
    padding-right: 0px;
  }
  .home-3-wrap .main-home3-wrapper {
    padding-left: 0;
  }
  .home-3-wrap .main-home3-wrapper .info-bar {
    margin-top: 25px;
  }
  .banners-container .baners {
    background: var(--theme-default-grey);
    margin-right: 0;
    margin-bottom: 10px;
  }
  .banners-container .baners .first {
    width: 100%;
    background-size: contain;
  }
  .banners-container .baner h4.big-title {
    font-size: 25px;
  }
  .banners-container .seccond {
    margin-right: 0px;
    background-color: var(--theme-default-grey);
    height: 332px;
  }
  .banners-container .pd-xs {
    margin-top: 10px;
  }
  .banners-container .mg-xs-0 {
    margin-left: 0;
  }
  .carousel-2 .carousel-2-wrapper {
    max-width: 100%;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
  .carousel-3 .carousel-2-wrapper {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0px;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond {
    height: 100%;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info {
    height: 100%;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
    width: auto;
    height: 100%;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .text-box h4 {
    font-size: 20px;
  }
  .carousel-2 .slider-categories {
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .left-sidebar-wrapper .filter-sidenav {
    width: 100%;
  }
  .left-sidebar-wrapper .filter-sidenav .popular-products {
    margin-top: 0;
  }
  .left-sidebar-wrapper .filter-sidenav .toggles-wrapper .toggles {
    padding-right: 7px;
  }
  .left-sidebar-wrapper .filter-sidenav .toggles-wrapper .popular-products {
    padding-left: 0px;
  }
}
@media (max-width: 768px) {
  .crypto-value {
    display: flex;
    flex-direction: column;
  }
  .demo-wrapper .demo-showcase .demos .item.middle {
    margin: 0 auto !important;
    margin-bottom: 50px !important;
  }
  .left-sidebar-wrapper .all-products {
    padding-left: 0;
  }
  .home-header .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .home-header-2 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .home-header-4 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .home-header-three .logo {
    margin: 0 auto;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }
  .home-3-wrap .main-home3-wrapper .info-bar {
    margin-top: 25px;
  }
  .home-3-wrap .main-home3-wrapper h3 {
    top: 30px;
  }
  .slide-item .content {
    padding-left: 0px !important;
  }
  .slide-item .content h1 {
    font-size: 34px !important;
    letter-spacing: 0px;
    max-width: 190px;
  }
  .slide-item .content h3 {
    font-size: 24px;
  }
  .checkout .billing-details {
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  .checkout .mainOrder-wrapper {
    padding-left: 0;
  }
  .carousel-2 .carousel-2-wrapper {
    max-width: 100%;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
  .carousel-2 .details-wrapper .product-image {
    margin-bottom: 20px;
  }
  .carousel-2 .details-wrapper .product-right {
    padding: 0px;
  }
  .carousel-2 .demo-wrapper .demo-showcase .demos .item.middle {
    margin: 0 auto !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .carousel-2 .banners-container .baners {
    margin-right: 0;
  }
  .carousel-2 .home-header .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .carousel-2 .home-header-2 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .carousel-2 .home-header-three .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .carousel-2 .main-slider .content {
    padding-left: 10px;
  }
  .carousel-2 .main-slider .content button {
    padding: 0;
    width: 100px;
  }
}
@media (max-width: 768px) and (max-width: 649px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner {
    height: 116px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 12px;
    left: 15px;
    /* font-size: 95%; */
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 20px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 7px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner img {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
  }
  .home-3-wrap .home3-sidebar {
    padding-right: 0px;
  }
  .home-3-wrap .main-home3-wrapper {
    padding-left: 0;
  }
  .home-3-wrap .main-home3-wrapper h3 {
    top: 30px;
  }
  .home-header .search-form {
    width: 95%;
    padding: 0;
  }
  .home-header-2 .search-form {
    width: 95%;
    padding: 0;
  }
  .home-header-three .search-form {
    width: 95%;
    padding: 0;
  }
  .home-header-four .search-form {
    width: 95%;
    padding: 0;
  }
}
@media (max-width: 768px) and (max-width: 599px) {
  .home-5-wrap .categories .left-section {
    padding-right: 0;
    margin-bottom: 0px;
  }
  .home-5-wrap .categories .left-section .item {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 15px;
  }
  .home-5-wrap .categories .left-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .left-section .item .product-info ul {
    margin: 0;
  }
  .home-5-wrap .categories .right-section {
    padding-left: 0;
  }
  .home-5-wrap .categories .right-section .item {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 15px;
    margin-top: 0px !important;
  }
  .home-5-wrap .categories .right-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .right-section .item .product-info ul {
    margin: 0;
  }
  .categories-menu-wrap .category-contant ul li {
    width: 100%;
    transition: 0.3s;
  }
  .info-bar-four-wrapper {
    border-bottom: none;
  }
  .info-bar-four-wrapper .info-bar-four {
    border: none;
  }
  .info-bar-four-wrapper .info-bar-four .card-wrap {
    padding: 0;
    border-bottom: 1px solid var(--theme-default-grey);
  }
  .info-bar-four-wrapper .info-bar-four .card-wrap .mat-card {
    border: none;
  }
  .info-bar .card-wrap {
    padding: 0 !important;
  }
  .carousel-3 .carousel-2-wrapper {
    max-width: 100% !important;
    margin-bottom: 20px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 320px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 34px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 24px;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0px;
  }
  .main-slider {
    height: 280px;
  }
  .slide-item .content h1 {
    font-size: 24px;
    letter-spacing: 3px;
  }
  .slide-item .content h3 {
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  .vertical-products .product-panel:not(:last-child) {
    padding-right: 0px;
    margin-bottom: 30px;
  }
  .mt-2 {
    margin-top: 20px;
  }
  .banners-container .seccond {
    margin: 10px 0 0 0;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner {
    width: 100%;
    height: 100%;
    background: var(--theme-default-grey);
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .text-box {
    width: 100% !important;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
    width: 100%;
    height: auto;
  }
  .left-sidebar-wrapper .filter-sidenav {
    width: 100% !important;
  }
  .left-sidebar-wrapper .all-products {
    padding-left: 0px;
  }
  .left-sidebar-wrapper .all-products .col .product-item {
    margin-right: 0px;
  }
  .mat-toolbar-row {
    height: 100% !important;
    padding: 15px !important;
  }
  .cdk-global-overlay-wrapper .product-dialog {
    max-width: 80vw !important;
    min-width: 90% !important;
  }
  .product-dialog .mat-dialog-content {
    max-width: 73vw !important;
  }
  .product-dialog .mat-dialog-content {
    max-width: 52vw;
  }
  .product-dialog .mat-dialog-content .product-right {
    padding: 0px !important;
  }
  .product-dialog .mat-dialog-content .product-right .buttons .btn-project {
    padding: 3px 12px !important;
  }
}
@media (max-width: 768px) and (max-width: 480px) {
  .demo-wrapper .demo-showcase .demos .item .first {
    height: auto !important;
  }
  .checkout .mainOrder-wrapper .main-order {
    padding-left: 10px;
    padding-right: 10px;
  }
  .checkout .mainOrder-wrapper .main-order ul.total-amount .shipping {
    /* width: 35%; */
    /* float: right; */
    width: unset;
    float: unset;
    display: flex;
    flex-direction: column;
  }
  .checkout .mainOrder-wrapper .main-order ul.total-amount .shipping .shopping-option .mat-radio-group {
    display: flex;
    flex-direction: column;
  }
  .checkout .mainOrder-wrapper .main-order ul.price-list li {
    line-height: 29px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .commerce-buttons .mat-button {
    padding-right: 0px !important;
  }
  .home-header .info-message-icon .info-text span {
    font-size: 15px !important;
  }
  .home-header-2 .info-message-icon .info-text span {
    font-size: 15px !important;
  }
  .slide-item .content h1 {
    font-size: 23px !important;
    letter-spacing: 0px;
    max-width: 190px;
  }
  .slide-item .content h3 {
    font-size: 19px !important;
    margin-bottom: 10px;
    letter-spacing: 0px;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content {
    padding-left: 10px;
    padding-right: 150px;
  }
  .carousel-3 .carousel-2-wrapper {
    max-width: 100% !important;
    margin-bottom: 20px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-left: 10px;
    padding-right: 146px !important;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0px;
  }
}
body.dark {
  background-color: var(--theme-default-dark);
  transition: all 0.3s ease;
  color: var(--theme-default);
}
body.dark .mat-drawer-content {
  background-color: var(--theme-default-dark);
}

.dark .color-options .configuration-content h6 {
  color: var(--theme-color-2) !important;
}
.dark .color-options .configuration-content h5 {
  color: var(--theme-default-dark) !important;
}
.dark .main-slider .sl-wrap .content h3 {
  color: var(--theme-default-dark) !important;
}
.dark .mat-card {
  background: transparent !important;
}
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
  color: var(--theme-default-grey) !important;
}
.dark .mat-tab-labels .mat-tab-label-content {
  color: var(--theme-default-grey);
}
.dark .product-item-2 .mat-card:not([class*=mat-elevation-z]) .price-wrap p,
.dark .product-item-1 .mat-card:not([class*=mat-elevation-z]) .price-wrap p {
  font-size: 19px;
  color: var(--theme-default-grey);
}
.dark .info-bar {
  border: 1px solid var(--theme-default-dark);
}
.dark .info-bar .card-wrap .mat-card {
  border-right: 1px solid var(--theme-default-dark);
}
.dark .info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
  color: var(--theme-default-grey);
}
.dark .info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.dark .info-bar .card-wrap .mat-icon {
  color: var(--theme-default);
}
.dark .home-3-wrap .main-home3-wrapper .info-bar {
  border: 1px solid var(--theme-default-dark);
}
.dark .home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card {
  border-right: 1px solid var(--theme-default-dark);
}
.dark .home-3-wrap .brands {
  border: 1px solid var(--theme-default-dark);
}
.dark .left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  background: transparent;
}
.dark .left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  color: var(--theme-default-grey);
}
.dark .left-sidebar-wrapper .all-products select {
  color: var(--theme-default-grey);
}
.dark .left-sidebar-wrapper .all-products input {
  color: var(--theme-default-grey);
}
.dark .left-sidebar-wrapper .filter-row {
  border-bottom: 1px solid var(--theme-default-dark);
  border-top: 1px solid var(--theme-default-dark);
}
.dark .left-sidebar-wrapper .mat-expansion-panel {
  border: 1px solid var(--theme-default-dark);
}
.dark .left-sidebar-wrapper .mat-expansion-panel span {
  color: var(--theme-default-grey);
}
.dark .left-sidebar-wrapper .no-shadow {
  background: transparent;
}
.dark .right-sidebar-wrapper .all-products {
  background: transparent;
}
.dark .right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  background: transparent;
}
.dark .right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  color: var(--theme-default-grey);
}
.dark .right-sidebar-wrapper .all-products select {
  color: var(--theme-default-grey);
}
.dark .right-sidebar-wrapper .all-products input {
  color: var(--theme-default-grey);
}
.dark .right-sidebar-wrapper .filter-row {
  border-bottom: 1px solid var(--theme-default-dark);
  border-top: 1px solid var(--theme-default-dark);
}
.dark .right-sidebar-wrapper .mat-expansion-panel {
  border: 1px solid var(--theme-default-dark);
}
.dark .right-sidebar-wrapper .mat-expansion-panel span {
  color: var(--theme-default-grey);
}
.dark .right-sidebar-wrapper .no-shadow {
  background: transparent;
}
.dark .products .product-item .mat-card {
  background-color: transparent;
  box-shadow: none !important;
}
.dark .products .product-item .mat-card .product-info-wrapper .price-wrap p {
  color: var(--theme-default-grey);
}
.dark .vertical-products .widget-column .title .widget-title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: var(--theme-default-dark);
}
.dark .vertical-products .widget-column .title .widget-title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: var(--theme-color-2);
  bottom: 0.3125rem;
}
.dark .vertical-products .widget-column .media-body span {
  color: var(--theme-default-grey);
}
.dark .vertical-products .widget-column .media-body .price {
  color: var(--theme-default-grey);
}
.dark .footer {
  background-color: var(--theme-default-dark);
}
.dark .footer .footer-widget a {
  color: var(--theme-default-grey);
}
.dark .footer .footer-widget p {
  color: var(--theme-default-grey);
}
.dark .footer .footer-widget .tel-box .icon i {
  color: var(--theme-default);
}
.dark .footer .footer-widget .social li {
  border: 1px solid var(--theme-color-2);
}
.dark .footer .footer-widget .social li i {
  color: var(--theme-default-grey);
}
.dark .carousel-2 .slider-categories .categories-wrap {
  border: 1px solid var(--theme-default-dark);
}
.dark .carousel-2 .slider-categories .categories-wrap .category-contant ul li a {
  border-top: 1px solid var(--theme-default-dark);
}
.dark .carousel-2 .slider-categories .categories-wrap .title {
  background: var(--theme-default-dark);
  border-bottom: 1px solid var(--theme-default-dark);
  color: var(--theme-default-grey);
}
.dark .home3-sidebar .categories-wrap {
  border: 1px solid var(--theme-default-dark);
}
.dark .home3-sidebar .categories-wrap .category-contant ul li a {
  border-top: 1px solid var(--theme-default-dark);
}
.dark .home3-sidebar .categories-wrap .title {
  background: var(--theme-default-dark);
  border-bottom: 1px solid var(--theme-default-dark);
  color: var(--theme-default-grey);
}
.dark .carousel-3 {
  background-color: var(--theme-default-dark);
  padding: 25px 0;
}
.dark .home-3-wrap .main-home3-wrapper .products {
  background-color: var(--theme-default-dark);
}
.dark .menu-container1 {
  background: var(--theme-default-dark);
  border-bottom: 1px solid var(--theme-default-dark);
}
.dark .menu-container1 a.mat-button {
  color: var(--theme-default-grey);
}
.dark .info-bar-four-wrapper {
  margin-top: 0px;
  border: none;
  background: var(--theme-default-dark);
  border-bottom: 1px solid var(--theme-default-dark);
}
.dark .info-bar-four-wrapper .card-wrap .mat-card {
  border-right: 1px solid var(--theme-default-dark);
}
.dark .info-bar-four-wrapper .card-wrap .mat-card p {
  color: var(--theme-default-grey);
}
.dark .products .products-four h3::before {
  background-color: var(--theme-default-dark);
}
.dark .products .products-four h3::after {
  background-color: var(--theme-color-2);
}
.dark .featured-products-wrap {
  background: var(--theme-default-dark);
}
.dark .home-header-three {
  background: var(--theme-default-dark);
  border-top: none;
}
.dark .home-header-three .call-bucket-wrap .main-menu-wrapper-3 .mat-icon {
  color: var(--theme-default-grey);
}
.dark .home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-cart span {
  color: var(--theme-default-grey);
}
.dark .topbar_four {
  background: var(--theme-default-dark) !important;
}
.dark .topbar_four .widget-text a {
  color: var(--theme-default-grey);
}
.dark .topbar_four .widget-text a .mat-icon {
  color: var(--theme-default-grey);
}
.dark .menu-container4 {
  background: var(--theme-default-dark);
  border-top: 1px solid var(--theme-default-dark);
}
.dark .menu-container4 .main-menu a.mat-button {
  color: var(--theme-default-grey);
}
.dark .popular-products .title-header h4::before {
  background-color: var(--theme-default-dark);
}
.dark .popular-products .title-header h4::after {
  background-color: var(--theme-color-2);
}
.dark .popular-products .reviews .content .info .price {
  color: var(--theme-default-grey);
}
.dark .home-5-wrap {
  background: var(--theme-default-dark);
}
.dark .home-5-wrap .categories {
  background: var(--theme-default-dark);
}
.dark .home-5-wrap .categories .title h3::before {
  background-color: var(--theme-default-dark);
}
.dark .home-5-wrap .categories .title h3::after {
  background-color: var(--theme-color-2);
}
.dark .home-header-4 .call-bucket-wrap .info-message-icon .info-text span {
  color: var(--theme-default-grey);
}
.dark .home-header-4 .call-bucket-wrap .info-message-icon .info-text p {
  color: var(--theme-default-grey);
}
.dark .home-header-4 .call-bucket-wrap .info-message-icon .icon .mat-icon {
  color: var(--theme-default-grey);
}
.dark .home-header-4 .call-bucket-wrap .top-navbar .mat-button-wrapper .mat-icon {
  color: var(--theme-default-grey);
}
.dark .home-header-4 .call-bucket-wrap .top-navbar .mat-button-wrapper .top-cart span {
  color: var(--theme-default-grey);
}
.dark .menu-container5 .container {
  border-top: 1px solid var(--theme-default-dark);
}
.dark .menu-container5 .container a.mat-button {
  color: var(--theme-default-grey);
}
.dark .mat-form-field-label-wrapper label {
  color: var(--theme-default-grey);
}
.dark .about-us-wrapper .about-section .left-info-panel .about-us-intro p {
  color: var(--theme-default-grey);
}
.dark .about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions .mat-expansion-panel {
  background: transparent;
  border: 1px solid var(--theme-color-2);
}
.dark .about-us-wrapper .our-values {
  background-color: var(--theme-default-dark);
}
.dark .about-us-wrapper .our-values .box-wraper .box .content {
  background: transparent;
  border: 1px solid var(--theme-color-2);
}
.dark .about-us-wrapper .our-values .box-wraper .box .content p {
  color: var(--theme-default-grey);
}
.dark .about-us-wrapper .our-team .team-members .item .single-team-member .text-box p {
  color: var(--theme-color-2);
}
.dark .details-wrapper .product-image {
  background: var(--theme-default) !important;
}
.dark .details-wrapper .small-carousel .mat-card {
  background: var(--theme-default) !important;
}
.dark .details-wrapper .info-bar .mat-icon {
  color: var(--theme-default-grey);
}
.dark .details-wrapper .info-bar .content p {
  color: var(--theme-default-grey);
}
.dark .details-wrapper .info-bar .content span {
  color: var(--theme-default-grey);
}
.dark .details-wrapper .products .title::before {
  background-color: var(--theme-default-dark);
}
.dark .details-wrapper .products .title::after {
  background-color: var(--theme-color-2);
}
.dark .details-wrapper .product-right .avalibility {
  color: var(--theme-color-2);
}
.dark .details-wrapper p.description {
  color: var(--theme-default-grey);
}
.dark .product-dialog .mat-dialog-container {
  overflow: visible;
  background: var(--theme-default-secondary);
}
.dark .product-dialog .swiper-container .swiper-wrapper img.img-fluid {
  background: var(--theme-default);
}
.dark .product-dialog .product-right .discription .bold {
  color: var(--theme-color-2);
}
.dark .product-dialog .product-right span {
  color: var(--theme-default-grey);
}
.dark .product-dialog .product-right .quantity input {
  background-color: transparent;
  border: 1px solid var(--theme-color-2);
  color: var(--theme-default);
}
.dark .menu-wraper-five {
  background: var(--theme-default-dark);
}
.dark .menu-wraper-five a {
  color: var(--theme-default-grey) !important;
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form input {
  color: var(--theme-default-grey);
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form i {
  color: var(--theme-default-grey);
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper .mat-icon {
  color: var(--theme-default-grey);
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper .top-cart span {
  color: var(--theme-default-grey);
}
.dark .food-product-section {
  background: url(/assets/images/background/download2.svg) no-repeat 90%;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
}
.dark .food-product-section .head-title p {
  color: var(--theme-default-grey);
}
.dark .food-product-section .prod-menu ul li .text p {
  color: var(--theme-default-grey);
}
.dark .food-product-section .prod-menu ul li .icon svg {
  fill: var(--theme-default-grey) !important;
}
.dark .food-product-section .prod-menu ul li .icon svg .cls-1 {
  stroke: var(--theme-default-grey) !important;
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card {
  border: 1px solid var(--theme-default-dark);
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card .product-img {
  background-color: var(--theme-default);
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper {
  background: var(--theme-default-dark);
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap p {
  color: var(--theme-default-grey);
}
.dark .about-products {
  background: var(--theme-default-dark);
}
.dark .about-products p,
.dark .about-products span {
  color: var(--theme-default-grey);
}
.dark .main-topbar-furniture {
  background: var(--theme-default-dark) !important;
}
.dark .main-topbar-furniture span,
.dark .main-topbar-furniture p {
  color: var(--theme-default-grey);
}
.dark .home-header-seven > div {
  width: 100%;
  background-color: var(--theme-default-dark);
}
.dark .home-header-seven > div .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
  color: var(--theme-default);
}
.dark .home-header-seven > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: transparent !important;
}
.dark .home-header-seven > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-icon {
  color: var(--theme-default);
}
.dark .home-header-seven > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .top-cart span {
  color: var(--theme-default);
}
.dark .carousel-furniture {
  padding-top: 30px;
}
.dark .carousel-furniture .ctn-box .slider-categories .categories-wrap {
  border: 1px solid var(--theme-default-dark);
}
.dark .carousel-furniture .ctn-box .slider-categories .categories-wrap .title {
  background: var(--theme-default-dark);
  border-bottom: 1px solid var(--theme-default-dark);
  color: var(--theme-default-grey);
}
.dark .carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li a {
  border-top: 1px solid var(--theme-default-dark);
}
.dark .carousel-furniture .main-menu a.mat-button {
  color: var(--theme-default-grey);
}
.dark .banners-container .baner h4 {
  color: var(--theme-default-dark) !important;
}
.dark .commercial-banner .story__content {
  background-color: var(--theme-default-dark);
}
.dark .commercial-banner .story__content .inner-column h1 {
  color: var(--theme-default-grey);
}
.dark .main-menu a.mat-button {
  color: var(--theme-default-grey);
}
.dark .header-decor > div {
  width: 100%;
  background-color: var(--theme-default-dark);
}
.dark .carousel-home-decor .slider-categories {
  background-color: var(--theme-default-dark);
}
.dark .carousel-home-decor .slider-categories .categories-wrap {
  border: 1px solid var(--theme-default-dark);
}
.dark .carousel-home-decor .slider-categories .categories-wrap .title {
  background: var(--theme-default-dark);
  border-bottom: 1px solid var(--theme-default-dark);
  color: var(--theme-default-grey);
}
.dark .carousel-home-decor .slider-categories .categories-wrap .category-contant ul li a {
  border-top: 1px solid var(--theme-default-dark);
  color: var(--theme-default-grey);
}
.dark .banners-decor {
  border: 1px solid var(--theme-color-2);
}
.dark .banners-decor .baner h4 {
  color: var(--theme-default-dark) !important;
}